import { call } from 'redux-saga/effects';
import { Observable } from 'zen-observable-ts';
import { API, graphqlOperation } from 'aws-amplify';

export function* SubscribeToUpdates<T>(
    subscriptionQuery: any,
    onNext: (data: T) => void,
    onError: (error: any) => void
) {
    let subscription: ZenObservable.Subscription | null = null;

    try {
        // Use `call` to invoke the subscription
        const observable: Observable<{ value: { data: T } }> = yield call(
            [API, 'graphql'],
            graphqlOperation(subscriptionQuery)
        );

        // Subscribe to the updates and store the subscription
        subscription = observable.subscribe({
            next: ({ value }) => {
                const data = value.data;

                if (data) {
                    onNext(data);

                    // Unsubscribe after receiving the first valid data
                    if (subscription) {
                        //subscription.unsubscribe();
                        //console.log('Subscription closed after receiving data');
                    }
                }
            },
            error: (error: any) => {
                onError(error);

                // Unsubscribe on error
                if (subscription) {
                    subscription.unsubscribe();
                    console.log('Subscription closed due to an error');
                }
            },
        });

        // Keep the saga active until the subscription completes
        yield new Promise(() => { });  // Prevent saga from completing immediately
    } catch (err) {
        console.log('Error subscribing:', err);
    }
}