import { action } from 'typesafe-actions';
import { DynamicObject } from '../../utils/commonInterfaces';
import { PageData } from '../common/types';
import { GetInvoicesRequestPayload, Invoice } from '../invoices/types';
import {
    Conversation,
    GetConversationRequestPayload,
    GetPaymentPlanDataScheduleRequestPayload,
    GetPaymentPlansRequestPayload,
    PaymentPlanAddCommentRequestPayload,
    PaymentPlansActionTypes,
    PaymentPlanSchedule,
    CancelPaymentPlanRequestPayload,
    RequestPaymentPlansPayload,
    PaymentPlan,
    RequestManualCreateNewPaymentPlanPayload,
    UpdatePaymentPlanScheduleRequestPayload,
    ResendPaymentPlanApprovalRequestPayload
} from './types';

/**
 * Actions for fetching the paymentPlans list.
 * @param payload
 */
export const getPaymentPlansRequestAction = (
    payload: GetPaymentPlansRequestPayload
) => action(PaymentPlansActionTypes.GET_PAYMENT_PLANS_REQUEST, payload);
export const getPaymentPlansSuccessAction = (payload: {
    data: DynamicObject[];
    pageData: PageData;
    mergeData?: boolean;
}) => action(PaymentPlansActionTypes.GET_PAYMENT_PLANS_SUCCESS, payload);
export const getPaymentPlansErrorAction = (payload: string[]) =>
    action(PaymentPlansActionTypes.GET_PAYMENT_PLANS_ERROR, payload);

/**
 * Action called for updating the filters on PaymentPlans management page.
 * @param filters
 */
export const updatePaymentPlansFiltersAction = (
    filters: GetPaymentPlansRequestPayload['filters']
) => action(PaymentPlansActionTypes.UPDATE_PAYMENT_PLANS_FILTERS, filters);

/**
 * Action called for changing the sort by, sort order, and state for PaymentPlans list.
 * @param tableSortAndState
 */
export const updatePaymentPlansSortByAndStateAction = (tableSortAndState: {
    sortBy: GetPaymentPlansRequestPayload['sortBy'];
    sortAscending: boolean;
}) =>
    action(
        PaymentPlansActionTypes.UPDATE_PAYMENT_PLANS_SORT_BY_AND_STATE,
        tableSortAndState
    );

/**
 * Action called when changing the paymentPlans view filter.
 * @param paymentPlanViewFilter
 */
export const updatePaymentPlansTableFilterAction = (
    paymentPlanViewFilter: string
) =>
    action(
        PaymentPlansActionTypes.UPDATE_PAYMENT_PLANS_TABLE_FILTER_STATE,
        paymentPlanViewFilter
    );

/**
 * Action called for clearing the PaymentPlans state.
 */
export const clearPaymentPlansStateDataAction = () =>
    action(PaymentPlansActionTypes.CLEAR_PAYMENT_PLANS_STATE_DATA);

// Single Record
/**
 * Action sfor setting the selected paymentPlan Id when clicking on a paymentPlan table row item.
 * @param paymentPlanId
 * @param callback
 */
export const setPaymentPlanSelectedIdRequestAction = (
    paymentPlanId: string | null,
    callback: () => void
) =>
    action(PaymentPlansActionTypes.SET_PAYMENT_PLAN_SELECTED_ID_REQUEST, {
        paymentPlanId,
        callback,
    });
export const setPaymentPlanSelectedIdSuccessAction = (
    paymentPlanId: string | null
) =>
    action(
        PaymentPlansActionTypes.SET_PAYMENT_PLAN_SELECTED_ID_SUCCESS,
        paymentPlanId
    );

/**
 * Actions for getting the details for the selected single paymentPlan.
 * @param paymentPlanId
 * @param isUsingCloudImportType
 */
export const getPaymentPlanDataRequestAction = (
    paymentPlanId: string,
    isUsingCloudImportType: boolean
) =>
    action(PaymentPlansActionTypes.GET_PAYMENT_PLAN_DATA_REQUEST, {
        paymentPlanId,
        isUsingCloudImportType,
    });
export const getPaymentPlanDataSuccessAction = (payload: { record: {} }) =>
    action(PaymentPlansActionTypes.GET_PAYMENT_PLAN_DATA_SUCCESS, payload);
export const getPaymentPlanDataErrorAction = (payload: string[]) =>
    action(PaymentPlansActionTypes.GET_PAYMENT_PLAN_DATA_ERROR, payload);

/**
 * Action for resetting table filters and sort values.
 */
export const clearPaymentPlansStateAllTableFiltersAction = () =>
    action(PaymentPlansActionTypes.CLEAR_PAYMENT_PLANS_STATE_ALL_TABLE_FILTERS);

/**
 * Action for clearing the details of a selected paymentPlan.
 */
export const clearPaymentPlanDataAction = () =>
    action(PaymentPlansActionTypes.CLEAR_PAYMENT_PLAN_DATA_SUCCESS);

// Invoice List Actions
export const getPaymentPlanInvoicesRequestAction = (
    payload: GetInvoicesRequestPayload
) => action(PaymentPlansActionTypes.GET_PAYMENT_PLAN_INVOICES_REQUEST, payload);
export const getPaymentPlanInvoicesSuccessAction = (payload: {
    data: Invoice[];
    pageData: PageData;
}) =>
    action(PaymentPlansActionTypes.GET_PAYMENT_PLAN_INVOICES_SUCCESS, payload);
export const getPaymentPlanInvoicesErrorAction = (payload: string[]) =>
    action(PaymentPlansActionTypes.GET_PAYMENT_PLAN_INVOICES_ERROR, payload);

export const updatePaymentPlanInvoicesFiltersAction = (
    filters: GetInvoicesRequestPayload['filters']
) =>
    action(
        PaymentPlansActionTypes.UPDATE_PAYMENT_PLAN_INVOICES_FILTERS,
        filters
    );
export const updatePaymentPlanInvoicesSortByAndStateAction =
    (tableSortAndState: {
        sortBy: GetInvoicesRequestPayload['sortBy'];
        sortAscending: boolean;
        invoiceState: string;
    }) =>
        action(
            PaymentPlansActionTypes.UPDATE_PAYMENT_PLAN_INVOICES_SORT_BY_AND_STATE,
            tableSortAndState
        );
export const updatePaymentPlanInvoicesTableFilterAction = (
    invoiceState: string
) =>
    action(
        PaymentPlansActionTypes.UPDATE_PAYMENT_PLAN_INVOICES_TABLE_FILTER_STATE,
        invoiceState
    );

// Conversation actions
export const getPaymentPlanConversationRequestAction = (
    payload: GetConversationRequestPayload
) =>
    action(
        PaymentPlansActionTypes.GET_PAYMENT_PLAN_CONVERSATION_REQUEST,
        payload
    );
export const getPaymentPlanConversationSuccessAction = (payload: {
    data: Conversation[];
    pageData: PageData;
}) =>
    action(
        PaymentPlansActionTypes.GET_PAYMENT_PLAN_CONVERSATION_SUCCESS,
        payload
    );
export const getPaymentPlanConversationErrorAction = (payload: string[]) =>
    action(
        PaymentPlansActionTypes.GET_PAYMENT_PLAN_CONVERSATION_ERROR,
        payload
    );

export const updatePaymentPlanConversationFiltersAction = (
    filters: GetConversationRequestPayload['filters']
) =>
    action(
        PaymentPlansActionTypes.UPDATE_PAYMENT_PLAN_CONVERSATION_FILTERS,
        filters
    );

export const updatePaymentPlanConversationTableFilterAction = (
    conversationTableFilter: string
) =>
    action(
        PaymentPlansActionTypes.UPDATE_PAYMENT_PLAN_CONVERSATION_TABLE_FILTER,
        conversationTableFilter
    );
export const updateConversationSortByAction = (tableSort: {
    sortBy: GetConversationRequestPayload['sortBy'];
    sortAscending: boolean;
}) =>
    action(
        PaymentPlansActionTypes.UPDATE_PAYMENT_PLAN_CONVERSATION_SORT_BY,
        tableSort
    );

export const paymentPlanAddCommentRequestAction = (
    payload: PaymentPlanAddCommentRequestPayload
) => action(PaymentPlansActionTypes.PAYMENT_PLAN_ADD_COMMENT_REQUEST, payload);

export const updatePaymenPlanScheduleRequestAction = (
    payload: UpdatePaymentPlanScheduleRequestPayload,
    callback: () => void
) => action(PaymentPlansActionTypes.UPDATE_PAYMENT_PLAN_SCHEDULE, { payload, callback });

/**
 * Payment schedule
 */
/**
 * Actions called for getting the payment schedule data for payment plan.
 * @param payload
 */
export const getPaymentPlanDataScheduleRequestAction = (
    payload: GetPaymentPlanDataScheduleRequestPayload
) =>
    action(
        PaymentPlansActionTypes.GET_PAYMENT_PLAN_DATA_SCHEDULE_REQUEST,
        payload
    );
export const getPaymentPlanDataScheduleSuccessAction = (payload: {
    data: PaymentPlanSchedule[];
    pageData: PageData;
}) =>
    action(
        PaymentPlansActionTypes.GET_PAYMENT_PLAN_DATA_SCHEDULE_SUCCESS,
        payload
    );
export const getPaymentPlanDataScheduleErrorAction = (payload: string[]) =>
    action(
        PaymentPlansActionTypes.GET_PAYMENT_PLAN_DATA_SCHEDULE_ERROR,
        payload
    );

/**
 * Action for updating the filter values to be used in getting the Schedule data for payment plan.
 * @param filters
 */
export const updatePaymentPlanDataScheduleFiltersAction = (
    filters: GetPaymentPlanDataScheduleRequestPayload['filters']
) =>
    action(
        PaymentPlansActionTypes.UPDATE_PAYMENT_PLAN_DATA_SCHEDULE_FILTERS,
        filters
    );

/**
 * Action called for updating the Invoice state and sort values when getting the schedule for payment plan.
 * @param tableSort
 */
export const updatePaymentPlanDataScheduleSortByAndStateAction = (tableSort: {
    sortBy: GetPaymentPlanDataScheduleRequestPayload['sortBy'];
    sortAscending: boolean;
    invoiceState: string;
}) =>
    action(
        PaymentPlansActionTypes.UPDATE_PAYMENT_PLAN_DATA_SCHEDULE_SORT_BY_AND_STATE,
        tableSort
    );

/**
 * Action called for creating paymnent plans
 * @param payload
 */
export const createPaymentPlanAction = (
    payload: PaymentPlan,
    callback: (response: DynamicObject) => void
) => action(PaymentPlansActionTypes.CREATE_PAYMENT_PLAN, { payload, callback });

/**
 * Action that fetches the payment schedule details page (public) data.
 * @param communicationId
 * @param callback
 */
export const fetchPaymentPlanCommunicationDetail = (
    communicationId: string,
    applyDelay: boolean,
    callback: (response: DynamicObject) => void
) =>
    action(
        PaymentPlansActionTypes.FETCH_PAYMENT_PLAN_COMMUNICATION_DETAILS_PUBLIC,
        {
            communicationId,
            applyDelay,
            callback,
        }
    );

/**
 * Action that creates dummy payment plans to populate the request payment plan panel
 * @param communicationId
 */
export const requestPaymentPlansAction = (
    payload: RequestPaymentPlansPayload,
    callback: (response: { IsSuccess: boolean, PaymentPlans: PaymentPlan[], Messages?: string[] }) => void
) =>
    action(
        PaymentPlansActionTypes.FETCH_PAYMENT_PLAN_CONFIGURATIONS,
        {
            payload,
            callback
        }
    );    

/**
 * Action called when submitting the form when actioning a payment schedule task.
 * @param payload
 */
export const actionPaymentPlanRequestAction = (payload: {}) =>
    action(PaymentPlansActionTypes.ACTION_PAYMENT_PLAN_REQUEST, payload);

/**
* Action that fetched the state of the paymentplans
* @param payload
*/
export const checkPaymentPlansActionStateAction = (payload: {}) =>
    action(PaymentPlansActionTypes.CHECK_PAYMENT_PLANS_ACTION_STATE, payload);  


/**
 * Action called when submitting the form when cancelling a payment schedule task.
 * @param payload
 */
export const cancelPaymentPlanRequestAction = (payload: CancelPaymentPlanRequestPayload) =>
    action(PaymentPlansActionTypes.CANCEL_PAYMENT_PLAN_REQUEST, payload);

/**
 * Action called for acting on a batch of payment plan tasks.
 * @param payload
 */
export const actionPaymentPlanTasks = (
    payload: DynamicObject
) => action(PaymentPlansActionTypes.ACTION_PAYMENT_PLANS_REQUEST, payload);


export const manualCreateNewPaymentPlanRequestAction = (
    payload: RequestManualCreateNewPaymentPlanPayload
) => action(PaymentPlansActionTypes.ACTION_MANUAL_CREATE_NEW_PAYMENT_PLAN_REQUEST, payload);

export const resendPaymentPlanApprovalRequestAction = (payload: ResendPaymentPlanApprovalRequestPayload) =>
    action(PaymentPlansActionTypes.RESEND_PAYMENT_PLAN_APPROVAL_REQUEST, payload);
