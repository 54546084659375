import API from '@aws-amplify/api';
import { get } from 'lodash';
import { all, call, delay, fork, put, takeLatest } from 'redux-saga/effects';
import {
    API_NAME,
    maxAPIRefetchCount,
    refetchAPIDelay,
} from '../../config/config';
import {
    checkShouldRequestRefetch,
} from '../../utils/commonFunctions';
import { DynamicObject } from '../../utils/commonInterfaces';
import {
    deallocateRequestAction,
} from './actions';
import { ChangesActionTypes } from './types';

/**
 * Function called when connecting deallocate change line to the API.
 * @param param0
 */
function* handleDeallocateRequest({ payload: sagaPayload }: any) {
    const { payload, callback } = sagaPayload;
    let refetchCount = 0;

    try {
        const res: DynamicObject = yield call(
            [API, 'post'],
            API_NAME,
            '/change/reverse',
            {
                body: payload,
            }
        );

        if (callback) {
            const response = {
                ...res,
                IsSuccess: true,
            };
            callback(response);
        }
    } catch (err) {
        if (err instanceof Error) {
            console.log('Error', err);
        } else {
            console.error('An unknown error occured.');
        }

        if (
            refetchCount <= maxAPIRefetchCount &&
            checkShouldRequestRefetch(err)
        ) {
            refetchCount++;
            yield delay(refetchAPIDelay);
            yield put(deallocateRequestAction(payload, callback));
        } else {
            if (callback) {
                const returnData = get(err.response, 'data')
                    ? err.response.data
                    : { Messages: [err.message] };
                returnData.IsSuccess = false;
                callback(returnData);
            }
        }
    }
}

function* watchDeallocateCreditRequest() {
    yield takeLatest(
        ChangesActionTypes.DEALLOCATE_REQUEST,
        handleDeallocateRequest
    );
}

// We can also use `fork()` here to split our saga into multiple watchers.
function* changesSaga() {
    yield all([
        fork(watchDeallocateCreditRequest),
    ]);
}

export default changesSaga;
