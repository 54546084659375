import update from 'immutability-helper';
import { concat, isEqual, uniqWith } from 'lodash';
import { Reducer } from 'redux';
import {
    ORGANISATION_COMPANIES_PAGE,
    ORGANISATION_USERS_PAGE,
} from '../../config/tableAndPageConstants';
import {
    companiesSortByOptions,
    organisationUsersSortByOptions,
} from '../../constants/organisationsSortAndFilters';
import { OrganisationsActionTypes, OrganisationsState } from './types';

// Type-safe initialState!
export const initialState: OrganisationsState = {
    loading: false,
    userOrganisations: [],
    companies: {
        loading: false,
        errorMessages: [],
        data: [],
        pageData: {
            pageSize: ORGANISATION_COMPANIES_PAGE.pageSize,
            currentPage: 0,
            hasNextPage: false,
        },
        filters: {},
        sortBy: companiesSortByOptions[0].value,
        sortAscending: false,
        tableFilter: undefined,
    },
    userCompanies: {
        loading: false,
        data: [],
    },
    users: {
        loading: false,
        errorMessages: [],
        data: [],
        pageData: {
            pageSize: ORGANISATION_USERS_PAGE.pageSize,
            currentPage: 0,
            hasNextPage: false,
        },
        filters: {},
        sortBy: organisationUsersSortByOptions[0].value,
        sortAscending: false,
        tableFilter: undefined,
        editUserLoading: false,
        activeData: {
            loading: false,
            record: {},
            selectedId: null,
            errorMessages: [],
        },
    },
    selectedUserOrganisation: undefined,
    saveLoading: false,
};

// Thanks to Redux 4's much simpler typings, we can take away a lot of typings on the reducer side,
// everything will remain type-safe.
const reducer: Reducer<OrganisationsState> = (state = initialState, action) => {
    switch (action.type) {
        case OrganisationsActionTypes.GET_USER_ORGANISATIONS_REQUEST: {
            return { ...state, loading: true };
        }
        case OrganisationsActionTypes.GET_USER_ORGANISATIONS_SUCCESS: {
            return {
                ...state,
                loading: false,
                userOrganisations: action.payload,
            };
        }
        case OrganisationsActionTypes.SELECT_USER_ORGANISATION_REQUEST: {
            return { ...state, loading: true };
        }
        case OrganisationsActionTypes.SELECT_USER_ORGANISATION_SUCCESS: {
            return {
                ...state,
                loading: false,
                selectedUserOrganisation: action.payload,
            };
        }

        // COMPANIES
        case OrganisationsActionTypes.GET_ORGANISATION_COMPANIES_REQUEST: {
            const newCompanies = update(state.companies, {
                $merge: {
                    loading: true,
                },
            });
            return {
                ...state,
                companies: newCompanies,
            };
        }
        case OrganisationsActionTypes.GET_ORGANISATION_COMPANIES_SUCCESS: {
            let newDataState = [];
            if (
                !action.payload.pageData ||
                action.payload.pageData.currentPage === 0
            ) {
                newDataState = update(state.companies.data, {
                    $set: action.payload.data,
                });
            } else {
                newDataState = uniqWith(
                    concat(state.companies.data, action.payload.data),
                    isEqual
                );
            }

            const newCompanies = update(state.companies, {
                $merge: {
                    loading: false,
                    data: newDataState,
                    pageData: action.payload.pageData,
                    errorMessages: initialState.companies.errorMessages,
                },
            });
            return {
                ...state,
                companies: newCompanies,
            };
        }
        case OrganisationsActionTypes.GET_ORGANISATION_COMPANIES_ERROR: {
            const newCompanies = update(state.companies, {
                $merge: {
                    loading: false,
                    data: initialState.companies.data,
                    errorMessages: action.payload,
                },
            });

            return {
                ...state,
                companies: newCompanies,
            };
        }
        case OrganisationsActionTypes.GET_ORGANISATION_COMPANIES_FOR_USER_REQUEST: {
            const newCompanies = update(state.companies, {
                $merge: {
                    loading: true,
                },
            });
            return {
                ...state,
                userCompanies: newCompanies,
            };
        }
        case OrganisationsActionTypes.GET_ORGANISATION_COMPANIES_FOR_USER_SUCCESS: {
            let newDataState = [];
            if (
                !action.payload.pageData ||
                action.payload.pageData.currentPage === 0
            ) {
                newDataState = update(state.userCompanies.data, {
                    $set: action.payload.data,
                });
            } else {
                newDataState = uniqWith(
                    concat(state.userCompanies.data, action.payload.data),
                    isEqual
                );
            }

            const newCompanies = update(state.userCompanies, {
                $merge: {
                    loading: false,
                    data: newDataState,
                },
            });
            return {
                ...state,
                userCompanies: newCompanies,
            };
        }
        case OrganisationsActionTypes.GET_ORGANISATION_COMPANIES_FOR_USER_ERROR: {
            const newCompanies = update(state.userCompanies, {
                $merge: {
                    loading: false,
                    data: initialState.userCompanies.data,
                },
            });

            return {
                ...state,
                userCompanies: newCompanies,
            };
        }
        case OrganisationsActionTypes.UPDATE_ORGANISATION_COMPANIES_FILTERS: {
            const newCompanies = update(state.companies, {
                $merge: {
                    filters: action.payload,
                },
            });

            return {
                ...state,
                companies: newCompanies,
            };
        }
        case OrganisationsActionTypes.UPDATE_ORGANISATION_COMPANIES_SORT_BY: {
            const newCompanies = update(state.companies, {
                $merge: {
                    sortBy: action.payload.sortBy,
                    sortAscending: action.payload.sortAscending,
                },
            });

            return {
                ...state,
                companies: newCompanies,
            };
        }
        case OrganisationsActionTypes.UPDATE_ORGANISATION_COMPANIES_TABLE_FILTER: {
            const newCompanies = update(state.companies, {
                $merge: {
                    tableFilter: action.payload,
                },
            });

            return {
                ...state,
                companies: newCompanies,
            };
        }
        case OrganisationsActionTypes.CLEAR_ORGANISATION_COMPANIES_STATE_DATA: {
            return {
                ...state,
                companies: { ...initialState.companies },
            };
        }

        // USERS
        case OrganisationsActionTypes.GET_ORGANISATION_USERS_REQUEST: {
            const newUsers = update(state.users, {
                $merge: {
                    loading: true,
                },
            });
            return {
                ...state,
                users: newUsers,
            };
        }
        case OrganisationsActionTypes.GET_ORGANISATION_USERS_SUCCESS: {
            let newDataState = [];
            if (
                !action.payload.pageData ||
                action.payload.pageData.currentPage === 0
            ) {
                newDataState = update(state.users.data, {
                    $set: action.payload.data,
                });
            } else {
                newDataState = uniqWith(
                    concat(state.users.data, action.payload.data),
                    isEqual
                );
            }

            const newUsers = update(state.users, {
                $merge: {
                    loading: false,
                    data: newDataState,
                    pageData: action.payload.pageData,
                    errorMessages: initialState.users.errorMessages,
                },
            });
            return {
                ...state,
                users: newUsers,
            };
        }
        case OrganisationsActionTypes.GET_ORGANISATION_USERS_ERROR: {
            const newUsers = update(state.users, {
                $merge: {
                    loading: false,
                    data: initialState.users.data,
                    errorMessages: action.payload,
                },
            });

            return {
                ...state,
                users: newUsers,
            };
        }
        case OrganisationsActionTypes.UPDATE_ORGANISATION_USERS_FILTERS: {
            const newUsers = update(state.users, {
                $merge: {
                    filters: action.payload,
                },
            });

            return {
                ...state,
                users: newUsers,
            };
        }
        case OrganisationsActionTypes.UPDATE_ORGANISATION_USERS_SORT_BY: {
            const newUsers = update(state.users, {
                $merge: {
                    sortBy: action.payload.sortBy,
                    sortAscending: action.payload.sortAscending,
                },
            });

            return {
                ...state,
                users: newUsers,
            };
        }
        case OrganisationsActionTypes.UPDATE_ORGANISATION_USERS_TABLE_FILTER: {
            const newUsers = update(state.users, {
                $merge: {
                    tableFilter: action.payload,
                },
            });

            return {
                ...state,
                users: newUsers,
            };
        }
        case OrganisationsActionTypes.CLEAR_ORGANISATION_USERS_STATE_DATA: {
            return {
                ...state,
                users: { ...initialState.users },
            };
        }

        //Single Record
        case OrganisationsActionTypes.SET_ORGANISATION_USER_SELECTED_ID_REQUEST: {
            const newUsers = update(state.users, {
                activeData: {
                    $merge: {
                        selectedId: initialState.users.activeData.selectedId,
                    },
                },
            });

            return {
                ...state,
                users: newUsers,
            };
        }

        case OrganisationsActionTypes.SET_ORGANISATION_USER_SELECTED_ID_SUCCESS: {
            const newUsers = update(state.users, {
                activeData: {
                    $merge: {
                        selectedId: action.payload,
                    },
                },
            });

            return {
                ...state,
                users: newUsers,
            };
        }

        case OrganisationsActionTypes.GET_ORGANISATION_USER_DATA_REQUEST: {
            const newUsers = update(state.users, {
                activeData: {
                    $merge: {
                        record: initialState.users.activeData.record,
                        loading: true,
                    },
                },
            });

            return {
                ...state,
                users: newUsers,
            };
        }

        case OrganisationsActionTypes.GET_ORGANISATION_USER_DATA_SUCCESS: {
            const newUsers = update(state.users, {
                activeData: {
                    $merge: {
                        record: action.payload.record,
                        loading: false,
                        errorMessages:
                            initialState.users.activeData.errorMessages,
                    },
                },
            });

            return {
                ...state,
                users: newUsers,
            };
        }

        case OrganisationsActionTypes.GET_ORGANISATION_USER_DATA_ERROR: {
            const newUsers = update(state.users, {
                activeData: {
                    $merge: {
                        record: initialState.users.activeData.record,
                        loading: false,
                        errorMessages: action.payload,
                    },
                },
            });

            return {
                ...state,
                users: newUsers,
            };
        }

        case OrganisationsActionTypes.CLEAR_ORGANISATION_USER_DATA_SUCCESS: {
            const newUsers = update(state.users, {
                activeData: { $set: initialState.users.activeData },
            });

            return {
                ...state,
                users: newUsers,
            };
        }

        case OrganisationsActionTypes.SAVE_USER_ORGANISATION_REQUEST: {
            return { ...state, saveLoading: true };
        }
        case OrganisationsActionTypes.SAVE_USER_ORGANISATION_RESPONSE: {
            return { ...state, saveLoading: false };
        }
        case OrganisationsActionTypes.LOAD_CURRENCIES_DATA_REQUEST: {
            return { ...state, saveLoading: true };
        }

        case OrganisationsActionTypes.CLEAR_ORGANISATION_COMPANIES_STATE_ALL_TABLE_FILTERS: {
            const newCompanies = update(state.companies, {
                $merge: {
                    filters: initialState.companies.filters,
                    sortBy: initialState.companies.sortBy,
                    sortAscending: initialState.companies.sortAscending,
                    tableFilter: initialState.companies.tableFilter,
                },
            });
            return {
                ...state,
                companies: newCompanies,
            };
        }
        default: {
            return state;
        }
    }
};

// Instead of using default export, we use named exports. That way we can group these exports
// inside the `index.js` folder.
export { reducer as organisationsReducer };
