import { action } from 'typesafe-actions';
import { DynamicObject } from '../../utils/commonInterfaces';
import {
    Conversation,
    GetConversationRequestPayload,
    PageData
} from '../common/types';
import { Credit, GetCreditsRequestPayload } from '../credits/types';
import { GetInvoicesRequestPayload, Invoice } from '../invoices/types';
import { GetPaymentsRequestPayload, Payment } from '../payments/types';
import {
    BulkSaveCustomerUpdateRequestPayload,
    CustomerAddCommentRequestPayload,
    CustomersActionTypes,
    CustomerSendInformationRequestPayload,
    CustomersFetchedForOrganisationPayload,
    GetCustomersRequestPayload,
    SaveCustomerUpdateRequestPayload
} from './types';

export const getCustomersRequestAction = (
    payload: GetCustomersRequestPayload
) => action(CustomersActionTypes.GET_CUSTOMERS_REQUEST, payload);
export const getCustomersForOrganisationRequestAction = (
    payload: GetCustomersRequestPayload
) => action(CustomersActionTypes.GET_CUSTOMERS_FOR_ORGANISATION_REQUEST, payload);
export const getCustomersSuccessAction = (payload: {
    data: DynamicObject[];
    pageData: PageData;
    mergeData?: boolean;
}) => action(CustomersActionTypes.GET_CUSTOMERS_SUCCESS, payload);
export const getCustomersErrorAction = (payload: string[]) =>
    action(CustomersActionTypes.GET_CUSTOMERS_ERROR, payload);

export const updateCustomersFiltersAction = (
    filters: GetCustomersRequestPayload['filters']
) => action(CustomersActionTypes.UPDATE_CUSTOMERS_FILTERS, filters);
export const updateCustomersSortByAndStateAction = (tableSortAndState: {
    sortBy: GetCustomersRequestPayload['sortBy'];
    sortAscending: boolean;
    atbType: number;
}) =>
    action(
        CustomersActionTypes.UPDATE_CUSTOMERS_SORT_BY_AND_ATB,
        tableSortAndState
    );

export const updateCustomersTableFilterAction = (customerState: string) =>
    action(
        CustomersActionTypes.UPDATE_CUSTOMERS_TABLE_FILTER_STATE,
        customerState
    );

/**
 * Action for resetting table filters and sort values.
 */
export const clearCustomersStateAllTableFiltersAction = () =>
    action(CustomersActionTypes.CLEAR_CUSTOMERS_STATE_ALL_TABLE_FILTERS);

export const clearCustomersStateDataAction = () =>
    action(CustomersActionTypes.CLEAR_CUSTOMERS_STATE_DATA);

// Single Record
export const setCustomerSelectedIdRequestAction = (
    customerId: string | null,
    callback: () => void
) =>
    action(CustomersActionTypes.SET_CUSTOMER_SELECTED_ID_REQUEST, {
        customerId,
        callback,
    });
export const setCustomerSelectedIdSuccessAction = (customerId: string | null) =>
    action(CustomersActionTypes.SET_CUSTOMER_SELECTED_ID_SUCCESS, customerId);

/**
 * Actions for getting customer data.
 */
export const getCustomerDataRequestAction = (
    customerId: string,
    isUsingCloudImportType: boolean
) =>
    action(CustomersActionTypes.GET_CUSTOMER_DATA_REQUEST, {
        customerId,
        isUsingCloudImportType,
    });
/**
 * Actions for getting customer data.
 */
export const getOrganisationCustomerDataRequestAction = (
    customerId: string,
    companyId: string,
    region: string,
    isUsingCloudImportType: boolean,
    conversionCurrency: number
) =>
    action(CustomersActionTypes.GET_ORGANISATION_CUSTOMER_DATA_REQUEST, {
        customerId,
        companyId,
        region,
        isUsingCloudImportType,
        conversionCurrency,
    });
export const getCustomerDataSuccessAction = (payload: { record: {} }) =>
    action(CustomersActionTypes.GET_CUSTOMER_DATA_SUCCESS, payload);
export const getCustomerDataErrorAction = (payload: string[]) =>
    action(CustomersActionTypes.GET_CUSTOMER_DATA_ERROR, payload);

export const clearCustomerDataAction = () =>
    action(CustomersActionTypes.CLEAR_CUSTOMER_DATA_SUCCESS);

// Invoice List Actions
export const getCustomerInvoicesRequestAction = (
    payload: GetInvoicesRequestPayload
) => action(CustomersActionTypes.GET_CUSTOMER_INVOICES_REQUEST, payload);
// Invoice List Actions
export const getOrganisationCustomerInvoicesRequestAction = (
    payload: GetInvoicesRequestPayload,
    region: string
) => action(CustomersActionTypes.GET_ORGANISATION_CUSTOMER_INVOICES_REQUEST, {
    payload,
    region
});
export const getCustomerInvoicesSuccessAction = (payload: {
    data: Invoice[];
    pageData: PageData;
}) => action(CustomersActionTypes.GET_CUSTOMER_INVOICES_SUCCESS, payload);
export const getCustomerInvoicesErrorAction = (payload: string[]) =>
    action(CustomersActionTypes.GET_CUSTOMER_INVOICES_ERROR, payload);

export const updateCustomerInvoicesFiltersAction = (
    filters: GetInvoicesRequestPayload['filters']
) => action(CustomersActionTypes.UPDATE_CUSTOMER_INVOICES_FILTERS, filters);
export const updateCustomerInvoicesSortByAndStateAction = (tableSortAndState: {
    sortBy: GetInvoicesRequestPayload['sortBy'];
    sortAscending: boolean;
    invoiceState: string;
}) =>
    action(
        CustomersActionTypes.UPDATE_CUSTOMER_INVOICES_SORT_BY_AND_STATE,
        tableSortAndState
    );
export const updateCustomerInvoicesTableFilterAction = (invoiceState: string) =>
    action(
        CustomersActionTypes.UPDATE_CUSTOMER_INVOICES_TABLE_FILTER_STATE,
        invoiceState
    );

export const saveCustomerUpdateRequestAction = (
    payload: SaveCustomerUpdateRequestPayload
) => action(CustomersActionTypes.SAVE_CUSTOMER_UPDATE_REQUEST, payload);
export const saveOrganisationCustomerUpdateRequestAction = (
    payload: SaveCustomerUpdateRequestPayload,
    region: string
) => action(CustomersActionTypes.SAVE_ORGANISATION_CUSTOMER_UPDATE_REQUEST, {
    payload,
    region
});
export const saveCustomerUpdateResponseAction = () =>
    action(CustomersActionTypes.SAVE_CUSTOMER_UPDATE_RESPONSE);

export const bulkSaveCustomerUpdateRequestAction = (
    payload: BulkSaveCustomerUpdateRequestPayload
) => action(CustomersActionTypes.BULK_SAVE_CUSTOMER_UPDATE_REQUEST, payload);
export const bulkSaveCustomerUpdateResponseAction = () =>
    action(CustomersActionTypes.BULK_SAVE_CUSTOMER_UPDATE_RESPONSE);

// Conversation actions
export const getCustomerConversationRequestAction = (
    payload: GetConversationRequestPayload
) => action(CustomersActionTypes.GET_CUSTOMER_CONVERSATION_REQUEST, payload);
// Conversation actions
export const getOrganisationCustomerConversationRequestAction = (
    payload: GetConversationRequestPayload,
    region: string
) => action(CustomersActionTypes.GET_ORGANISATION_CUSTOMER_CONVERSATION_REQUEST, {
    payload,
    region
});
export const getCustomerConversationSuccessAction = (payload: {
    data: Conversation[];
    pageData: PageData;
}) => action(CustomersActionTypes.GET_CUSTOMER_CONVERSATION_SUCCESS, payload);
export const getCustomerConversationErrorAction = (payload: string[]) =>
    action(CustomersActionTypes.GET_CUSTOMER_CONVERSATION_ERROR, payload);

export const updateCustomerConversationFiltersAction = (
    filters: GetConversationRequestPayload['filters']
) => action(CustomersActionTypes.UPDATE_CUSTOMER_CONVERSATION_FILTERS, filters);

export const updateCustomerConversationTableFilterAction = (
    conversationTableFilter: string
) =>
    action(
        CustomersActionTypes.UPDATE_CUSTOMER_CONVERSATION_TABLE_FILTER,
        conversationTableFilter
    );
export const updateCustomerConversationSortByAction = (tableSort: {
    sortBy: GetConversationRequestPayload['sortBy'];
    sortAscending: boolean;
}) =>
    action(
        CustomersActionTypes.UPDATE_CUSTOMER_CONVERSATION_SORT_BY,
        tableSort
    );

export const customerAddCommentRequestAction = (
    payload: CustomerAddCommentRequestPayload
) => action(CustomersActionTypes.CUSTOMER_ADD_COMMENT_REQUEST, payload);
export const organisationCustomerAddCommentRequestAction = (
    payload: CustomerAddCommentRequestPayload,
    region: string
) => action(CustomersActionTypes.ORGANISATION_CUSTOMER_ADD_COMMENT_REQUEST, {
    payload,
    region
});

export const customerSendInformationRequestAction = (
    payload: CustomerSendInformationRequestPayload
) => action(CustomersActionTypes.CUSTOMER_SEND_INFORMATION_REQUEST, payload);

// Credit List Actions
export const getCustomerCreditsRequestAction = (
    payload: GetCreditsRequestPayload
) => action(CustomersActionTypes.GET_CUSTOMER_CREDITS_REQUEST, payload);
// Credit List Actions
export const getOrganisationCustomerCreditsRequestAction = (
    payload: GetCreditsRequestPayload,
    region: string
) => action(CustomersActionTypes.GET_ORGANISATION_CUSTOMER_CREDITS_REQUEST, {
    payload,
    region
});
export const getCustomerCreditsSuccessAction = (payload: {
    data: Credit[];
    pageData: PageData;
}) => action(CustomersActionTypes.GET_CUSTOMER_CREDITS_SUCCESS, payload);
export const getCustomerCreditsErrorAction = (payload: string[]) =>
    action(CustomersActionTypes.GET_CUSTOMER_CREDITS_ERROR, payload);

export const updateCustomerCreditsFiltersAction = (
    filters: GetCreditsRequestPayload['filters']
) => action(CustomersActionTypes.UPDATE_CUSTOMER_CREDITS_FILTERS, filters);
export const updateCustomerCreditsSortByAndStateAction = (tableSortAndState: {
    sortBy: GetCreditsRequestPayload['sortBy'];
    sortAscending: boolean;
    creditState: string;
}) =>
    action(
        CustomersActionTypes.UPDATE_CUSTOMER_CREDITS_SORT_BY_AND_STATE,
        tableSortAndState
    );
export const updateCustomerCreditsTableFilterAction = (creditState: string) =>
    action(
        CustomersActionTypes.UPDATE_CUSTOMER_CREDITS_TABLE_FILTER_STATE,
        creditState
    );

// Payment List Actions
export const getCustomerPaymentsRequestAction = (
    payload: GetPaymentsRequestPayload
) => action(CustomersActionTypes.GET_CUSTOMER_PAYMENTS_REQUEST, payload);
// Payment List Actions
export const getOrganisationCustomerPaymentsRequestAction = (
    payload: GetPaymentsRequestPayload,
    region: string
) => action(CustomersActionTypes.GET_ORGANISATION_CUSTOMER_PAYMENTS_REQUEST, {
    payload,
    region
});
export const getCustomerPaymentsSuccessAction = (payload: {
    data: Payment[];
    pageData: PageData;
}) => action(CustomersActionTypes.GET_CUSTOMER_PAYMENTS_SUCCESS, payload);
export const getCustomerPaymentsErrorAction = (payload: string[]) =>
    action(CustomersActionTypes.GET_CUSTOMER_PAYMENTS_ERROR, payload);

export const updateCustomerPaymentsFiltersAction = (
    filters: GetPaymentsRequestPayload['filters']
) => action(CustomersActionTypes.UPDATE_CUSTOMER_PAYMENTS_FILTERS, filters);
export const updateCustomerPaymentsSortByAction = (tableSort: {
    sortBy: GetPaymentsRequestPayload['sortBy'];
    sortAscending: boolean;
}) => action(CustomersActionTypes.UPDATE_CUSTOMER_PAYMENTS_SORT_BY, tableSort);
export const updateCustomerPaymentsTableFilterAction = (paymentState: string) =>
    action(
        CustomersActionTypes.UPDATE_CUSTOMER_PAYMENTS_TABLE_FILTER,
        paymentState
    );

/**
* Function for getting the Customer Custom Fields for a company.
* @param payload
*/
export const getCustomerCustomFieldsForCompanyRequestAction = (
    companyId: string,
    callback?: (response: DynamicObject) => void
) =>
    action(CustomersActionTypes.GET_CUSTOMER_CUSTOM_FIELDS_FOR_COMPANY_REQUEST, {
        companyId,
        callback,
    });

/**
* Function for getting the Customer first letter.
* @param payload
*/
export const getCustomerFirstLetterValuesRequestAction = (
    companyId: string,
    callback?: (response: DynamicObject) => void
) =>
    action(CustomersActionTypes.GET_CUSTOMER_FIRST_LETTER_VALUES_REQUEST, {
        companyId,
        callback,
    });

/**
* Function for getting the Customer for organisation.
* @param payload
*/
export const customersFetchedForOrganisationAction = (
    payload: CustomersFetchedForOrganisationPayload
) => action(CustomersActionTypes.CUSTOMERS_FETCHED_FOR_ORGANISATION_RECEIVED, payload);