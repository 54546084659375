import update from 'immutability-helper';
import { concat, get, isEqual, keyBy, merge, uniqWith, values } from 'lodash';
import { Reducer } from 'redux';
import {
    CUSTOMERS_PAGE,
    DETAILS_TAB,
} from '../../config/tableAndPageConstants';
import { conversationTableFilterOptions } from '../../constants/common';
import {
    creditsSortByOptions,
    creditsStateFilterOptions,
} from '../../constants/creditsSortAndFilters';
import {
    contactSelectType,
    customersATBCalculationOptions,
    customersSortByOptionsMap
} from '../../constants/customersSortAndFilters';
import {
    invoicesSortByOptions,
    invoicesStateFilterOptions,
} from '../../constants/invoicesSortAndFilters';
import { paymentsCommonSortByOptions } from '../../constants/paymentsSortAndFilters';
import { CustomersActionTypes, CustomersState } from './types';

// Type-safe initialState!
export const initialState: CustomersState = {
    loading: false,
    saveCustomerDataLoading: false,
    errorMessages: [],
    data: [],
    pageData: {
        pageSize: CUSTOMERS_PAGE.pageSize,
        currentPage: 0,
        hasNextPage: false,
    },
    filters: {
        Customer: '',
        InclusiveContact:
            get(contactSelectType, '0.value') === 'true' ? true : false,
        Contact: 0,
        AmountType: '',
        AmountValue: '',
        AmountOperator: '',
    },
    tableFilter: undefined,
    atbType: get(customersATBCalculationOptions, `${0}.value`),
    sortBy: customersSortByOptionsMap.customerName.value, // for Company Name
    sortAscending: true,
    activeData: {
        record: {},
        invoices: {
            loading: false,
            errorMessages: [],
            data: [],
            pageData: {
                pageSize: DETAILS_TAB.INVOICE_LIST_COMPLETE.pageSize,
                currentPage: 0,
                hasNextPage: false,
            },
            filters: {
                InvoiceNumber: '',
                Customer: '',
                CustomerCode: '',
                CreatedDateMin: '',
                CreatedDateMax: '',
                DueDateMin: '',
                DueDateMax: '',
                AmountType: '',
                AmountValue: '',
                AmountOperator: '',
            },
            invoiceState: get(invoicesStateFilterOptions, `${0}.value`),
            sortBy: invoicesSortByOptions[0].value, // 2 for Invoice number
            sortAscending: true,
        },
        credits: {
            loading: false,
            errorMessages: [],
            data: [],
            pageData: {
                pageSize: DETAILS_TAB.CREDIT_LIST_COMPLETE.pageSize,
                currentPage: 0,
                hasNextPage: false,
            },
            filters: {},
            creditState: get(creditsStateFilterOptions, `${0}.value`),
            sortBy: creditsSortByOptions[0].value,
            sortAscending: true,
        },
        payments: {
            loading: false,
            errorMessages: [],
            data: [],
            pageData: {
                pageSize: DETAILS_TAB.PAYMENT_LIST_COMPLETE.pageSize,
                currentPage: 0,
                hasNextPage: false,
            },
            filters: {},
            sortBy: paymentsCommonSortByOptions[1].value,
            sortAscending: false,
        },
        conversation: {
            loading: false,
            errorMessages: [],
            data: [],
            pageData: {
                pageSize: DETAILS_TAB.CONVERSATION_TIMELINE.pageSize,
                currentPage: 0,
                hasNextPage: false,
            },
            filters: {},
            conversationTableFilter: get(
                conversationTableFilterOptions,
                '0.value'
            ),
            sortBy: 'Created Date Time',
            sortAscending: false,
        },
        loading: false,
        selectedId: null,
        errorMessages: [],
    },
};

// Thanks to Redux 4's much simpler typings, we can take away a lot of typings on the reducer side,
// everything will remain type-safe.
const reducer: Reducer<CustomersState> = (state = initialState, action) => {
    switch (action.type) {
        case CustomersActionTypes.GET_CUSTOMERS_FOR_ORGANISATION_REQUEST:
        case CustomersActionTypes.GET_CUSTOMERS_REQUEST: {
            return { ...state, loading: true };
        }
        case CustomersActionTypes.GET_CUSTOMERS_SUCCESS: {
            let newDataState = [];
            if (action.payload.mergeData === true) {
                newDataState = values(
                    merge(
                        keyBy(state.data, 'Id'),
                        keyBy(action.payload.data, 'Id')
                    )
                );
            } else {
                if (action.payload.pageData.currentPage === 0) {
                    newDataState = update(state.data, {
                        $set: action.payload.data,
                    });
                } else {
                    newDataState = uniqWith(
                        concat(state.data, action.payload.data),
                        isEqual
                    );
                }
            }

            return {
                ...state,
                loading: false,
                data: newDataState,
                pageData: action.payload.pageData,
                errorMessages: initialState.errorMessages,
            };
        }
        case CustomersActionTypes.GET_CUSTOMERS_ERROR: {
            return {
                ...state,
                loading: false,
                data: initialState.data,
                errorMessages: action.payload,
            };
        }
        case CustomersActionTypes.UPDATE_CUSTOMERS_FILTERS: {
            return { ...state, filters: action.payload };
        }

        case CustomersActionTypes.UPDATE_CUSTOMERS_SORT_BY_AND_ATB: {
            return {
                ...state,
                sortBy: action.payload.sortBy,
                sortAscending: action.payload.sortAscending,
                atbType: action.payload.atbType,
            };
        }

        case CustomersActionTypes.UPDATE_CUSTOMERS_TABLE_FILTER_STATE: {
            return {
                ...state,
                tableFilter: action.payload,
            };
        }

        case CustomersActionTypes.CLEAR_CUSTOMERS_STATE_ALL_TABLE_FILTERS: {
            return {
                ...state,
                filters: initialState.filters,
                sortBy: initialState.sortBy,
                sortAscending: initialState.sortAscending,
                tableFilter: initialState.tableFilter,
            };
        }

        case CustomersActionTypes.CLEAR_CUSTOMERS_STATE_DATA: {
            return {
                ...initialState,
            };
        }

        case CustomersActionTypes.SAVE_CUSTOMER_UPDATE_REQUEST:
        case CustomersActionTypes.SAVE_ORGANISATION_CUSTOMER_UPDATE_REQUEST: {
            return {
                ...state,
                saveCustomerDataLoading: true,
            };
        }
        case CustomersActionTypes.SAVE_CUSTOMER_UPDATE_RESPONSE: {
            return {
                ...state,
                saveCustomerDataLoading: false,
            };
        }

        case CustomersActionTypes.BULK_SAVE_CUSTOMER_UPDATE_REQUEST: {
            return {
                ...state,
                saveCustomerDataLoading: true,
            };
        }
        case CustomersActionTypes.BULK_SAVE_CUSTOMER_UPDATE_RESPONSE: {
            return {
                ...state,
                saveCustomerDataLoading: false,
            };
        }

        //Single Record
        case CustomersActionTypes.SET_CUSTOMER_SELECTED_ID_REQUEST: {
            const newActiveData = update(state.activeData, {
                $merge: {
                    selectedId: initialState.activeData.selectedId,
                },
            });

            return {
                ...state,
                activeData: newActiveData,
            };
        }

        case CustomersActionTypes.SET_CUSTOMER_SELECTED_ID_SUCCESS: {
            const newActiveData = update(state.activeData, {
                $merge: {
                    selectedId: action.payload
                },
            });

            return {
                ...state,
                activeData: newActiveData,
            };
        }

        case CustomersActionTypes.GET_CUSTOMER_DATA_REQUEST:
        case CustomersActionTypes.GET_ORGANISATION_CUSTOMER_DATA_REQUEST: {
            const newActiveData = update(state.activeData, {
                record: { $set: initialState.activeData.record },
                loading: { $set: true },
            });

            return {
                ...state,
                activeData: newActiveData,
            };
        }

        case CustomersActionTypes.GET_CUSTOMER_DATA_SUCCESS: {
            const newActiveData = update(state.activeData, {
                record: { $set: action.payload.record },
                loading: { $set: false },
                errorMessages: { $set: initialState.activeData.errorMessages },
            });

            return {
                ...state,
                activeData: newActiveData,
            };
        }

        case CustomersActionTypes.GET_CUSTOMER_DATA_ERROR: {
            const newActiveData = update(state.activeData, {
                record: { $set: initialState.activeData.record },
                loading: { $set: false },
                errorMessages: { $set: action.payload },
            });

            return {
                ...state,
                activeData: newActiveData,
            };
        }

        case CustomersActionTypes.CLEAR_CUSTOMER_DATA_SUCCESS: {
            return {
                ...state,
                activeData: initialState.activeData,
            };
        }

        // For invoices list reducers
        case CustomersActionTypes.GET_ORGANISATION_CUSTOMER_INVOICES_REQUEST:
        case CustomersActionTypes.GET_CUSTOMER_INVOICES_REQUEST: {
            const newInvoicesState = update(state.activeData.invoices, {
                $merge: {
                    loading: true,
                },
            });

            const newActiveData = update(state.activeData, {
                invoices: { $set: newInvoicesState },
            });

            return {
                ...state,
                activeData: newActiveData,
            };
        }

        case CustomersActionTypes.GET_CUSTOMER_INVOICES_SUCCESS: {
            let newDataState = [];
            if (action.payload.pageData.currentPage === 0) {
                newDataState = update(state.activeData.invoices.data, {
                    $set: action.payload.data,
                });
            } else {
                newDataState = uniqWith(
                    concat(state.activeData.invoices.data, action.payload.data),
                    isEqual
                );
            }

            const newInvoicesState = update(state.activeData.invoices, {
                $merge: {
                    loading: false,
                    data: newDataState,
                    pageData: action.payload.pageData,
                    errorMessages:
                        initialState.activeData.invoices.errorMessages,
                },
            });

            const newActiveData = update(state.activeData, {
                invoices: { $set: newInvoicesState },
            });

            return {
                ...state,
                activeData: newActiveData,
            };
        }

        case CustomersActionTypes.GET_CUSTOMER_INVOICES_ERROR: {
            const newInvoicesState = update(state.activeData.invoices, {
                $merge: {
                    loading: false,
                    data: initialState.activeData.invoices.data,
                    errorMessages: action.payload,
                },
            });

            const newActiveData = update(state.activeData, {
                invoices: { $set: newInvoicesState },
            });

            return {
                ...state,
                activeData: newActiveData,
            };
        }

        case CustomersActionTypes.UPDATE_CUSTOMER_INVOICES_FILTERS: {
            const newInvoicesState = update(state.activeData.invoices, {
                $merge: {
                    filters: action.payload,
                },
            });

            const newActiveData = update(state.activeData, {
                invoices: { $set: newInvoicesState },
            });

            return {
                ...state,
                activeData: newActiveData,
            };
        }
        case CustomersActionTypes.UPDATE_CUSTOMER_INVOICES_SORT_BY_AND_STATE: {
            const newInvoicesState = update(state.activeData.invoices, {
                $merge: {
                    sortBy: action.payload.sortBy,
                    sortAscending: action.payload.sortAscending,
                    invoiceState: action.payload.invoiceState,
                },
            });

            const newActiveData = update(state.activeData, {
                invoices: { $set: newInvoicesState },
            });

            return {
                ...state,
                activeData: newActiveData,
            };
        }
        case CustomersActionTypes.UPDATE_CUSTOMER_INVOICES_TABLE_FILTER_STATE: {
            const newInvoicesState = update(state.activeData.invoices, {
                $merge: {
                    invoiceState: action.payload,
                },
            });

            const newActiveData = update(state.activeData, {
                invoices: { $set: newInvoicesState },
            });

            return {
                ...state,
                activeData: newActiveData,
            };
        }

        // For credits list reducers
        case CustomersActionTypes.GET_CUSTOMER_CREDITS_REQUEST:
        case CustomersActionTypes.GET_ORGANISATION_CUSTOMER_CREDITS_REQUEST: {
            const newCreditsState = update(state.activeData.credits, {
                $merge: {
                    loading: true,
                },
            });

            const newActiveData = update(state.activeData, {
                credits: { $set: newCreditsState },
            });

            return {
                ...state,
                activeData: newActiveData,
            };
        }

        case CustomersActionTypes.GET_CUSTOMER_CREDITS_SUCCESS: {
            let newDataState = [];
            if (action.payload.pageData.currentPage === 0) {
                newDataState = update(state.activeData.credits.data, {
                    $set: action.payload.data,
                });
            } else {
                newDataState = uniqWith(
                    concat(state.activeData.credits.data, action.payload.data),
                    isEqual
                );
            }

            const newCreditsState = update(state.activeData.credits, {
                $merge: {
                    loading: false,
                    data: newDataState,
                    pageData: action.payload.pageData,
                    errorMessages:
                        initialState.activeData.credits.errorMessages,
                },
            });

            const newActiveData = update(state.activeData, {
                credits: { $set: newCreditsState },
            });

            return {
                ...state,
                activeData: newActiveData,
            };
        }

        case CustomersActionTypes.GET_CUSTOMER_CREDITS_ERROR: {
            const newCreditsState = update(state.activeData.credits, {
                $merge: {
                    loading: false,
                    data: initialState.activeData.credits.data,
                    errorMessages: action.payload,
                },
            });

            const newActiveData = update(state.activeData, {
                credits: { $set: newCreditsState },
            });

            return {
                ...state,
                activeData: newActiveData,
            };
        }

        case CustomersActionTypes.UPDATE_CUSTOMER_CREDITS_FILTERS: {
            const newCreditsState = update(state.activeData.credits, {
                $merge: {
                    filters: action.payload,
                },
            });

            const newActiveData = update(state.activeData, {
                credits: { $set: newCreditsState },
            });

            return {
                ...state,
                activeData: newActiveData,
            };
        }
        case CustomersActionTypes.UPDATE_CUSTOMER_CREDITS_SORT_BY_AND_STATE: {
            const newCreditsState = update(state.activeData.credits, {
                $merge: {
                    sortBy: action.payload.sortBy,
                    sortAscending: action.payload.sortAscending,
                    creditState: action.payload.creditState,
                },
            });

            const newActiveData = update(state.activeData, {
                credits: { $set: newCreditsState },
            });

            return {
                ...state,
                activeData: newActiveData,
            };
        }
        case CustomersActionTypes.UPDATE_CUSTOMER_CREDITS_TABLE_FILTER_STATE: {
            const newCreditsState = update(state.activeData.credits, {
                $merge: {
                    creditState: action.payload,
                },
            });

            const newActiveData = update(state.activeData, {
                credits: { $set: newCreditsState },
            });

            return {
                ...state,
                activeData: newActiveData,
            };
        }

        // For payments list reducers
        case CustomersActionTypes.GET_CUSTOMER_PAYMENTS_REQUEST:
        case CustomersActionTypes.GET_ORGANISATION_CUSTOMER_PAYMENTS_REQUEST: {
            const newPaymentsState = update(state.activeData.payments, {
                $merge: {
                    loading: true,
                },
            });

            const newActiveData = update(state.activeData, {
                payments: { $set: newPaymentsState },
            });

            return {
                ...state,
                activeData: newActiveData,
            };
        }

        case CustomersActionTypes.GET_CUSTOMER_PAYMENTS_SUCCESS: {
            let newDataState = [];
            if (action.payload.pageData.currentPage === 0) {
                newDataState = update(state.activeData.payments.data, {
                    $set: action.payload.data,
                });
            } else {
                newDataState = uniqWith(
                    concat(state.activeData.payments.data, action.payload.data),
                    isEqual
                );
            }

            const newPaymentsState = update(state.activeData.payments, {
                $merge: {
                    loading: false,
                    data: newDataState,
                    pageData: action.payload.pageData,
                    errorMessages:
                        initialState.activeData.payments.errorMessages,
                },
            });

            const newActiveData = update(state.activeData, {
                payments: { $set: newPaymentsState },
            });

            return {
                ...state,
                activeData: newActiveData,
            };
        }

        case CustomersActionTypes.GET_CUSTOMER_PAYMENTS_ERROR: {
            const newPaymentsState = update(state.activeData.payments, {
                $merge: {
                    loading: false,
                    data: initialState.activeData.payments.data,
                    errorMessages: action.payload,
                },
            });

            const newActiveData = update(state.activeData, {
                payments: { $set: newPaymentsState },
            });

            return {
                ...state,
                activeData: newActiveData,
            };
        }

        case CustomersActionTypes.UPDATE_CUSTOMER_PAYMENTS_FILTERS: {
            const newPaymentsState = update(state.activeData.payments, {
                $merge: {
                    filters: action.payload,
                },
            });

            const newActiveData = update(state.activeData, {
                payments: { $set: newPaymentsState },
            });

            return {
                ...state,
                activeData: newActiveData,
            };
        }
        case CustomersActionTypes.UPDATE_CUSTOMER_PAYMENTS_SORT_BY: {
            const newPaymentsState = update(state.activeData.payments, {
                $merge: {
                    sortBy: action.payload.sortBy,
                    sortAscending: action.payload.sortAscending,
                },
            });

            const newActiveData = update(state.activeData, {
                payments: { $set: newPaymentsState },
            });

            return {
                ...state,
                activeData: newActiveData,
            };
        }
        case CustomersActionTypes.UPDATE_CUSTOMER_PAYMENTS_TABLE_FILTER: {
            const newPaymentsState = update(state.activeData.payments, {
                $merge: {
                    // paymentState: action.payload,
                },
            });

            const newActiveData = update(state.activeData, {
                payments: { $set: newPaymentsState },
            });

            return {
                ...state,
                activeData: newActiveData,
            };
        }

        // For conversation list reducers
        case CustomersActionTypes.GET_CUSTOMER_CONVERSATION_REQUEST:
        case CustomersActionTypes.GET_ORGANISATION_CUSTOMER_CONVERSATION_REQUEST: {
            const newConversationState = update(state.activeData.conversation, {
                $merge: {
                    loading: true,
                },
            });

            const newActiveData = update(state.activeData, {
                conversation: { $set: newConversationState },
            });

            return {
                ...state,
                activeData: newActiveData,
            };
        }

        case CustomersActionTypes.GET_CUSTOMER_CONVERSATION_SUCCESS: {
            let newDataState = [];
            if (action.payload.pageData.currentPage === 0) {
                newDataState = update(state.activeData.conversation.data, {
                    $set: action.payload.data,
                });
            } else {
                newDataState = uniqWith(
                    concat(
                        state.activeData.conversation.data,
                        action.payload.data
                    ),
                    isEqual
                );
            }

            const newConversationState = update(state.activeData.conversation, {
                $merge: {
                    loading: false,
                    data: newDataState,
                    pageData: action.payload.pageData,
                    errorMessages:
                        initialState.activeData.conversation.errorMessages,
                },
            });

            const newActiveData = update(state.activeData, {
                conversation: { $set: newConversationState },
            });

            return {
                ...state,
                activeData: newActiveData,
            };
        }

        case CustomersActionTypes.GET_CUSTOMER_CONVERSATION_ERROR: {
            const newConversationState = update(state.activeData.conversation, {
                $merge: {
                    loading: false,
                    data: initialState.activeData.conversation.data,
                    errorMessages: action.payload,
                },
            });

            const newActiveData = update(state.activeData, {
                conversation: { $set: newConversationState },
            });

            return {
                ...state,
                activeData: newActiveData,
            };
        }

        case CustomersActionTypes.UPDATE_CUSTOMER_CONVERSATION_FILTERS: {
            const newConversationState = update(state.activeData.conversation, {
                $merge: {
                    filters: action.payload,
                },
            });

            const newActiveData = update(state.activeData, {
                conversation: { $set: newConversationState },
            });

            return {
                ...state,
                activeData: newActiveData,
            };
        }

        case CustomersActionTypes.UPDATE_CUSTOMER_CONVERSATION_TABLE_FILTER: {
            const newConversationState = update(state.activeData.conversation, {
                $merge: {
                    conversationTableFilter: action.payload,
                },
            });

            const newActiveData = update(state.activeData, {
                conversation: { $set: newConversationState },
            });

            return {
                ...state,
                activeData: newActiveData,
            };
        }

        case CustomersActionTypes.UPDATE_CUSTOMER_CONVERSATION_SORT_BY: {
            const newConversationState = update(state.activeData.conversation, {
                $merge: {
                    sortBy: action.payload.sortBy,
                    sortAscending: action.payload.sortAscending,
                },
            });

            const newActiveData = update(state.activeData, {
                conversation: { $set: newConversationState },
            });

            return {
                ...state,
                activeData: newActiveData,
            };
        }

        default: {
            return state;
        }
    }
};

// Instead of using default export, we use named exports. That way we can group these exports
// inside the `index.js` folder.
export { reducer as customersReducer };
