import moment from 'moment-timezone';
import { DynamicObject } from '../utils/commonInterfaces';
import { filter } from 'lodash';
import {
    dateSelectOptions,
    invoicesAmountOperatorOptions,
} from './invoicesSortAndFilters';

export const preConfiguredFilters = {
    ALL_REMITTANCE_ADVICES: 'All remittance advices',
};

export const remittanceAdvicesTableFilterOptions = [
    { label: 'All remittance advices', value: preConfiguredFilters.ALL_REMITTANCE_ADVICES },
];

export const remittanceAdviceStatus = {
    Unprocessed: 'Unprocessed',
    Processed: 'Processed',
    Verified: 'Verified',
    Allocated: 'Completed'
};

export const remittanceAdvicesSortByOptions = [
    { label: 'Company name', value: 'company name' },
    { label: 'Received date', value: 'received date' },
    { label: 'Payment date', value: 'paid date' },
    { label: 'Amount', value: 'paid amount' },
    { label: 'Status', value: 'status' },
];

export const remittanceAdvicesCommonSortByOptions = [
    { label: 'Status', value: 'status' },
    { label: 'Received date', value: 'Received date' },
    { label: 'Amount', value: 'paid amount' },
];

export const unallocatedAmountType = 'Unallocated';

export const remittanceAdvicesAmountType = [
    { label: 'Paid', value: 'Paid' },
    { label: 'Unallocated', value: 'Unallocated'}
];

const {
    THIS_MONTH,
    LAST_MONTH,
    LAST_7_DAYS,
    LAST_30_DAYS,
    CUSTOM_DATE_RANGE,
    CUSTOM_DAYS_RANGE,
} = dateSelectOptions;

export const remittanceAdvicesReceivedDateOptions = [
    THIS_MONTH,
    LAST_MONTH,
    LAST_7_DAYS,
    LAST_30_DAYS,
    CUSTOM_DATE_RANGE,
    CUSTOM_DAYS_RANGE,
];


export const remittanceAdviceResults = {
    SUCCESSFUL: 'Successful',
    UNCONFIRMED: 'Unconfirmed',
    FAILED: 'Failed',
};

export const multipleCustomertateOptions = [
    { label: 'Yes', value: 'true' },
    { label: 'No', value: 'false' },
];

export const remittanceAdviceResultOptions = [
    {
        label: remittanceAdviceStatus.Unprocessed,
        value: 1,
    },
    {
        label: remittanceAdviceStatus.Processed,
        value: 2,
    },
    {
        label: remittanceAdviceStatus.Verified,
        value: 4,
    },
    {
        label: remittanceAdviceStatus.Allocated,
        value: 16,
    },
];

export const getRemittanceAdvicesFilterBarFilters = (supportCashAllocation: Boolean, customerValuesOption: any, customerLabel: string) => {
    let amountTypes = filter(
        remittanceAdvicesAmountType,
        (pat: DynamicObject) => (supportCashAllocation || unallocatedAmountType != pat.label)
    );
    
    return [
        {
            filterName: 'Customer',
            filterStateName: 'Customer',
            filterElement: 'input-autocomplete',
            filterQuery: 'GET_CUSTOMERS_FOR_COMPANY_AUTOCOMPLETE_FILTER',
            filterSort: 'Company name',
            filterResponse: 'GetCustomersForCompany.Customers',
            filterLabelField: 'DisplayName',
        },
        {
            filterName: customerLabel,
            filterStateName: 'CustomerFirstLetter',
            filterElement: 'checkbox-group',
            filterType: 'array',
            filterOptions: customerValuesOption
        },
        {
            filterName: 'Multiple Customer',
            filterStateName: 'MultipleCustomer',
            filterElement: 'radio-group',
            filterType: 'text',
            filterPlaceholder: 'Select if multiple customer',
            filterOptions: multipleCustomertateOptions,
        },
        {
            filterName: 'Result',
            filterStateName: 'RemittanceAdviceState',
            filterElement: 'checkbox-group',
            filterType: 'array',
            filterOptions: remittanceAdviceResultOptions,
        },
        {
            filterName: 'Paid on',
            filterStateName: 'PaidDate',
            filterElement: 'select',
            filterType: 'text',
            filterMaxDate: moment(),
            filterOptions: remittanceAdvicesReceivedDateOptions,
            filterOptionsCustomTypes: {
                'Custom date range': 'date-range',
                'Custom days range': 'all-days-range-from-to-last',
            },
        },
        {
            filterName: 'Amount',
            filterStateName: 'Amount',
            filterElement: 'select-multiple-and-input-amount',
            filterType: 'text',
            filterOptions: [
                {
                    AmountType: amountTypes,
                },
                {
                    AmountOperator: invoicesAmountOperatorOptions,
                },
            ],
        },
    ];
};

/**
 * For remittanceAdvice changes
 */

export const remittanceAdviceChangesFilters = {
    ALL_CHANGES: 'All changes',
};

export const remittanceAdviceChangesTableFilterOptions = [
    {
        label: 'All conversations',
        value: remittanceAdviceChangesFilters.ALL_CHANGES,
    },
];

export const remittanceAdviceChangeTypes = {
    NewResource: 'NewResource',
    UpdatedResource: 'UpdatedResource',
    Allocation: 'Allocation',
    Deallocation: 'Deallocation',
    Settlement: 'Settlement',
    ResourceLink: 'ResourceLink',
    Verified: 'Verified',
    Processed: 'Processed',
};
/**
 * For Payment Tab
 */

export const paymentTypes = {
    LINK: 'PaymentURLClick',
    WESTERN_UNION: 'WesternUnion',
    CORPAY: 'Corpay',
    CHEQUE: 'Cheque',
    EZIDEBIT: 'EziDebit',
    INTEGRAPAY: 'IntegraPay',
    ADVAM: 'Advam',
};

export const paymentMethodTypes = {
    CARD: 'Card',
    BANK_DEPOSIT: 'BankDeposit',
};

export const paymentResults = {
    SUCCESSFUL: 'Successful',
    UNCONFIRMED: 'Unconfirmed',
    FAILED: 'Failed',
};
export const remittanceAdviceSource = {
   
    Import :'Import',
    Customer :'Customer',
    User : "User",
    PaymentURLClick : "PaymentURLClick",
    System : "System",
    Email : "Email"
};