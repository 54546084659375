import API, { graphqlOperation } from '@aws-amplify/api';
import { get, map } from 'lodash';
import moment from 'moment-timezone';
import {
    all,
    call,
    fork,
    put,
    takeLatest,
    delay,
    select,
} from 'redux-saga/effects';
import { ApplicationState } from '..';
import {
    API_NAME,
    maxAPIRefetchCount,
    refetchAPIDelay,
} from '../../config/config';
import { DETAILS_TAB, INVOICES_PAGE } from '../../config/tableAndPageConstants';
import {
    FamilyNameAttribute,
    GivenNameAttribute,
} from '../../constants/authUserAttributes';
// import { ApplicationState } from '..';
import queries from '../../graphql/queries.graphql';
import subscriptions from '../../graphql/subscriptions.graphql';
import {
    removeAppliedFiltersForApiRequest,
    getSortFieldsWithCustomFields,
    checkShouldRequestRefetch,
    formatDateToDateObjectUTC,
    getRegionConfigFromList,
    getGraphqlQueryString,
    getCompanyFlagValue,
} from '../../utils/commonFunctions';
import { DynamicObject } from '../../utils/commonInterfaces';
import { PageData } from '../common/types';
import { getCurrentUser } from '../users/sagas';
import {
    getInvoiceConversationErrorAction,
    getInvoiceConversationSuccessAction,
    getInvoiceDataErrorAction,
    getInvoiceDataSuccessAction,
    getInvoicesErrorAction,
    getInvoicesSuccessAction,
    setSelectedInvoiceIdSuccessAction,
    getInvoicesRequestAction,
    getInvoiceChangesSuccessAction,
    getInvoiceChangesErrorAction,
    getInvoicesForOrganisationRequestAction,
    invoicesFetchedForOrganisationAction,
} from './actions';
import { Invoice, InvoicesActionTypes, InvoicesFetchedData } from './types';
import { getRegionKeyConfig, getRegionSettingConfig } from '../auth/sagas';
import { Auth } from 'aws-amplify';
import { AtbViewType } from '../../constants/settings';
import { store } from '../..';
import { SubscribeToUpdates } from '../../utils/SubscriptionService';

export const getInvoiceData = (state: ApplicationState) =>
    state.invoices.activeData;

export const getSelectedInvoiceId = (state: ApplicationState) =>
    state.invoices.activeData.selectedId;

export const getInvoicesPageData = (state: ApplicationState) =>
    state.invoices.pageData;

let refetchCount = 0;
/**
 * Function that fetches the invoice list - api connection.
 * @param param0
 */
function* handleGetInvoicesRequest({ payload }: any) {
    const errorMessage = 'Error fetching invoice list. Please try again later.';
    try {
        const {
            filters,
            invoiceState,
            sortBy,
            sortAscending,
            pageSize,
            currentPage,
            isUsingCloudImportType,
            usingMultipleWorkflow,
            usingCustomerWorkflow,
            isPaymentPlanEnabled,
            excludeInvoices
        } = payload;
        const cleanFilters = removeAppliedFiltersForApiRequest(
            filters,
            true,
            'invoice',
            true
        );

        const sortObject = getSortFieldsWithCustomFields(sortBy);
        // To call async functions, use redux-saga's `call()`.
        const res: DynamicObject = yield call(
            [API, 'graphql'],
            graphqlOperation(queries.GET_INVOICES_FOR_COMPANY, {
                ...cleanFilters,
                InvoiceState: invoiceState,
                // SortField: sortBy,
                ...sortObject,
                Ascending: sortAscending,
                PageSize: pageSize,
                Skip: currentPage * INVOICES_PAGE.pageSize,
                IsCloudImportType: isUsingCloudImportType,
                IsMultipleWorkflow: usingMultipleWorkflow,
                UsingCustomerWorkflow: usingCustomerWorkflow,
                PaymentPlanEnabled: isPaymentPlanEnabled,
                ExcludeInvoices: excludeInvoices
            })
        );

        const { Invoices } = get(res.data, 'GetInvoicesForCompany');
        if (Invoices) {
            const responsePayload = {
                data: Invoices,
                pageData: {
                    pageSize: pageSize,
                    currentPage: currentPage,
                    hasNextPage:
                        !(Invoices.length < pageSize) &&
                        !(pageSize < INVOICES_PAGE.pageSize),
                },
            };

            refetchCount = 0;
            yield put(getInvoicesSuccessAction(responsePayload));
        }
    } catch (err) {
        if (err instanceof Error) {
            console.log('Error', err);
        } else {
            console.error('An unknown error occured.', err);
        }

        if (
            refetchCount <= maxAPIRefetchCount &&
            checkShouldRequestRefetch(err)
        ) {
            refetchCount++;
            yield delay(refetchAPIDelay);
            yield put(getInvoicesRequestAction(payload));
        } else {
            yield put(getInvoicesErrorAction([errorMessage]));
        }
    }
}

function* handleGetInvoicesForOrganisationRequest({ payload: sagaPayload }: any) {
    let { refetchCount, ...payload } = sagaPayload;
    refetchCount = refetchCount || 0;
    const errorMessage = 'Error fetching invoice list. Please try again later.';
    try {
        const {
            filters,
            invoiceState,
            sortBy,
            sortAscending,
            pageSize,
            currentPage,
            isUsingCloudImportType,
            usingMultipleWorkflow,
            usingCustomerWorkflow,
            region,
            conversionCurrency,
            organisationCurrencies,
        } = payload;
        const cleanFilters = removeAppliedFiltersForApiRequest(
            filters,
            true,
            'invoice'
        );

        const sortObject = getSortFieldsWithCustomFields(sortBy);

        let apiUrl: string | undefined = undefined;
        if (region) {
            let regionKeyConfig: DynamicObject[] = yield select(
                getRegionKeyConfig
            );
            let regionSettingsConfig: DynamicObject[] = yield select(
                getRegionSettingConfig
            );
            const configUsed = getRegionConfigFromList(
                region,
                regionKeyConfig,
                regionSettingsConfig
            );
            apiUrl = get(configUsed, 'Url');
        }

        //let res: DynamicObject;
        const usedQuery = queries.GET_INVOICES_FOR_ORGANISATION;
        const variables = {
            InvoiceState: invoiceState,
            ...cleanFilters,
            // SortField: sortBy,
            ...sortObject,
            Ascending: sortAscending,
            PageSize: pageSize,
            Skip: currentPage * INVOICES_PAGE.pageSize,
            IsCloudImportType: isUsingCloudImportType,
            UsingCustomerWorkflow: usingCustomerWorkflow,
            IsMultipleWorkflow: usingMultipleWorkflow,
            ConversionCurrency: conversionCurrency,
            OrganisationCurrencies: organisationCurrencies
        };

        if (apiUrl) {
            const currentSession: DynamicObject = yield Auth.currentSession();
            const accessToken = get(currentSession, 'accessToken.jwtToken');
            //const response: Response = yield fetch(
            yield fetch(
                `${apiUrl}/graphql`,
                {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        Authorization: accessToken,
                    },
                    body: JSON.stringify({
                        query: getGraphqlQueryString(usedQuery),
                        variables,
                    }),
                }
            );

            //res = yield response.json();
        } else {
            //res = yield call(
            yield call(
                [API, 'graphql'],
                graphqlOperation(usedQuery, variables)
            );
        }

        //const { Invoices } = get(res.data, 'GetInvoicesForOrganisation');
        //if (Invoices) {
        //    const responsePayload = {
        //        data: Invoices,
        //        pageData: {
        //            pageSize: pageSize,
        //            currentPage: currentPage,
        //            hasNextPage:
        //                !(Invoices.length < pageSize) &&
        //                !(pageSize < INVOICES_PAGE.pageSize),
        //        },
        //    };

        //    refetchCount = 0;
        //    yield put(getInvoicesSuccessAction(responsePayload));
        //}

        // Use the subscription function
        yield call(
            (subscriptionQuery, onNext, onError) =>
                SubscribeToUpdates<InvoicesFetchedData>(subscriptionQuery, onNext, onError),
            subscriptions.ON_INVOICES_FETCHED_FOR_ORGANISATION,
            InvoicesFetchedForOrganisationReceived({ pageSize, currentPage }),
            function handleError(error: any) {
                console.error('Subscription error:', JSON.stringify(error, null, 2));
            }
        );
    } catch (err) {
        if (err instanceof Error) {
            console.log('Error', err);
        } else {
            console.error('An unknown error occured.', err);
        }

        if (
            refetchCount <= maxAPIRefetchCount &&
            checkShouldRequestRefetch(err)
        ) {
            refetchCount++;
            yield delay(refetchAPIDelay);
            yield put(getInvoicesForOrganisationRequestAction({ ...payload, refetchCount }));
        } else {
            yield put(getInvoicesErrorAction([errorMessage]));
        }
    }
}

/**
 * Function that calls the API for getting the invoice details based on the given Id.
 * @param param0
 */
function* handleGetInvoiceDataRequest({
    payload: { invoiceId, isUsingCloudImportType },
}: any) {
    const errorMessage =
        'Error fetching invoice details. Please try again later.';
    try {
        const isCalendarView: boolean = yield select(
            (state: ApplicationState) => getCompanyFlagValue(state, AtbViewType.CalendarView)
        );
        // To call async functions, use redux-saga's `call()`.
        const res: DynamicObject = yield call(
            [API, 'graphql'],
            graphqlOperation(queries.GET_INVOICE_DETAILS_FOR_COMPANY, {
                InvoiceId: invoiceId,
                IsCloudImportType: isUsingCloudImportType,
                IsCalendarView: isCalendarView
            })
        );

        const Invoice = get(res.data, 'GetInvoiceDetailsForCompany');
        if (Invoice) {
            const responsePayload = {
                record: Invoice,
            };

            yield put(getInvoiceDataSuccessAction(responsePayload));
        } else {
            yield put(getInvoiceDataErrorAction([errorMessage]));
        }
    } catch (err) {
        if (err instanceof Error) {
            console.log('Error', err);
        } else {
            console.error('An unknown error occured.', err);
        }

        yield put(getInvoiceDataErrorAction([errorMessage]));
    }
}

/**
 * Function calling the API for fetching the invoice data based on the given id.
 * @param param0
 */
function* handleGetOrganisationInvoiceDataRequest({
    payload: {
        invoiceId: invoiceId,
        region,
        isUsingCloudImportType
    },
}: any) {
    const errorMessage =
        'Error fetching invoice details. Please try again later.';
    try {
        let apiUrl: string | undefined = undefined;
        if (region) {
            let regionKeyConfig: DynamicObject[] = yield select(
                getRegionKeyConfig
            );
            let regionSettingsConfig: DynamicObject[] = yield select(
                getRegionSettingConfig
            );
            const configUsed = getRegionConfigFromList(
                region,
                regionKeyConfig,
                regionSettingsConfig
            );
            apiUrl = get(configUsed, 'Url');
        }

        const isCalendarView: boolean = yield select(
            (state: ApplicationState) => getCompanyFlagValue(state, AtbViewType.CalendarView)
        );
        let res: DynamicObject;
        let variables = {
            InvoiceId: invoiceId,
            IsCloudImportType: isUsingCloudImportType,
            IsCalendarView: isCalendarView
        };
        if (apiUrl) {
            const currentSession: DynamicObject = yield Auth.currentSession();
            const accessToken = get(currentSession, 'accessToken.jwtToken');
            const response: Response = yield fetch(
                `${apiUrl}/graphql`,
                {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        Authorization: accessToken,
                    },
                    body: JSON.stringify({
                        query: getGraphqlQueryString(queries.GET_INVOICE_DETAILS_FOR_ORGANISATION),
                        variables,
                    }),
                }
            );

            res = yield response.json();
        } else {
            res = yield call(
                [API, 'graphql'],
                graphqlOperation(queries.GET_INVOICE_DETAILS_FOR_ORGANISATION, variables)
            );
        }
        const Invoice = get(res.data, 'GetInvoiceDetailsForOrganisation');

        if (Invoice) {
            const responsePayload = {
                record: Invoice,
            };

            yield put(getInvoiceDataSuccessAction(responsePayload));
        } else {
            yield put(getInvoiceDataErrorAction([errorMessage]));
        }
    } catch (err) {
        if (err instanceof Error) {
            console.log('Error', err);
        } else {
            console.error('An unknown error occured.', err);
        }

        yield put(getInvoiceDataErrorAction([errorMessage]));
    }
}

/**
 * Function that checks if the invoices selected belong to a single customer or more.
 * @param param0
 */
function* handleCheckInvoicesIfSingleCustomer({ payload }: any) {
    const {
        filters,
        invoiceState,
        sortBy,
        sortAscending,
        pageSize,
        currentPage,
        callback,
    } = payload;

    try {
        const cleanFilters = removeAppliedFiltersForApiRequest(
            filters,
            true,
            'invoice'
        );
        // To call async functions, use redux-saga's `call()`.
        const res: DynamicObject = yield call(
            [API, 'graphql'],
            graphqlOperation(queries.CHECK_INVOICES_IF_SINGLE_CUSTOMER, {
                ...cleanFilters,
                InvoiceState: invoiceState,
                SortField: sortBy,
                Ascending: sortAscending,
                PageSize: pageSize,
                Skip: currentPage * INVOICES_PAGE.pageSize,
            })
        );

        const responseData = get(res.data, 'CheckInvoicesIfSingleCustomer');

        if (callback) callback(responseData);
    } catch (err) {
        console.log('err: ', err);
        if (callback) {
            callback(false);
        }
        if (err instanceof Error) {
            console.log('Error', err);
        } else {
            console.error('An unknown error occured.');
        }
    }
}

/**
 * Function called for raising a ticket for single or multiple invoices.
 * @param param0
 */
function* handleRaiseQueryInvoiceBulkRequest({ payload: sagaPayload }: any) {
    const {
        InvoiceIds,
        CustomerId,
        CompanyId,
        TicketOptionId,
        WorkflowId,
        Details,
        InvoiceManagementFilter,
        callback,
    } = sagaPayload;

    if (InvoiceManagementFilter.CustomFieldFilters) {
        InvoiceManagementFilter.CustomFieldFilters = JSON.parse(
            InvoiceManagementFilter.CustomFieldFilters
        );
    }

    const payload = {
        Ticket: {
            CompanyId,
            WorkflowId,
            TicketOption: {
                Id: TicketOptionId,
            },
            Invoices: map(InvoiceIds, (Id) => ({
                Id,
            })),
            Customer: {
                Id: CustomerId,
            },
            Details,
        },
        InvoiceManagementFilter,
    };

    try {
        const res: DynamicObject = yield call(
            [API, 'post'],
            API_NAME,
            '/ticket/bulkinvoice',
            {
                body: payload,
            }
        );

        const { TaskEditAction } = res;
        let successMessages: any = [];
        if (TaskEditAction) {
            successMessages.push(`Ticket has been sent successfully!`);
        }
        if (callback) {
            const response = {
                IsSuccess: true,
                Messages: successMessages,
            };
            callback(response);
        }
    } catch (err) {
        if (callback) {
            const returnData = get(err.response, 'data')
                ? err.response.data
                : { Messages: [err.message] };
            returnData.IsSuccess = false;
            callback(returnData);
        }
        if (err instanceof Error) {
            console.log('Error', err);
        } else {
            console.error('An unknown error occured.');
        }
    }
}

/**
 * Function that sets the selected invoice id in the redux state for reference.
 * @param param0
 */
function* handleSetSelectedInvoiceIdRequest({ payload }: any) {
    const { invoiceId, callback } = payload;
    yield put(setSelectedInvoiceIdSuccessAction(invoiceId));
    callback();
}

/**
 * Function that fetches the conversation list of an invoice.
 * @param param0
 */
function* handleGetInvoiceConversationRequest({ payload }: any) {
    const errorMessage = `Error fetching invoice's conversation list. Please try again later.`;
    try {
        const { filters, sortBy, sortAscending, pageSize, currentPage } =
            payload;

        const cleanFilters = removeAppliedFiltersForApiRequest(
            filters,
            true,
            'invoice',
            true
        );

        const res: DynamicObject = yield call(
            [API, 'graphql'],
            graphqlOperation(queries.GET_CONVERSATION_LINES_FOR_COMPANY, {
                ...cleanFilters,
                SortField: sortBy,
                Ascending: sortAscending,
                PageSize: pageSize,
                Skip: currentPage * DETAILS_TAB.CONVERSATION_TIMELINE.pageSize,
            })
        );

        const { ConversationLines } = get(
            res.data,
            'GetConversationLinesForCompany'
        );
        const Conversation = ConversationLines;

        if (Conversation) {
            const responsePayload = {
                data: Conversation,
                pageData: {
                    pageSize,
                    currentPage: currentPage,
                    hasNextPage:
                        !(Conversation.length < pageSize) &&
                        !(
                            pageSize <
                            DETAILS_TAB.CONVERSATION_TIMELINE.pageSize
                        ),
                },
            };

            yield put(getInvoiceConversationSuccessAction(responsePayload));
        }
    } catch (err) {
        if (err instanceof Error) {
            console.log('Error', err);
        } else {
            console.error('An unknown error occured.', err);
        }

        yield put(getInvoiceConversationErrorAction([errorMessage]));
    }
}

/**
 * Function for fetching the conversation list of a given customer.
 * @param param0
 */
function* handleGetOrganisationInvoiceConversationRequest({
    payload: sagaPayload
}: any) {
    const errorMessage = `Error fetching invoice's conversation list. Please try again later.`;
    const { payload, region } = sagaPayload;

    try {
        const { filters, sortBy, sortAscending, pageSize, currentPage } =
            payload;

        const cleanFilters = removeAppliedFiltersForApiRequest(
            filters,
            true,
            'invoice',
            true
        );
        let apiUrl: string | undefined = undefined;
        if (region) {
            let regionKeyConfig: DynamicObject[] = yield select(
                getRegionKeyConfig
            );
            let regionSettingsConfig: DynamicObject[] = yield select(
                getRegionSettingConfig
            );
            const configUsed = getRegionConfigFromList(
                region,
                regionKeyConfig,
                regionSettingsConfig
            );
            apiUrl = get(configUsed, 'Url');
        }

        let res: DynamicObject;
        const usedQuery = queries.GET_CONVERSATION_LINES_FOR_ORGANISATION;

        const variables = {
            ...cleanFilters,
            SortField: sortBy,
            Ascending: sortAscending,
            PageSize: pageSize,
            Skip: currentPage * DETAILS_TAB.CONVERSATION_TIMELINE.pageSize,
        };

        if (apiUrl) {
            const currentSession: DynamicObject = yield Auth.currentSession();
            const accessToken = get(currentSession, 'accessToken.jwtToken');
            const response: Response = yield fetch(
                `${apiUrl}/graphql`,
                {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        Authorization: accessToken,
                    },
                    body: JSON.stringify({
                        query: getGraphqlQueryString(usedQuery),
                        variables,
                    }),
                }
            );

            res = yield response.json();
        } else {
            res = yield call(
                [API, 'graphql'],
                graphqlOperation(usedQuery, variables)
            );
        }

        const { ConversationLines } = get(res.data, 'GetConversationLinesForOrganisation');
        const Conversation = ConversationLines;

        if (Conversation) {
            const responsePayload = {
                data: Conversation,
                pageData: {
                    pageSize,
                    currentPage: currentPage,
                    hasNextPage:
                        !(Conversation.length < pageSize) &&
                        !(
                            pageSize <
                            DETAILS_TAB.CONVERSATION_TIMELINE.pageSize
                        ),
                },
            };

            yield put(getInvoiceConversationSuccessAction(responsePayload));
        }
    } catch (err) {
        if (err instanceof Error) {
            console.log('Error', err);
        } else {
            console.error('An unknown error occured.', err);
        }

        yield put(getInvoiceConversationErrorAction([errorMessage]));
    }
}

/**
 * Function called for adding a comment in the invoice conversation list.
 * @param param0
 */
function* handleInvoiceAddCommentRequest({ payload: sagaPayload }: any) {
    const { filter, invoiceIds, excludeInvoices, comment, callback } =
        sagaPayload;
    const cleanFilters = removeAppliedFiltersForApiRequest(
        filter,
        true,
        'invoice'
    );

    if (cleanFilters.CustomFieldFilters) {
        cleanFilters.CustomFieldFilters = JSON.parse(
            cleanFilters.CustomFieldFilters
        );
    }

    const payload = {
        InvoiceManagementFilter: {
            ...cleanFilters,
            InvoiceIds: invoiceIds,
            ExcludeInvoices: excludeInvoices,
        },
        Comment: comment,
    };

    try {
        yield call(
            [API, 'post'],
            API_NAME,
            '/conversation/save/invoicecomment',
            {
                body: payload,
            }
        );
        if (callback) {
            let RefetchList = true;
            if (invoiceIds.length === 1 && excludeInvoices === false) {
                RefetchList = false;
                const currentUser: DynamicObject = yield select(getCurrentUser);
                const invoicesUpdated: DynamicObject[] = map(
                    invoiceIds,
                    (uId: string) => {
                        return {
                            Id: uId,
                            ConversationLine: {
                                Content: comment,
                                CreatedDateTime: formatDateToDateObjectUTC(
                                    moment(),
                                    undefined,
                                    true
                                ),
                                User: {
                                    GivenName: get(
                                        currentUser,
                                        GivenNameAttribute
                                    ),
                                    FamilyName: get(
                                        currentUser,
                                        FamilyNameAttribute
                                    ),
                                },
                            },
                        };
                    }
                );
                const pageData: PageData = yield select(getInvoicesPageData);
                yield put(
                    getInvoicesSuccessAction({
                        data: invoicesUpdated,
                        pageData,
                        mergeData: true,
                    })
                );
            }
            const response = {
                IsSuccess: true,
                RefetchList,
            };
            callback(response);
        }
    } catch (err) {
        if (callback) {
            const returnData = get(err.response, 'data')
                ? err.response.data
                : { Messages: [err.message] };
            returnData.IsSuccess = false;
            callback(returnData);
        }
        if (err instanceof Error) {
            console.log('Error', err);
        } else {
            console.error('An unknown error occured.');
        }
    }
}

/**
 * Function for adding comment to the customer's conversation list.
 * @param param0
 */
function* handleOrganisationInvoiceAddCommentRequest({ payload: sagaPayload }: any) {
    const { payload, region } = sagaPayload;
    const { filter, invoiceIds, excludeInvoices, comment, callback } =
        payload;
    const cleanFilters = removeAppliedFiltersForApiRequest(
        filter,
        true,
        'invoice'
    );

    if (cleanFilters.CustomFieldFilters) {
        cleanFilters.CustomFieldFilters = JSON.parse(
            cleanFilters.CustomFieldFilters
        );
    }

    const postPayload = {
        InvoiceManagementFilter: {
            ...cleanFilters,
            InvoiceIds: invoiceIds,
            ExcludeInvoices: excludeInvoices,
        },
        Comment: comment,
    };

    try {
        let apiUrl: string | undefined = undefined;

        if (region) {
            let regionKeyConfig: DynamicObject[] = yield select(
                getRegionKeyConfig
            );
            let regionSettingsConfig: DynamicObject[] = yield select(
                getRegionSettingConfig
            );
            const configUsed = getRegionConfigFromList(
                region,
                regionKeyConfig,
                regionSettingsConfig
            );
            apiUrl = get(configUsed, 'Url');
        }

        if (apiUrl) {
            const currentSession: DynamicObject = yield Auth.currentSession();
            const accessToken = get(currentSession, 'accessToken.jwtToken');

            yield fetch(
                `${apiUrl}/conversation/save/invoicecomment`,
                {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        Authorization: accessToken,
                    },
                    body: JSON.stringify(postPayload),
                }
            );
        }
        else {
            yield call(
                [API, 'post'],
                API_NAME,
                '/conversation/save/invoicecomment',
                {
                    body: postPayload,
                }
            );
        }
        
        if (callback) {
            let RefetchList = true;
            if (invoiceIds.length === 1 && excludeInvoices === false) {
                RefetchList = false;
                const currentUser: DynamicObject = yield select(getCurrentUser);
                const invoicesUpdated: DynamicObject[] = map(
                    invoiceIds,
                    (uId: string) => {
                        return {
                            Id: uId,
                            ConversationLine: {
                                Content: comment,
                                CreatedDateTime: formatDateToDateObjectUTC(
                                    moment(),
                                    undefined,
                                    true
                                ),
                                User: {
                                    GivenName: get(
                                        currentUser,
                                        GivenNameAttribute
                                    ),
                                    FamilyName: get(
                                        currentUser,
                                        FamilyNameAttribute
                                    ),
                                },
                            },
                        };
                    }
                );
                const pageData: PageData = yield select(getInvoicesPageData);
                yield put(
                    getInvoicesSuccessAction({
                        data: invoicesUpdated,
                        pageData,
                        mergeData: true,
                    })
                );
            }
            const response = {
                IsSuccess: true,
                RefetchList,
            };
            callback(response);
        }
    } catch (err) {
        if (callback) {
            const returnData = get(err.response, 'data')
                ? err.response.data
                : { Messages: [err.message] };
            returnData.IsSuccess = false;
            callback(returnData);
        }
        if (err instanceof Error) {
            console.log('Error', err);
        } else {
            console.error('An unknown error occured.');
        }
    }
}
/**
 * Function for checking the pause/resume state of invoices selected to enable/disable the `Pause/Resume` button in
 * Invoices page.
 * @param param0
 */
function* handleCheckInvoicesPauseResumeState({ payload }: any) {
    const {
        filters,
        sortBy,
        sortAscending,
        pageSize,
        currentPage,
        excludeInvoices,
        callback,
    } = payload;
    // To call async functions, use redux-saga's `call()`.
    const cleanFilters = removeAppliedFiltersForApiRequest(
        filters,
        true,
        'invoice'
    );

    const sortObject = getSortFieldsWithCustomFields(sortBy);
    try {
        // To call async functions, use redux-saga's `call()`.
        const res: DynamicObject = yield call(
            [API, 'graphql'],
            graphqlOperation(queries.GET_INVOICE_SET_BLOCKED_STATE, {
                ...cleanFilters,
                ...sortObject,
                ExcludeInvoices: excludeInvoices,
                Ascending: sortAscending,
                PageSize: pageSize,
                Skip: currentPage * INVOICES_PAGE.pageSize,
            })
        );

        const blockedStateResponse = get(res.data, 'GetInvoiceSetBlockedState');

        if (callback) callback(blockedStateResponse);
    } catch (err) {
        if (callback) {
            callback(false);
        }
        if (err instanceof Error) {
            console.log('Error', err);
        } else {
            console.error('An unknown error occured.');
        }
    }
}

/**
 * Function called for handling invoices pause/resume action.
 * @param param0
 */
function* handleInvoicePauseResumeRequest({ payload: sagaPayload }: any) {
    const { filter, invoiceIds, excludeInvoices, isBlocked, callback } =
        sagaPayload;
    const cleanFilters = removeAppliedFiltersForApiRequest(
        filter,
        true,
        'invoice'
    );

    if (cleanFilters.CustomFieldFilters) {
        cleanFilters.CustomFieldFilters = JSON.parse(
            cleanFilters.CustomFieldFilters
        );
    }

    const payload = {
        InvoiceManagementFilter: {
            ...cleanFilters,
            InvoiceIds: invoiceIds,
            ExcludeInvoices: excludeInvoices,
        },
        IsBlocked: isBlocked,
    };

    try {
        yield call([API, 'post'], API_NAME, '/invoice/batch/save', {
            body: payload,
        });
        if (callback) {
            const response = {
                IsSuccess: true,
            };
            callback(response);
        }
    } catch (err) {
        if (callback) {
            const returnData = get(err.response, 'data')
                ? err.response.data
                : { Messages: [err.message] };
            returnData.IsSuccess = false;
            callback(returnData);
        }
        if (err instanceof Error) {
            console.log('Error', err);
        } else {
            console.error('An unknown error occured.');
        }
    }
}

/**
 * Function for fetching the invoiceChanges list of a given invoice.
 * @param param0
 */
function* handleGetInvoiceChangesRequest({ payload }: any) {
    const errorMessage = `Error fetching invoice changes list. Please try again later.`;
    try {
        const { filters, pageSize, currentPage } = payload;

        const cleanFilters = removeAppliedFiltersForApiRequest(filters, true);

        const res: DynamicObject = yield call(
            [API, 'graphql'],
            graphqlOperation(queries.GET_CHANGE_LINES_FOR_COMPANY, {
                ...cleanFilters,
                PageSize: pageSize,
                Skip:
                    currentPage * DETAILS_TAB.INVOICE_CHANGES_TIMELINE.pageSize,
            })
        );

        const { ChangeLines } = get(res.data, 'GetChangeLinesForCompany');

        if (ChangeLines) {
            const responsePayload = {
                data: ChangeLines,
                pageData: {
                    pageSize,
                    currentPage: currentPage,
                    hasNextPage:
                        !(ChangeLines.length < pageSize) &&
                        !(
                            pageSize <
                            DETAILS_TAB.INVOICE_CHANGES_TIMELINE.pageSize
                        ),
                },
            };

            yield put(getInvoiceChangesSuccessAction(responsePayload));
        }
    } catch (err) {
        if (err instanceof Error) {
            console.log('Error', err);
        } else {
            console.error('An unknown error occured.', err);
        }

        yield put(getInvoiceChangesErrorAction([errorMessage]));
    }
}


/**
 * Function for fetching the creditChanges list of a given credit.
 * @param param0
 */
function* handleGetOrganisationInvoiceChangesRequest({
    payload: sagaPayload
}: any) {
    const errorMessage = `Error fetching invoice changes list. Please try again later.`;
    const { payload, region } = sagaPayload;

    try {
        const { filters, pageSize, currentPage } = payload;
        const cleanFilters = removeAppliedFiltersForApiRequest(filters, true);

        let apiUrl: string | undefined = undefined;
        
        if (region) {
            let regionKeyConfig: DynamicObject[] = yield select(
                getRegionKeyConfig
            );
            let regionSettingsConfig: DynamicObject[] = yield select(
                getRegionSettingConfig
            );
            const configUsed = getRegionConfigFromList(
                region,
                regionKeyConfig,
                regionSettingsConfig
            );
            apiUrl = get(configUsed, 'Url');
        }
        
        let res: DynamicObject;
        const usedQuery = queries.GET_CHANGE_LINES_FOR_ORGANISATION;

        const variables = {
            ...cleanFilters,
            PageSize: pageSize,
            Skip:
                currentPage * DETAILS_TAB.CREDIT_CHANGES_TIMELINE.pageSize,
        };

        if (apiUrl) {
            const currentSession: DynamicObject = yield Auth.currentSession();
            const accessToken = get(currentSession, 'accessToken.jwtToken');
            const response: Response = yield fetch(
                `${apiUrl}/graphql`,
                {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        Authorization: accessToken,
                    },
                    body: JSON.stringify({
                        query: getGraphqlQueryString(usedQuery),
                        variables,
                    }),
                }
            );

            res = yield response.json();
        } else {
            res = yield call(
                [API, 'graphql'],
                graphqlOperation(usedQuery, variables)
            );
        }

        const { ChangeLines } = get(res.data, 'GetChangeLinesForOrganisation');

        if (ChangeLines) {
            const responsePayload = {
                data: ChangeLines,
                pageData: {
                    pageSize,
                    currentPage: currentPage,
                    hasNextPage:
                        !(ChangeLines.length < pageSize) &&
                        !(
                            pageSize <
                            DETAILS_TAB.CREDIT_CHANGES_TIMELINE.pageSize
                        ),
                },
            };

            yield put(getInvoiceChangesSuccessAction(responsePayload));
        }
    } catch (err) {
        if (err instanceof Error) {
            console.log('Error', err);
        } else {
            console.error('An unknown error occured.', err);
        }

        yield put(getInvoiceChangesErrorAction([errorMessage]));
    }
}

/**
 * Function for getting the invoice custom fields for a company.
 * @param param0
 */
function* handleGetInvoiceCustomFieldsForCompanyRequest({ payload }: any) {
    const errorMessage = '';
    const { 
            CompanyId, 
            callback
        } = payload;
    try {
        const errorMessage =
        'Error fetching custom field values. Please try again later.';
        const res: DynamicObject = yield call(
            [API, 'graphql'],
            graphqlOperation(queries.GET_INVOICE_CUSTOM_FIELDS_FOR_COMPANY, {
                CompanyId: CompanyId,
            })
        );

        const CustomFieldValues  = get(res.data, 'GetInvoiceCustomFieldsForCompany');
        
        if (callback) {
            CustomFieldValues.IsSuccess = true;
            callback(CustomFieldValues);
        }
    } catch (err) {
        if (callback) callback([]);
        if (err instanceof Error) {
            console.log('Error', err);
        } else {
            console.error('An unknown error occured.');
        }
    }
}
/* Function that fetches the invoice list - api connection.
 * @param param0
 */
function* handleGetInvoicesForPaymentPlanRequestAction({ payload: sagaPayload }: any) {
    const { payload, callback } = sagaPayload;

    try {
        const {
            invoiceState,
            sortBy,
            sortAscending,
            pageSize,
            currentPage,
            isUsingCloudImportType,
            usingMultipleWorkflow,
            usingCustomerWorkflow,
            isPaymentPlanEnabled,
            excludeInvoices,
            ...filters
        } = payload;
    
        const sortObject = getSortFieldsWithCustomFields(sortBy);
        let finalInvoices: DynamicObject[] = [];
        let fetching = true;
        let skip = 0
        
        while (fetching) {
            // To call async functions, use redux-saga's `call()`.
            const res: DynamicObject = yield call(
                [API, 'graphql'],
                graphqlOperation(queries.GET_INVOICES_FOR_COMPANY, {
                    ...filters,
                    InvoiceState: invoiceState,
                    // SortField: sortBy,
                    ...sortObject,
                    Ascending: sortAscending,
                    PageSize: pageSize,
                    Skip: skip * pageSize,
                    IsCloudImportType: isUsingCloudImportType,
                    IsMultipleWorkflow: usingMultipleWorkflow,
                    UsingCustomerWorkflow: usingCustomerWorkflow,
                    PaymentPlanEnabled: isPaymentPlanEnabled,
                    ExcludeInvoices: excludeInvoices
                })
            );
            
            const { Invoices } = get(res.data, 'GetInvoicesForCompany');
            
            if (Invoices && Invoices.length > 0) {
                finalInvoices = [...finalInvoices, ...Invoices]
            }
            else {
                fetching = false;
            }

            skip = skip + 1;
        }
        
        if (finalInvoices) {
            if (callback) { 
                const response = {
                    IsSuccess: true,
                    Invoices: finalInvoices,
                };
                callback(response);
            }
        }
    } catch (err) {
        if (callback) {
            const returnData = get(err.response, 'data')
                ? err.response.data
                : { Messages: [err.message] };
            returnData.IsSuccess = false;
            callback(returnData);
        }
        if (err instanceof Error) {
            console.log('Error', err);
        } else {
            console.error('An unknown error occured.');
        }
    }
}

function InvoicesFetchedForOrganisationReceived(params: { pageSize: number, currentPage: number }) {
    return function (data: InvoicesFetchedData) {
        store.dispatch(invoicesFetchedForOrganisationAction({ data, ...params }));
    };
}

function* watchInvoicesFetchedForOrganisationReceived() {
    yield takeLatest(InvoicesActionTypes.INVOICES_FETCHED_FOR_ORGANISATION_RECEIVED, function* (action: any) {
        const { data, pageSize, currentPage } = action.payload;
        const invoices: Invoice[] = data.OnInvoicesFetchedForOrganisation.Invoices;

        const responsePayload = {
            data: invoices,
            pageData: {
                pageSize: pageSize,
                currentPage: currentPage,
                hasNextPage:
                    !(invoices.length < pageSize) &&
                    !(pageSize < INVOICES_PAGE.pageSize),
            },
        };

        refetchCount = 0;
        yield put(getInvoicesSuccessAction(responsePayload));
    });
}

// This is our watcher function. We use `take*()` functions to watch Redux for a specific action
// type, and run our saga, for example the `handleFetch()` saga above.
function* watchGetInvoicesRequest() {
    yield takeLatest(
        InvoicesActionTypes.GET_INVOICES_REQUEST,
        handleGetInvoicesRequest
    );
}

function* watchGetInvoicesForOrganisationRequest() {
    yield takeLatest(
        InvoicesActionTypes.GET_INVOICES_FOR_ORGANISATION_REQUEST,
        handleGetInvoicesForOrganisationRequest
    );
}

function* watchGetInvoiceDataRequest() {
    yield takeLatest(
        InvoicesActionTypes.GET_INVOICE_DATA_REQUEST,
        handleGetInvoiceDataRequest
    );
}

function* watchGetOrganisationInvoiceDataRequest() {
    yield takeLatest(
        InvoicesActionTypes.GET_ORGANISATION_INVOICE_DATA_REQUEST,
        handleGetOrganisationInvoiceDataRequest
    );
}

function* watchSetSelectedInvoiceIdRequest() {
    yield takeLatest(
        InvoicesActionTypes.SET_INVOICE_SELECTED_ID_REQUEST,
        handleSetSelectedInvoiceIdRequest
    );
}

function* watchCheckInvoicesIfSingleCustomer() {
    yield takeLatest(
        InvoicesActionTypes.CHECK_INVOICES_IF_SINGLE_CUSTOMER_REQUEST,
        handleCheckInvoicesIfSingleCustomer
    );
}

function* watchRaiseQueryInvoiceBulkRequest() {
    yield takeLatest(
        InvoicesActionTypes.RAISE_QUERY_INVOICE_BULK_REQUEST,
        handleRaiseQueryInvoiceBulkRequest
    );
}

function* watchGetInvoiceConversationRequest() {
    yield takeLatest(
        InvoicesActionTypes.GET_INVOICE_CONVERSATION_REQUEST,
        handleGetInvoiceConversationRequest
    );
}

function* watchGetOrganisationInvoiceConversationRequest() {
    yield takeLatest(
        InvoicesActionTypes.GET_ORGANISATION_INVOICE_CONVERSATION_REQUEST,
        handleGetOrganisationInvoiceConversationRequest
    );
}

function* watchInvoiceAddCommentRequest() {
    yield takeLatest(
        InvoicesActionTypes.INVOICE_ADD_COMMENT_REQUEST,
        handleInvoiceAddCommentRequest
    );
}

function* watchOrganisationInvoiceAddCommentRequest() {
    yield takeLatest(
        InvoicesActionTypes.ORGANISATION_INVOICE_ADD_COMMENT_REQUEST,
        handleOrganisationInvoiceAddCommentRequest
    );
}

function* watchCheckInvoicesPauseResumeState() {
    yield takeLatest(
        InvoicesActionTypes.CHECK_INVOICES_PAUSE_RESUME_STATE,
        handleCheckInvoicesPauseResumeState
    );
}

function* watchInvoicePauseResumeRequest() {
    yield takeLatest(
        InvoicesActionTypes.INVOICE_PAUSE_RESUME_REQUEST,
        handleInvoicePauseResumeRequest
    );
}

function* watchGetInvoiceChangesRequest() {
    yield takeLatest(
        InvoicesActionTypes.GET_INVOICE_INVOICE_CHANGES_REQUEST,
        handleGetInvoiceChangesRequest
    );
}

function* watchGetOrganisationInvoiceChangesRequest() {
    yield takeLatest(
        InvoicesActionTypes.GET_ORGANISATION_INVOICE_CHANGES_REQUEST,
        handleGetOrganisationInvoiceChangesRequest
    );
}

function* watchGetInvoiceCustomFieldsForCompanyRequest() {
    yield takeLatest(
        InvoicesActionTypes.GET_INVOICE_CUSTOM_FIELDS_FOR_COMPANY_REQUEST,
        handleGetInvoiceCustomFieldsForCompanyRequest
        );
    }
// This is our watcher function. We use `take*()` functions to watch Redux for a specific action
// type, and run our saga, for example the `handleFetch()` saga above.
function* watchGetInvoicesForPaymentPlanRequestAction() {
    yield takeLatest(
        InvoicesActionTypes.GET_INVOICES_FOR_PAYMENT_PLAN_REQUEST,
        handleGetInvoicesForPaymentPlanRequestAction
    );
}

// We can also use `fork()` here to split our saga into multiple watchers.
function* invoicesSaga() {
    yield all([
        fork(watchGetInvoicesRequest),
        fork(watchGetInvoicesForOrganisationRequest),
        fork(watchGetInvoiceDataRequest),
        fork(watchSetSelectedInvoiceIdRequest),
        fork(watchCheckInvoicesIfSingleCustomer),
        fork(watchRaiseQueryInvoiceBulkRequest),
        fork(watchGetInvoiceConversationRequest),
        fork(watchGetOrganisationInvoiceConversationRequest),
        fork(watchInvoiceAddCommentRequest),
        fork(watchCheckInvoicesPauseResumeState),
        fork(watchInvoicePauseResumeRequest),
        fork(watchGetInvoiceChangesRequest),
        fork(watchGetInvoiceCustomFieldsForCompanyRequest),
        fork(watchGetInvoicesForPaymentPlanRequestAction),
        fork(watchGetOrganisationInvoiceChangesRequest),
        fork(watchGetOrganisationInvoiceDataRequest),
        fork(watchOrganisationInvoiceAddCommentRequest),
        fork(watchInvoicesFetchedForOrganisationReceived),
    ]);
}

export default invoicesSaga;
