/**
 * File for the `Payments - Allocation`s widget.
 */

import { Spin, Table } from 'antd';
import {
    clone,
    filter,
    find,
    forEach,
    get,
    includes,
    isEmpty,
    isUndefined,
    map,
    sum,
    isNumber,
} from 'lodash';
import {
    Bar,
    BarChart,
    CartesianGrid,
    Cell,
    Legend,
    Pie,
    PieChart,
    ResponsiveContainer,
    Tooltip,
    TooltipFormatter,
    XAxis,
    YAxis,
} from 'recharts';
import moment from 'moment-timezone';
import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { populatePayloadForOrganisationRegionalWidgets, widgetDisplayTypeValues, tableCurrencyFormatter } from '../../constants/dashboards';
import { ApplicationState } from '../../store';
import { getCustomerUILabel } from '../../store/customers/sagas';
import { getDashboardPaymentsAllocationRequestAction } from '../../store/dashboards/actions';
import {
    dashboardBypassAPIFetch,
    getCompanyName,
    replaceInstancesOfCustomerString,
    getDateFilterValues,
    roundNumberToDecimalDigits
} from '../../utils/commonFunctions';
import { DynamicObject } from '../../utils/commonInterfaces';
import { withAccountingSystemHandler } from '../common/AccountingSystemHandler';
import { withDateFormatHandler } from '../common/DateFormatHandler';
import {
    appliedFilterIndicator,
    customFieldIndicator,
    paymentFieldIndicator
} from '../common/FilterBar';
import { withNumberFormatHandler } from '../common/NumberFormatHandler';
import { commonOrgFormFields } from './organisation/OrganisationWidgetCommonFilters';
import {
    displayColumnOptions,
    showCustomFieldsIndicator,
} from './PaymentsAllocationWidgetFields';
import {
    dateSelectOptions
} from '../../constants/invoicesSortAndFilters';

interface IProps {
    widgetDetails: DynamicObject;
    preview?: boolean;
    readonly formatCurrency: (
        amount: number,
        cusCurrencyCode?: string,
        cusLocale?: string
    ) => JSX.Element;
    customFieldsFilterList: DynamicObject[];
    isUsingCloudImportType: boolean;
    readonly isOrgView?: boolean;
    readonly organisationCurrenciesAll?: DynamicObject[];
    readonly functionRefObj?: any;
}

const PaymentsAllocationWidget: React.FC<IProps> = ({
    widgetDetails,
    preview,
    formatCurrency,
    isOrgView,
    organisationCurrenciesAll,
    functionRefObj
}: IProps) => {
    const unmountedRef = useRef<any>(null);
    const customerLabel = useSelector(getCustomerUILabel);
    const organisationCompanies = useSelector(
        (app: ApplicationState) => app.organisations.companies.data
    );

    let widgetRef = useRef<any>(null);

    const usedDisplayColumns: any = isOrgView
        ? displayColumnOptions
        : filter(displayColumnOptions, ['OrgViewOnly', false]);

    const initialTableColumns = filter(usedDisplayColumns, [
        'defaultChecked',
        true,
    ]).map(({ label, value }: DynamicObject) => ({
        title: replaceInstancesOfCustomerString(
            label,
            customerLabel,
            isOrgView
        ),
        dataIndex: value,
    }));

    const dispatch = useDispatch();
    const [tableState, setTableState] = useState<{
        lastWidgetDetails: DynamicObject;
        loading: boolean;
        columns: DynamicObject[];
        dataSource: DynamicObject[];
        pieDataSource: DynamicObject[];
    }>({
        lastWidgetDetails: {},
        loading: false,
        columns: initialTableColumns,
        dataSource: [],
        pieDataSource: [],
    });

    const currencySelected = isOrgView
        ? get(widgetDetails, commonOrgFormFields.CURRENCY)
        : undefined;
    const currencySelectedParsed = currencySelected
        ? find(organisationCurrenciesAll, ['Value', currencySelected])
        : undefined;

    let currencyValueUsed: any = undefined;
    let currencyCodeUsed: any = undefined;
    let localeUsed: any = undefined;
    if (isOrgView) {
        currencyValueUsed =
            currencySelectedParsed || get(organisationCurrenciesAll, 0);
        currencyCodeUsed = get(currencyValueUsed, 'Value');
        localeUsed = get(currencyValueUsed, 'Locale');
    }

    /**
     * Common function for updating the `tableState` state.
     * @param tableStateObject
     */
    const updateTableStateObject = (tableStateObject: {}) => {
        setTableState({
            ...tableState,
            ...tableStateObject,
        });
    };

    /**
     * Common function for formatting currencies
     */
    const handleFormatCurrency = (toFormat: number) => {
        return formatCurrency(toFormat, currencyCodeUsed, localeUsed);
    };

    const [hoveredTooltip, setHoveredTooltip] = useState<string | undefined>(
        undefined
    );

    const showBarTooltip = (tooltipId: string | undefined) => {
        if (hoveredTooltip !== tooltipId) setHoveredTooltip(tooltipId);
    };

    const dispatchAction = (payloadCallback?: (payload: any) => void) => {
        const paymentsColumns: DynamicObject[] = [];
        const customFieldFilters: DynamicObject = {};
        let hasDisplayValue = false;
        let amountFilters: any = {
            AmountType: '',
            AmountValue: 0,
            AmountOperator: '',
        };
        let Customer: string = '';
        let paidDateFilters: any = {
            value: undefined,
            From: undefined,
            To: undefined,
            Last: undefined,
            Next: undefined
        }
        let PaidDateMin: any = undefined;
        let PaidDateMax: any = undefined;

        paidDateFilters.value = get(widgetDetails, "PaymentField---PaidDate--DateType");

        forEach(widgetDetails, (wdValue: any, wdKey: string) => {
            if (includes(wdKey, customFieldIndicator) &&
                !includes(wdKey, showCustomFieldsIndicator)
            ) {
                customFieldFilters[wdKey + appliedFilterIndicator] = wdValue;
            }

            if (includes(wdKey, paymentFieldIndicator) && !isUndefined(wdValue)) {
                if (includes(wdKey, "Amount--AmountType")) {
                    amountFilters.AmountType = wdValue;
                }
                else if (includes(wdKey, "Amount--AmountOperator")) {
                    amountFilters.AmountOperator = wdValue;
                }
                else if (includes(wdKey, "Amount--AmountValue")) {
                    amountFilters.AmountValue = wdValue;
                }
                else if (includes(wdKey, "Customer")) {
                    Customer = wdValue;
                }
                else if (includes(wdKey, "PaidDate")) {
                    if (paidDateFilters.value === dateSelectOptions.CUSTOM_DATE_RANGE) {
                        if (includes(wdKey, "From")) {
                            paidDateFilters.From = moment(wdValue);
                        }
                        else if (includes(wdKey, "To")) {
                            paidDateFilters.To = moment(wdValue);
                        }
                    }
                    else if (paidDateFilters.value === dateSelectOptions.CUSTOM_DAYS_RANGE) {
                        if (includes(wdKey, "Last")) {
                            paidDateFilters.Last = wdValue;
                        }
                    }

                    const { minDate, maxDate } = getDateFilterValues(paidDateFilters);
                    PaidDateMin = minDate;
                    PaidDateMax = maxDate;
                }
            }
        });

        let payload: DynamicObject = {
            filters: {
                Customer,
                CustomerCode: '',
                PaidDateMin,
                PaidDateMax,
                ...amountFilters,
                ...customFieldFilters,
            },
        };

        if (isOrgView) {
            payload = populatePayloadForOrganisationRegionalWidgets({
                payload,
                widgetDetails,
                organisationCompanies,
                organisationCurrenciesAll,
                currencyCodeUsed,
            });
        }

        dispatch(
            getDashboardPaymentsAllocationRequestAction(
                payload,
                isOrgView,
                (paymentTotals: any) => {
                    if (unmountedRef.current) return;

                    const usedDataSource = [
                        {
                            name: 'Payments - Allocation',
                            'Total paid amount': get(paymentTotals, 'TotalPaidAmount', 0),
                            'Total allocated amountCount': get(paymentTotals, 'TotalAllocatedAmount', 0),
                            'Total unallocated amountCount': get(paymentTotals, 'TotalUnallocatedAmount', 0),
                            'Total allocated amount': (get(paymentTotals, 'TotalAllocatedAmount', 0) / get(paymentTotals, 'TotalPaidAmount', 0) * 100),
                            'Total unallocated amount': (get(paymentTotals, 'TotalUnallocatedAmount', 0) / get(paymentTotals, 'TotalPaidAmount', 0) * 100)
                        }
                    ];

                    const pieDataSource = map(displayColumnOptions, (option: any) => {
                        if (option.value !== 'TotalPaidAmount') {
                            return {
                                Category: get(option, 'label', ''),
                                Amount: get(paymentTotals, option.value, '')
                            }
                        }
                        return {
                            Category: '',
                            Amount: 0
                        };
                    })

                    updateTableStateObject({
                        dataSource: usedDataSource,
                        pieDataSource: pieDataSource,
                        loading: false,
                        lastWidgetDetails: clone(widgetDetails),
                    });
                },
                payloadCallback
            )
        );
    }

    /**
     * Function called for initializing widget data based on widgetDetails prop received.
     */
    const initializeWidgetData = () => {
        if (isOrgView && isEmpty(organisationCurrenciesAll)) return;

        const bypassAPIFetching = dashboardBypassAPIFetch(
            tableState.lastWidgetDetails,
            widgetDetails
        );
        if (bypassAPIFetching) return;

        updateTableStateObject({
            loading: true,
        });

        dispatchAction(undefined);
    };

    useEffect(initializeWidgetData, [widgetDetails, organisationCurrenciesAll]);

    if (functionRefObj) {
        functionRefObj.getPayload = (callback: (payload: any) => void) => {
            dispatchAction(callback);
        };
    }

    /**
     * Function responsible for setting the `unmounted` variable indicator for when this component unmounts.
     */
    const setInitialLoad = () => {
        unmountedRef.current = false;

        //will unmount
        return () => {
            unmountedRef.current = true;
        };
    };

    useEffect(setInitialLoad, []);

    /**
     * Function for rendering the labels for pie chart.
     * @param props
     */
    const renderCustomizedLabel = (props: any) => {
        const RADIAN = Math.PI / 180;
        const {
            cx,
            cy,
            midAngle,
            outerRadius,
            Category,
            percent,
            innerRadius,
        } = props;
        const sin = Math.sin(-RADIAN * midAngle);
        const cos = Math.cos(-RADIAN * midAngle);
        const textAnchor = cos >= 0 ? 'start' : 'end';
        const radius = innerRadius + (outerRadius - innerRadius) * 1.2;
        const x = cx + radius * cos;
        const y = cy + radius * sin;
        return (
            <g>
                <text
                    x={x}
                    y={y}
                    textAnchor={textAnchor}
                    fill="#333"
                    dominantBaseline="central"
                >
                    {Category}
                </text>
                <text x={x} y={y} dy={18} textAnchor={textAnchor} fill="#999">
                    {`${(percent * 100).toFixed(2)}%`}
                </text>
            </g>
        );
    };

    /**
     * Function for formatting the currency tooltip.
     * @param value
     */
    const tooltipCurrencyFormatter: TooltipFormatter = (value) => {
        if (isNumber(value)) {
            return formatCurrency(value);
        } else {
            return value;
        }
    };

    /**
     * Function that converts a number to percentage text.
     * @param value
     */
    const tickFormatter = (value: number) => {
        return `${value}%`;
    };

    /**
     * Function for formatting the tooltip.
     * @param value
     */
    const tooltipFormatter: TooltipFormatter = (value) => {
        if (isNumber(value)) {
            return formatPercentage(value);
        } else {
            return value;
        }
    };

    /**
     * Formatting percentage number to text with percent sign.
     * @param percentageNumber
     */
    const formatPercentage = (percentageNumber: number) =>
        `${roundNumberToDecimalDigits(percentageNumber)}%`;

    /**
     * Function for generating custom tooltip
     * @returns
     */
    const CustomTooltip = ({ active, payload }: any) => {
        if (hoveredTooltip && active && payload && payload.length) {
            const filteredValue = get(
                filter(payload, ['dataKey', hoveredTooltip]),
                0
            );
            const payloadValue = get(filteredValue, `payload`);
            const percentageValue = get(payloadValue, hoveredTooltip, 0);
            const color = get(filteredValue, 'color');

            return (
                <div className="custom-widget-tooltip">
                    <b style={{ color }}>{hoveredTooltip}</b>
                    <div>
                        Value:{' '}
                        <span style={{ color }}>
                            {formatCurrency(
                                get(
                                    payloadValue,
                                    `${hoveredTooltip}${countIndicator}`,
                                    0
                                )
                            )}
                        </span>
                    </div>
                    <div>
                        Percentage:{' '}
                        <span style={{ color }}>
                            {formatPercentage(percentageValue)}
                        </span>
                    </div>
                </div>
            );
        }

        return null;
    };


    /**
     * Function for populating the bar items for chart.
     */
    const populateBarItemsForChart = () => {
        const forReturn = map(
            displayColumnOptions,
            (option: DynamicObject) => {
                const value = get(option, 'value');
                const label = get(option, 'label');

                if (value === 'TotalPaidAmount') {
                    return;
                }
                return (
                    <Bar
                        key={value}
                        dataKey={label}
                        id={label}
                        fill={get(option, 'color')}
                        stackId="a"
                        maxBarSize={80}
                        onMouseOver={() => showBarTooltip(label)}
                        onMouseOut={() => showBarTooltip(undefined)}
                    />
                );
            }
        );

        return forReturn;
    };

    const COLORS = [
        '#0088FE',
        '#00C49F',
    ]

    const countIndicator = 'Count';

    const populateWidgetContent = () => {
        const {
            loading: stateLoading,
            dataSource: stateDataSource,
            pieDataSource: pieStateDataSource,
        } = tableState;

        const displayView = get(widgetDetails, 'displayType');

        if (displayView === widgetDisplayTypeValues.TABLE || displayView === undefined) {
            const columns = [
                {
                    title: 'Total paid amount',
                    dataIndex: 'Total paid amount',
                },
                {
                    title: 'Total allocated amount',
                    dataIndex: 'Total allocated amountCount',
                },
                {
                    title: 'Total unallocated amount',
                    dataIndex: 'Total unallocated amountCount',
                }
            ];

            return (
                <Table
                    className="table-striped-rows table-ws-nw"
                    columns={columns}
                    dataSource={tableCurrencyFormatter(stateDataSource, formatCurrency)}
                    loading={stateLoading}
                    pagination={false}
                    size="middle"
                    rowKey="name"
                />
            );
        } else if (displayView === widgetDisplayTypeValues.PIE_CHART) {
            const pieChartData = clone(pieStateDataSource);

            const filteredData = pieChartData.filter((dataPoint: any) => dataPoint["Amount"] > 0);

            return (
                <Spin wrapperClassName="spinner-wh100" spinning={stateLoading}>
                    <ResponsiveContainer width="99%" height="99%">
                        <PieChart>
                            <Pie
                                paddingAngle={1}
                                minAngle={1}
                                data={filteredData}
                                label={renderCustomizedLabel}
                                labelLine={true}
                                outerRadius="70%"
                                fill="#8884d8"
                                dataKey="Amount"
                                nameKey="Category"
                                isAnimationActive={false}
                            >
                                {map(filteredData, (_entry, index) => (
                                    <Cell
                                        key={index}
                                        fill={COLORS[index % COLORS.length]}
                                    />
                                ))}
                            </Pie>
                            <Tooltip formatter={tooltipCurrencyFormatter} />
                            {preview && <Legend />}
                        </PieChart>
                    </ResponsiveContainer>
                </Spin>
            );
        } else {
            return (
                <Spin wrapperClassName="spinner-wh100" spinning={stateLoading}>
                    <ResponsiveContainer width="99%" height="99%">
                        <BarChart
                            data={stateDataSource}
                            margin={{
                                top: 20,
                                right: 20,
                                left: 20,
                                bottom: 15,
                            }}
                            layout="vertical"
                        >
                            <CartesianGrid strokeDasharray="3 3" />
                            <XAxis
                                type="number"
                                ticks={[0, 20, 40, 60, 80, 100]}
                                tickCount={6}
                                tickFormatter={tickFormatter}
                            />
                            <YAxis dataKey="name" type="category" hide />
                            {!hoveredTooltip && (
                                <Tooltip formatter={tooltipFormatter} />
                            )}
                            {hoveredTooltip && (
                                <Tooltip content={<CustomTooltip />} />
                            )}
                            {preview && <Legend />}
                            {populateBarItemsForChart()}
                        </BarChart>
                    </ResponsiveContainer>
                </Spin>
            );
        }
    };

    return (
        <div className="current-atb-widget-container h-100">
            {populateWidgetContent()}
        </div>
    );
};

export default withAccountingSystemHandler(
    withDateFormatHandler(withNumberFormatHandler(PaymentsAllocationWidget))
);
