import AutoComplete from 'antd/lib/auto-complete';
import Icon from 'antd/lib/icon';
import Input from 'antd/lib/input/Input';
import { SelectValue } from 'antd/lib/select';
import Spin from 'antd/lib/spin';
import { API } from 'aws-amplify';
import { forEach, get, includes, isEmpty, isString } from 'lodash';
import React, { useEffect, useRef, useState } from 'react';
import queries from '../../../graphql/queries.graphql';
import { populatePopoverContainer } from '../../../utils/commonFunctions';
import { DynamicObject } from '../../../utils/commonInterfaces';
import { customFieldIndicator } from '../../common/FilterBar';

const { Option } = AutoComplete;
const FILTER_SEARCH_DEBOUNCE_TIME = 2000; //ms
let unmounted: boolean = false;
interface IProps {
    updateField: (value: string) => void;
    setSelectedCustomerId: any;
    setIsSaveBtnDisabled: any;
    stateValue: string;
    queryName: string;
    filterField: string | undefined;
    queryFilterName?: string;
    sortField: string;
    responseName: string;
    labelField: any;
    hasNoOkButton?: boolean;
    queryFilterNameSub?: string;
    filterSubChecking?: (inputVal: string | undefined | null) => boolean;
    loading?: boolean;
    hasNoContainerRef?: boolean;
    readOnly?: boolean;
}
let timeoutHandle: any = null;

const TaskActionPaymentInputAutoComplete: React.FC<IProps> = ({
    updateField,
    setSelectedCustomerId,
    setIsSaveBtnDisabled,
    stateValue,
    queryName,
    filterField,
    queryFilterName,
    sortField,
    responseName,
    labelField,
    hasNoOkButton,
    queryFilterNameSub,
    filterSubChecking,
    loading,
    hasNoContainerRef,
    readOnly,
}: IProps) => {
    const containerRef = useRef(null);
    const [inputState, setInputState] = useState<{
        key: number;
        fetching: boolean;
        data: [{
            Id:string,
            DisplayName: string
        }];
        value: string | undefined;
    }>({
        key: 0,
        fetching: false,
        data: [{
            Id:'',
            DisplayName: ''
        }],
        value: stateValue || undefined,
    });

    /**
     * Common function for updating the input state.
     * @param inputStateObject - must conform to inputState object
     */
    const updateInputState = (inputStateObject: {}) => {
        setInputState({
            ...inputState,
            ...inputStateObject,
        });
    };

    const checkStateValueUpdate = () => {
        if (!stateValue) {
            if (timeoutHandle) clearTimeout(timeoutHandle);
            unmounted = true;
            updateInputState({
                fetching: false,
                data: [],
                value: stateValue || undefined,
            });
        }
    };

    useEffect(checkStateValueUpdate, [stateValue]);

    /**
     * Function responsible for setting the `unmounted` variable indicator for when this component unmounts.
     */
    const setInitialLoad = () => {
        unmounted = false;

        //will unmount
        return () => {
            unmounted = true;
        };
    };

    useEffect(setInitialLoad, []);

    const actionIfLoading = () => {
        if (loading) {
            if (timeoutHandle) clearTimeout(timeoutHandle);
        }
    };

    useEffect(actionIfLoading, [loading]);

    const fetchOptions = (value: string) => {
        unmounted = false;
        updateField(value);
        if (value.length >= 3) {
            if (timeoutHandle) clearTimeout(timeoutHandle);
            let skipCheck = false;
            let filterNameInQueryUsed = queryFilterName;
            if (loading || (filterSubChecking && !queryFilterNameSub)) {
                skipCheck = true;
            } else if (filterSubChecking && queryFilterNameSub) {
                if (filterSubChecking(value))
                    filterNameInQueryUsed = queryFilterNameSub;
            }

            if (!skipCheck) {
                timeoutHandle = setTimeout(() => {
                    if (unmounted) return;
                    updateInputState({ value, data: [], fetching: true });

                    getDataOptions(value, filterNameInQueryUsed);
                }, FILTER_SEARCH_DEBOUNCE_TIME);
            }
        } else {
            if (timeoutHandle) clearTimeout(timeoutHandle);
            updateInputState({ value, data: [], fetching: false });
        }
    };

    const getDataOptions = (value: string, usedFilterNameInQuery?: string) => {
        const usedQueryFilter = usedFilterNameInQuery || filterField;
        if (!usedQueryFilter) return;
        let queryVariables: DynamicObject = {
            PageSize: 10,
            Skip: 0,
            Ascending: true,
        };
        let usedCustomFieldName = '';
        const isCustomField =
            includes(filterField, customFieldIndicator) ||
            labelField === customFieldIndicator;
        if (isCustomField) {
            const cusFieldValueUsed = filterField || usedQueryFilter;
            const usedKeyValue = cusFieldValueUsed.replace(
                customFieldIndicator,
                ''
            );
            const customFieldKeyValArray = usedKeyValue.split('--');
            usedCustomFieldName = get(customFieldKeyValArray, 1);
            queryVariables = {
                FieldName: usedCustomFieldName,
                Value: value,
            };
        } else {
            queryVariables[usedQueryFilter] = value;
            queryVariables.SortField = sortField;
        }

        const apiQuery = API.graphql({
            query: queries[queryName],
            variables: queryVariables,
        }) as Promise<any>;

        apiQuery
            .then((res: any) => {
                if (unmounted) return;

                const data: any = [];

                forEach(get(res, `data.${responseName}`), (opt: any) => {
                    opt = {
                        Id: opt['Id'],
                        DisplayName: opt[labelField],
                    };

                    data.push(opt);
                });
                
                updateInputState({ value, data, fetching: false });
            })
            .catch(() => {
                if (unmounted) return;

                updateInputState({ value, data: [], fetching: false });
            });
    };

    /**
    * Function responsible for getting the customer name based on the customerId.
    */
    const findArrayElementByTitle = (customerId: any) => {
        return data.find((element) => {
          return element.Id === customerId;
        })
      }
    
    /**
     * Function called when selecting a customer on the autosuggest dropdown
     */
    const handleSelect = (value?: SelectValue) => {
        let customerName = '';
        let input = findArrayElementByTitle(value);

        customerName = input ? input.DisplayName : '';
        setIsSaveBtnDisabled(false);
        setSelectedCustomerId(value);
        updateField(customerName as string);
    };

    const { fetching, data } = inputState;

    /**
     * Function called when typing on the customer input
     */
    const handleChange = (value: SelectValue) => {
        updateInputState({
            value: value as string,
            data: [],
            fetching: false,
        });
    };
    const options = data
        .map((data,index) => (                            
            <Option key={index} value={data.Id}>
                {data.DisplayName}
            </Option>
        ));

    return (
        <div
            className={hasNoOkButton ? '' : 'pop-action-content'}
            ref={hasNoContainerRef ? undefined : containerRef}
        >
            <div>
                    <AutoComplete
                        value={stateValue}
                        dataSource={options}
                        notFoundContent={null}
                        filterOption={false}
                        onSearch={fetchOptions}
                        onChange={handleChange}
                        onSelect={handleSelect}
                        dropdownClassName={
                            hasNoOkButton ? '' : 'autocomplete-input-dropdown'
                        }
                        getPopupContainer={
                            hasNoContainerRef
                                ? undefined
                                : populatePopoverContainer(containerRef)
                        }
                        disabled={readOnly}
                    >
                        <Input
                            className={readOnly ? 'autocomplete-input-disable' : ''}
                            prefix={
                                fetching ? (
                                    <Spin
                                        indicator={
                                            <Icon
                                                type="loading"
                                                style={{ fontSize: 12 }}
                                                spin
                                            />
                                        }
                                    />
                                ) : (
                                    <Icon type="search" />
                                )
                            }
                            placeholder="Search"
                            allowClear
                        />     
                    </AutoComplete>
            </div>
        </div>
    );
};

export default TaskActionPaymentInputAutoComplete;
