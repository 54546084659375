/**
 * File responsible for the customer table row item component.
 */

import { Badge, Button, Checkbox, Col, Row, Tooltip } from 'antd';
import ButtonGroup from 'antd/lib/button/button-group';
import { get, includes, isEmpty, isEqual, isUndefined, map } from 'lodash';
import React, { memo, useRef } from 'react';
import { useSelector } from 'react-redux';
import { customersATBCalculationOptions } from '../../constants/customersSortAndFilters';
import { dateFormatDMMMYYYYSpace } from '../../constants/dateFormats';
import { taskHistoryBatchTypes } from '../../constants/tasksSortAndFilters';
import '../../pages/sales/sales.less';
import { ApplicationState } from '../../store';
import { CustomField } from '../../store/common/types';
import { getNumberOfDaysBeforeRecentCommentTurnedOff } from '../../store/companies/sagas';
import { getCustomerSelectedId } from '../../store/customers/sagas';
import { Customer } from '../../store/customers/types';
import ContactMethodIcons from '../common/ContactMethodIcons';
import {
    checkIfDateHasPassed,
    getBadgeDisplay,
    getCompanyName,
    getIfIsLg,
    trialBalanceColorClassNames,
    getContactTypeValue,
    useCompanyFlagValue
} from '../../utils/commonFunctions';
import { DynamicObject } from '../../utils/commonInterfaces';
import ContactInformationIcons from '../common/ContactInformationIcons';
import { withDateFormatHandler } from '../common/DateFormatHandler';
import FontAwesome from '../common/FontAwesome';
import { withNumberFormatHandler } from '../common/NumberFormatHandler';
import {
    generateCommentItemWithType,
    generateCustomerIcon
} from '../common/SharedExportComponents';
import { DeliveryDetails } from '../../store/customers/types';
import { CompanyUserRole } from '../../store/companies/types';
import { AtbViewType } from '../../constants/settings';

interface IProps {
    readonly item: Customer;
    readonly style: {};
    readonly onRowClick: (item: DynamicObject, drawerType?: string) => void;
    readonly onCheckboxClick: (item: DynamicObject) => void;
    readonly selectedRowKeys: [];
    readonly formatCurrency?: (amount: number) => JSX.Element;
    readonly usingCustomerWorkflow?: boolean;
    readonly formatDateUTCToLocal: (
        date: any,
        fromFormat?: string | null,
        toFormat?: string | null
    ) => string;
}
const CustomerItemComponent: React.FC<IProps> = ({
    item,
    style,
    onRowClick,
    onCheckboxClick,
    selectedRowKeys,
    formatCurrency,
    usingCustomerWorkflow,
    formatDateUTCToLocal,
}: IProps) => {
    const selectedUserCompany: CompanyUserRole = useSelector(
        (state: ApplicationState) => state.companies.selectedUserCompany
    );
    const firstRowContainerRef = useRef<any>(null);
    const customerIconContainerRef = useRef<any>(null);
    const {
        Id,
        CustomerCode,
        AgedTrialBalance,
        Contacts,
        IsVip,
        TicketCount,
        Attachments,
        ConversationLine,
        CustomFields,
        LastCommunication,
        CurrentTask,
        Company
    } = item;

    const deliveryDetails = get(LastCommunication, 'DeliveryDetails');

    const TotalOwing = get(AgedTrialBalance, 'TotalOwing');
    const TotalOverdue = get(AgedTrialBalance, 'TotalOverdue');
    const CompanyName = get(Company, 'Name');
    const isCalendarView = useCompanyFlagValue(AtbViewType.CalendarView, Company);
    const AgedTrialBalanceFromCreate = get(
        AgedTrialBalance,
        'AgedTrialBalanceFromCreate'
    );
    const AgedTrialBalanceFromDue = get(
        AgedTrialBalance,
        'AgedTrialBalanceFromDue'
    );

    const customersState = useSelector(
        (state: ApplicationState) => state.customers
    );

    const selectedId = useSelector(getCustomerSelectedId);
    const numberOfDaysBeforeRecentCommentTurnedOff: number = useSelector(getNumberOfDaysBeforeRecentCommentTurnedOff);

    const ATBData =
        get(customersState, 'atbType') ===
            customersATBCalculationOptions[0].value
            ? AgedTrialBalanceFromCreate
            : AgedTrialBalanceFromDue;

    const isDueDateView = ATBData === AgedTrialBalanceFromDue;

    const ATBClassNames = trialBalanceColorClassNames(ATBData, isDueDateView);

    /**
     * Function called for adding the key prop - identifier for the row.
     */
    const getItemInfo = () => {
        const itemInfo: DynamicObject = item;
        itemInfo.key = Id;

        return itemInfo;
    };

    /**
     * Function triggered when checkbox is toggled.
     * @param e
     */
    const handleCheckboxClick = (
        e: React.MouseEvent<HTMLElement, MouseEvent>
    ) => {
        e.stopPropagation();
        const itemInfo = getItemInfo();
        if (onCheckboxClick) onCheckboxClick(itemInfo);
    };

    /**
     * Function triggered when row is clicked.
     */
    const handleRowClick = () => {
        const itemInfo = getItemInfo();
        if (onRowClick) onRowClick(itemInfo);
    };

    /**
     * Function that formats the currency if the format currency function from
     * NumberFormatHandler HOC exists.
     * @param amount
     */
    const handleFormatCurrency = (amount: number) => {
        return formatCurrency ? formatCurrency(amount) : null;
    };

    let itemColor = 'green';
    if (TotalOverdue > 0) {
        itemColor = 'red';
    }

    /**
     * Function that gets that is triggered when clicking on the comment icon from table row.
     * @param e
     */
    const viewCommentsSectionDrawer = (
        e: React.MouseEvent<HTMLElement, MouseEvent>
    ) => {
        e.stopPropagation();
        const itemInfo = getItemInfo();
        if (onRowClick) onRowClick(itemInfo, 'conversation');
    };

    const isLg = getIfIsLg();

    let firstRowContainerHeight: any = 'auto';

    if (isLg) {

        firstRowContainerHeight = get(
            firstRowContainerRef,
            'current.clientHeight'
        )
            ? get(firstRowContainerRef, 'current.clientHeight') - 2
            : 40;
    }

    const badgeTitle =
        TicketCount > 0
            ? `${TicketCount} open ticket${TicketCount === 1 ? '' : 's'}`
            : '';

    const badgeCount = getBadgeDisplay(TicketCount);

    /**
     * Function that populates the custom fields section
     */
    const populateCustomFields = () => {
        const customFieldsDivElements = map(
            CustomFields,
            ({ Name, Value }: CustomField) => (
                <Col key={`${Name}-${Value}`}>
                    <span className="mr-8">{Name}:</span>
                    <span>{Value}</span>
                </Col>
            )
        );
        return (
            <Row className="center-flex-i fw-w" gutter={30}>
                {customFieldsDivElements}
            </Row>
        );
    };

    /**
     * Function for populating the current task section.
     */
    const populateCurrentTaskSection = () => {
        let currentTaskText: string | null = null;
        if (CurrentTask) {
            const taskType = get(CurrentTask, 'Type');
            const stateName = get(CurrentTask, 'Workflow.StateName');
            if (
                taskType === taskHistoryBatchTypes.Communication ||
                taskType === taskHistoryBatchTypes.Collection
            ) {
                const actionDate = get(CurrentTask, 'ActionDate');
                const hasDatePassed = checkIfDateHasPassed(actionDate);
                const actionPhrase = hasDatePassed
                    ? 'ready since'
                    : 'pending until';
                const workflowName = populateWorkflowName();
                const workflowText = workflowName ? ` on ${workflowName}` : '';
                currentTaskText = `Current Task: ${stateName}${workflowText} ${actionPhrase} ${formatDateUTCToLocal(
                    actionDate,
                    null,
                    dateFormatDMMMYYYYSpace
                )}`;
            }
        }

        if (currentTaskText)
            return <Col className="fx-1">{currentTaskText}</Col>;

        return null;
    };
    const populateDeliveryFeedbackInfo = (deliveryDetails: DeliveryDetails[]) => {
        const contactMethods = getContactTypeValue(deliveryDetails);
        return (
            <ContactMethodIcons
                contactMethods={contactMethods}
                deliveryDetails={deliveryDetails}
                showOnlyComment={true}
            />
        );
    };
    /**
     * Function to populate the Current task and Last Reminder section
     */
    const populateTaskAndReminderSection = () => {
        const lastCommunicationState = get(LastCommunication, 'StateName');
        const lastCommunicationSentDateTime = get(
            LastCommunication,
            'SentDateTime'
        );
        const lastCommunicationWorkflowName = get(
            LastCommunication,
            'WorkflowName'
        );

        return (
            <Row>
                <Col>
                    <Row type="flex" gutter={10}>
                        {populateCurrentTaskSection()}
                    </Row>
                </Col>
                {lastCommunicationState && (
                    <Row>
                        <Col span={24}>
                            Last Reminder: {lastCommunicationState}
                            {lastCommunicationWorkflowName
                                ? ` on ${lastCommunicationWorkflowName}`
                                : ''}
                            {lastCommunicationSentDateTime
                                ? ` sent on ${formatDateUTCToLocal(
                                    lastCommunicationSentDateTime,
                                    null,
                                    dateFormatDMMMYYYYSpace
                                )}`
                                : null}
                            <span
                                style={{
                                    marginLeft: 10
                                }}
                            >{iconGroupComponent}</span>
                        </Col>
                    </Row>

                )}
            </Row>
        );
    };

    /**
     * Function for populating the Workflow name in certain conditions.
     */
    const populateWorkflowName = () => {
        let workflowNameDisplay = '';
        if (CurrentTask) {
            const taskType = get(CurrentTask, 'Type');
            if (!(taskType === taskHistoryBatchTypes.Ticket || IsVip)) {
                workflowNameDisplay = get(CurrentTask, 'Workflow.WorkflowName');
            }
        }
        return workflowNameDisplay;
    };

    const atbColProps = {
        style: {
            minWidth: 150,
        },
    };

    const iconGroupComponent = (
        <ButtonGroup className="button-link-icons">
            {!isEmpty(deliveryDetails) && populateDeliveryFeedbackInfo(deliveryDetails)}
        </ButtonGroup>
    );

    return (
        <Row
            className={`list-item-clickable ${selectedId === Id ? 'list-item-clicked' : ''
                }`}
            key={Id}
            style={style}
            onClick={handleRowClick}
        >
            <Col
                span={24}
                style={{ height: '100%', paddingTop: 10, paddingBottom: 10 }}
            >
                <Row
                    className="pr-8 h-100"
                    gutter={{ xxl: 10, xl: 10, lg: 20 }}
                    type="flex"
                    align="middle"
                >
                    <Col
                        xxl={2}
                        xl={3}
                        lg={2}
                        md={2}
                        sm={2}
                        xs={2}
                        className="h-100"
                    >
                        <Row className="fx-center-xl h-100">
                            <Col xl={8} lg={24} md={24} sm={24} xs={24}>
                                <div
                                    className="ta-center center-flex-all"
                                    style={{
                                        height: firstRowContainerHeight,
                                    }}
                                >
                                    <Checkbox
                                        checked={includes(selectedRowKeys, Id)}
                                        onClick={handleCheckboxClick}
                                    />
                                </div>
                            </Col>
                            <Col
                                xl={16}
                                lg={24}
                                md={24}
                                sm={24}
                                xs={24}
                                className="ta-center"
                            >
                                <div
                                    className="customer-icon-container"
                                    ref={customerIconContainerRef}
                                >
                                    <Tooltip
                                        title={
                                            TicketCount > 0
                                                ? `${TicketCount} open ticket${TicketCount === 1
                                                    ? ''
                                                    : 's'
                                                }`
                                                : ''
                                        }
                                    >
                                        <div
                                            onClick={(e) => e.stopPropagation()}
                                        >
                                            {IsVip ? (
                                                <div
                                                    style={{
                                                        marginTop: isLg
                                                            ? 10
                                                            : 0,
                                                    }}
                                                >
                                                    <Badge
                                                        count={badgeCount}
                                                        offset={[0, -5]}
                                                        title={badgeTitle}
                                                    >
                                                        {generateCustomerIcon(
                                                            true,
                                                            itemColor
                                                        )}
                                                    </Badge>
                                                </div>
                                            ) : (
                                                <Badge
                                                    count={badgeCount}
                                                    offset={[-8, 7]}
                                                    title={badgeTitle}
                                                >
                                                    {generateCustomerIcon(
                                                        false,
                                                        itemColor
                                                    )}
                                                </Badge>
                                            )}
                                        </div>
                                    </Tooltip>
                                </div>
                            </Col>
                        </Row>
                    </Col>
                    <Col xxl={22} xl={21} lg={22} md={22} sm={22} xs={22}>
                        <Row className="first-row-container" type="flex">
                            <Col span={24}>
                                <div ref={firstRowContainerRef}>
                                    <Row>
                                        <Col
                                            xxl={16}
                                            xl={15}
                                            lg={13}
                                            md={13}
                                            sm={13}
                                            xs={13}
                                        >
                                            <Row
                                                gutter={60}
                                                type="flex"
                                                className="fw-w"
                                            >
                                                {!isUndefined(CustomerCode) && (
                                                    <Col className="flx-3 mxw-100">
                                                        <b className="ws-nw va-top">
                                                            {CustomerCode}
                                                        </b>
                                                    </Col>
                                                )}

                                                <Col
                                                    span={isLg ? 24 : undefined}
                                                    className={`${isLg ? '' : 'flx-7'
                                                        } mxw-100`}
                                                >
                                                    <b className="ws-nw va-top">
                                                        {getCompanyName(item)}
                                                    </b>

                                                </Col>
                                                {CompanyName && (<Col className="flx-3 mxw-100">
                                                    <b className="wb-bw">
                                                        {CompanyName}
                                                    </b>
                                                </Col>)}
                                            </Row>
                                            {usingCustomerWorkflow &&
                                                <Row>
                                                    <Col
                                                        xl={24}
                                                        lg={0}
                                                        md={0}
                                                        sm={0}
                                                        xs={0}
                                                    >
                                                        {!isLg && populateTaskAndReminderSection()}
                                                    </Col>

                                                </Row>
                                            }
                                            <Row>
                                                <Col
                                                    xl={24}
                                                    lg={0}
                                                    md={0}
                                                    sm={0}
                                                    xs={0}
                                                >
                                                    {populateCustomFields()}
                                                </Col>

                                            </Row>
                                        </Col>
                                        {/* Fixed Total overdue and contact icons container */}
                                        <Col
                                            xxl={8}
                                            xl={9}
                                            lg={11}
                                            md={11}
                                            sm={11}
                                            xs={11}
                                        >
                                            <Row type="flex">
                                                <Col
                                                    className={`icons-container`}
                                                    style={{
                                                        width: isLg
                                                            ? 115
                                                            : 'auto',
                                                    }}
                                                >
                                                    <Row
                                                        type="flex"
                                                        align="middle"
                                                        justify="space-between"
                                                    >

                                                        <Col
                                                            className={`contact-icons-container ${isLg
                                                                ? 'w-100 pr-2'
                                                                : ''
                                                                }`}
                                                        >
                                                            <ContactInformationIcons
                                                                contactInformation={get(
                                                                    Contacts,
                                                                    0
                                                                )}
                                                                additionalClass="ta-right"
                                                                iconTransform="grow-2"
                                                            />
                                                        </Col>
                                                        <Col
                                                            className={`ta-right ${isLg
                                                                ? 'w-100'
                                                                : ''
                                                                }`}
                                                        >
                                                            <ButtonGroup className="button-link-icons">
                                                                {!isEmpty(
                                                                    Attachments
                                                                ) && (
                                                                        <Tooltip
                                                                            title={`${Attachments.length
                                                                                } attachment${Attachments.length >
                                                                                    1
                                                                                    ? 's'
                                                                                    : ''
                                                                                } available`}
                                                                            placement="topRight"
                                                                        >
                                                                            <Button
                                                                                type="link"
                                                                                className="row-icons-btn"
                                                                            >
                                                                                <FontAwesome
                                                                                    icon={[
                                                                                        'fas',
                                                                                        'file-alt',
                                                                                    ]}
                                                                                    className="blue"
                                                                                />
                                                                            </Button>
                                                                        </Tooltip>
                                                                    )}
                                                                {ConversationLine &&
                                                                    generateCommentItemWithType(
                                                                        ConversationLine,
                                                                        'customer',
                                                                        numberOfDaysBeforeRecentCommentTurnedOff,
                                                                        viewCommentsSectionDrawer,
                                                                        undefined,
                                                                        ''
                                                                    )}
                                                            </ButtonGroup>
                                                        </Col>
                                                    </Row>
                                                </Col>
                                                <Col className="ta-right totals-container fx-1">
                                                    <Row>
                                                        <Col>
                                                            <b
                                                                className={
                                                                    itemColor
                                                                }
                                                            >
                                                                Total overdue{' '}
                                                                {handleFormatCurrency(
                                                                    TotalOverdue
                                                                )}
                                                            </b>
                                                        </Col>
                                                    </Row>
                                                    <Row>
                                                        <Col>
                                                            Total owing{' '}
                                                            {handleFormatCurrency(
                                                                TotalOwing
                                                            )}
                                                        </Col>
                                                    </Row>
                                                </Col>
                                            </Row>
                                        </Col>
                                    </Row>
                                </div>
                            </Col>
                            {isLg && usingCustomerWorkflow && (
                                <Col>{populateTaskAndReminderSection()}</Col>
                            )}
                        </Row>
                        <Row type="flex" align="middle" gutter={10}>
                            {isDueDateView && (
                                <Col
                                    {...atbColProps}
                                    className={get(ATBClassNames, 'NotDue')}
                                >
                                    Not due:{' '}
                                    {handleFormatCurrency(
                                        get(ATBData, 'NotDue', 0)
                                    )}
                                </Col>
                            )}
                            <Col
                                {...atbColProps}
                                className={get(ATBClassNames, 'Current')}
                            >
                                Current:{' '}
                                {handleFormatCurrency(
                                    get(ATBData, 'Current', 0)
                                )}
                            </Col>
                            <Col
                                {...atbColProps}
                                className={get(ATBClassNames, 'Period1')}
                            >
                                {isCalendarView ? '1 month' : '30 days'}:{' '}
                                {handleFormatCurrency(
                                    get(ATBData, 'Period1', 0)
                                )}
                            </Col>
                            <Col
                                {...atbColProps}
                                className={get(ATBClassNames, 'Period2')}
                            >
                                {isCalendarView ? '2 months' : '60 days'}:{' '}
                                {handleFormatCurrency(
                                    get(ATBData, 'Period2', 0)
                                )}
                            </Col>
                            <Col
                                {...atbColProps}
                                className={get(ATBClassNames, 'Period3Plus')}
                            >
                                {isCalendarView ? '3 months' : '90 days'}:{' '}
                                {handleFormatCurrency(
                                    get(ATBData, 'Period3Plus', 0)
                                )}
                            </Col>
                        </Row>
                        <Row>
                            <Col xl={0} lg={24} md={24} sm={24} xs={24}>
                                {populateCustomFields()}
                            </Col>
                        </Row>
                    </Col>
                    {/* <Col xl={1}>
                           <AvatarNameInitialsComponent
                               fullName={name}
                               size="large"
                           />
                       </Col> */}
                 </Row>
             </Col>
         </Row>
     );
 };
 
 const arePropsEqual = (prevProps: any, nextProps: any) => {
     if (
         JSON.stringify(prevProps.item) === JSON.stringify(nextProps.item) &&
         prevProps.selectedId === nextProps.selectedId &&
         isEqual(prevProps.selectedRowKeys, nextProps.selectedRowKeys) &&
         JSON.stringify(prevProps.style) === JSON.stringify(nextProps.style) &&
         prevProps.addCommentDrawerVisible === nextProps.addCommentDrawerVisible
     ) {
         return true;
     } else {
         return false;
     }
 };
 
 export default memo(
     withDateFormatHandler(withNumberFormatHandler(CustomerItemComponent)),
     arePropsEqual
 );
