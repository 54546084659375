/**
 * File responsible for all the UI and actions for Settings>Customization page - `/app/settings/customization`.
 */

import {
    Button,
    Col,
    Divider,
    Icon,
    Form,
    Input,
    Modal,
    Row,
    Select,
    Typography,
    Tabs} from 'antd';
import { get, includes, isEmpty, map } from 'lodash';
import React, { useEffect, useMemo, useState } from 'react';
import 'react-quill/dist/quill.snow.css';
import { useDispatch, useSelector } from 'react-redux';
import { withRouter } from 'react-router-dom';
import ModalWithSpinner from '../../components/common/ModalWithSpinner';
import { ApplicationState } from '../../store';
import { CompaniesState } from '../../store/companies/types';
import { getRolePermissions } from '../../store/roles/sagas';
import { DynamicObject } from '../../utils/commonInterfaces';
import FontAwesome from '../../components/common/FontAwesome';

import { BankFileCustomization, BankFileFormat, BankFileIgnoreString, BankFileIgnoreStringVM, BankFileMapping, BanksState, FieldMapping, IgnoreString, ObjectHeading } from '../../store/banks/types';
import { getBankFileAction, saveBankFileRequestAction } from '../../store/banks/actions';
import { confirmModalCancelText, confirmModalOkText } from '../../config/config';
import { getPopoverContainer, populatePopoverContainer } from '../../utils/commonFunctions';

const { Title } = Typography;
const { Item: FormItem } = Form;
const { confirm } = Modal;
const { Option } = Select;
const { TabPane } = Tabs;
type TargetKey = React.MouseEvent | React.KeyboardEvent | string;

interface IProps {
    containerRef?: any;
    drawerRef: any;
    form: DynamicObject;
    history: {
        push: (path: string) => void;
    };
}

const formFieldNames = {
    BankFileIgnoreStrings: 'BankFileIgnoreStrings',
    BankFileFormats: 'BankFileFormats',
    BankFileMappings: 'BankFileMappings'
};

const BankFileImportCustomizationPage: React.FC<IProps> = ({
    containerRef,
    drawerRef,
    form,
    history
}) => {
    const globalId = useMemo(()=>({id:0}),[]);
    const { getFieldDecorator, validateFields, resetFields, setFields, getFieldsValue, getFieldValue, setFieldsValue } = form;

    const dispatch = useDispatch();

    const bankState: BanksState = useSelector(
        (state: ApplicationState) => state.banks
    );
    
    const companiesState: CompaniesState = useSelector(
        (state: ApplicationState) => state.companies
    );

    const { selectedUserCompany } = companiesState;
    const { saveLoading, bankFileCustomization } = bankState;
    const [formHasChanged, setFormHasChanged] = useState(false);
    const [isFormValid, setIsFormValid] = useState(true);
    const [isCancleUpdateing, setIsCancleUpdateing] = useState(false);
    const rolePermissions = useSelector(getRolePermissions);

    const userRole = useSelector((state: ApplicationState) =>
        get(selectedUserCompany, 'Role.Name')
    );

    const supportCashAllocation = useSelector((state: ApplicationState) =>
        get(selectedUserCompany, 'Company.SupportCashAllocation')
    );

    const usingCustomBankFileProcessor = useSelector((state: ApplicationState) =>
        get(selectedUserCompany, 'Company.UsingCustomBankFileProcessor')
    );

    const [bankFileIgnoreStrings, setBankFileIgnoreStrings] = useState<BankFileIgnoreStringVM[]>([]);
    const [usingBankFileFormats, setUsingBankFileFormats] = useState<BankFileFormat[]>([]);
    const [activeKey, setActiveKey] = useState<string | undefined>();
    const [invalidTabs, setInvalidTabs] = useState<string[]>([]);

    useEffect(() => {
        dispatch(getBankFileAction());
    }, [dispatch, selectedUserCompany]);

    useEffect(() => {
        const bankFileIgnoreStrings = bankFileCustomization && get(bankFileCustomization, 'BankFileIgnoreStrings');
        let bankFileIgnoreStringsVM: BankFileIgnoreStringVM[] = [];

        if (bankFileIgnoreStrings) {
            bankFileIgnoreStringsVM = bankFileIgnoreStrings.map((bankFileIgnoreString) => {
                return {
                    Format: bankFileIgnoreString.Format,
                    IgnoreStrings: bankFileIgnoreString.IgnoreStrings.map((ignoreString) => {
                        return {
                            Id: `${globalId.id++}`,
                            IgnoreString: ignoreString
                        }
                    })
                }
            });
        }

        setBankFileIgnoreStrings(bankFileIgnoreStringsVM);

        if (isCancleUpdateing) {
            setIsCancleUpdateing(false);
        }
        
    }, [bankFileCustomization, selectedUserCompany, isCancleUpdateing]);

    const defaultConfigs: BankFileCustomization = {
        BankFileMappings: [],
        BankFileFormats: [],
        PaymentObjectHeading: {
            ObjectHeadings: []
        },
        BankFileIgnoreStrings: [],
    }
    const getInitialCustomizationValue = (field: string) => get(bankFileCustomization, field) || get(defaultConfigs, field);
    
    /**
     * Function that return the ignoreStrings by bank format.
     */
    const getIgnoreStringsByBankFormat = (bankFormat: string) => {
        const bankFileIgnoreString = bankFileIgnoreStrings.find(b => b.Format === bankFormat);
        let bankIgnoreStrings: IgnoreString[] = [];

        if (bankFileIgnoreString && bankFileIgnoreString.IgnoreStrings && bankFileIgnoreString.IgnoreStrings.length > 0) {
            bankIgnoreStrings = bankFileIgnoreString.IgnoreStrings;
        }

        return bankIgnoreStrings;
    };

    // Define validation rules
    const maxLengthValidation = (_: any, value: any, callback: any) => {
        if (value && value.length > 250) {
            setIsFormValid(false);
            callback(
                <div style={{ textAlign: 'left' }}>
                    Maximum length is 250 characters
                </div>
            );
        }
        else {
            setIsFormValid(true);
            callback();
        }
    };

    // Custom validator function to check for duplicate values
    const validateDuplicateValues = (selectedBankTab: string) => (rule: any, value: any, callback: any) => {
        const values = getFieldsValue();
        const inputBankFileIgnoreStrings = values[formFieldNames.BankFileIgnoreStrings];
        const ignoreStrings: string[] = inputBankFileIgnoreStrings ? inputBankFileIgnoreStrings[selectedBankTab].filter((i: string) => i !== null) : [];

        const valueCount = ignoreStrings.filter(
            (fieldValue) => fieldValue && value && fieldValue.toLowerCase() === value.toLowerCase()
          ).length;

        if (valueCount > 1) {
            setIsFormValid(false);
            callback('Duplicate value found. Please enter unique values.');
        } else {
            setIsFormValid(true);
            callback();
        }
    };

    const addField = (selectedBankTab: string) => {
        setFormHasChanged(true);

        setBankFileIgnoreStrings(() => {
            let updatedIgnoreStrings = bankFileIgnoreStrings;

            if (!JSON.stringify(bankFileIgnoreStrings).includes(selectedBankTab)) 
            {
                updatedIgnoreStrings = [
                    ...updatedIgnoreStrings, 
                    { 
                        Format: selectedBankTab, 
                        IgnoreStrings: [
                            {
                                Id: `${globalId.id++}`, 
                                IgnoreString: ''
                            }
                        ]
                    }
                ];
            }
            else 
            {
                updatedIgnoreStrings = bankFileIgnoreStrings.map((bankFileIgnoreString) => {
                    if (bankFileIgnoreString.Format === selectedBankTab) {
                        return {
                          ...bankFileIgnoreString,
                          IgnoreStrings: [
                            ...bankFileIgnoreString.IgnoreStrings, 
                            {
                                Id: `${globalId.id++}`, 
                                IgnoreString: ''
                            }
                        ]
                        };
                    }
                    return bankFileIgnoreString;
                });
            }
          
            return updatedIgnoreStrings;
        });
        
        validationTabPane();
    };
  
    const removeField = (selectedBankTab: string, itemId: string) => {
        setFormHasChanged(true);
       
        setBankFileIgnoreStrings(() => {
            const updatedIgnoreStrings = bankFileIgnoreStrings.map((bankFileIgnoreString) => {
                if (bankFileIgnoreString.Format === selectedBankTab) {

                    return {
                      ...bankFileIgnoreString,
                      IgnoreStrings: bankFileIgnoreString.IgnoreStrings.filter(i => i.Id !== itemId)
                    };
                }
                return bankFileIgnoreString;
            });
            return updatedIgnoreStrings;
        });

        validationTabPane();
    };

    const validationTabPane = () => {
        setTimeout(() => {
            validateFields(undefined, { force: true }, (error: DynamicObject, values: DynamicObject) => {
                if (error) {
                    const invalidTabs: string[] = [];
                    const bankFileFormats: BankFileFormat[] = getInitialCustomizationValue('BankFileFormats');
    
                    bankFileFormats.forEach((bankFileFormat) => {
                        if (JSON.stringify(error).includes(bankFileFormat.BankName)) {
                            invalidTabs.push(bankFileFormat.BankName);
                        }
                    });
    
                    setInvalidTabs(invalidTabs);
                    setActiveKey(invalidTabs[0] ? invalidTabs[0] : undefined);
                    setIsFormValid(false);
                }
                else 
                {
                    setInvalidTabs([]);
                    setIsFormValid(true);
                }
            });
        }, 1);
    };
    
    const onSaveButtonClick = () => {
        validateFields((err: DynamicObject, values: DynamicObject) => {
            if (!err) {
                const payload = bankCustomizationMappingToModel(values);
                dispatch(
                    saveBankFileRequestAction(
                        payload,
                        handleModalSaveResponse
                    )
                );
            }
            else {
                validationTabPane();
            }
        });
    };
    
    const bankCustomizationMappingToModel = (values: DynamicObject) => {
        const formatOptions: BankFileFormat[] = getInitialCustomizationValue('BankFileFormats');
        const bankFileFormats = formatOptions.filter(x => values[formFieldNames.BankFileFormats].includes(x.BankName));
        const inputBankFileMappings = values[formFieldNames.BankFileMappings];
        const inputBankFileIgnoreStrings = values[formFieldNames.BankFileIgnoreStrings];

        const bankFileMappings: BankFileMapping[] = Object.keys(inputBankFileMappings).map((bankName) => {
            const bankFileMapping = inputBankFileMappings[bankName];
            const bankFileFormat: BankFileFormat | undefined = bankFileFormats.find(b => b.BankName === bankName);


            return {
                Format: bankName,
                FieldMappings: Object.keys(bankFileMapping).map((headingName) => {
                    const bankFileHeading = bankFileMapping[headingName];
                    const heading = bankFileFormat ? bankFileFormat.Headings.find(x => x.Name == headingName) : undefined;
                    
                    return {
                        BankFileHeading: bankFileHeading,
                        PaymentHeading: headingName,
                        Index: heading ? heading.Index : -1
                    }
                })
            }
        });

        const bankFileIgnoreStrings = inputBankFileIgnoreStrings ? Object.keys(inputBankFileIgnoreStrings).map((bankName) => {
            const bankIgnoreStrings: string[] = inputBankFileIgnoreStrings[bankName];
            return {
                Format: bankName,
                IgnoreStrings: bankIgnoreStrings.filter(x => (x !== null && x !== ''))
            }
        }) : undefined;


        const bankCustomization: BankFileCustomization = {
            BankFileMappings: !usingCustomBankFileProcessor ? bankFileMappings : [],
            BankFileFormats: bankFileFormats,
            BankFileIgnoreStrings: bankFileIgnoreStrings
        };

        return bankCustomization;
    }

    /**
     * Function responsible for showing the response modal after saving bank file processing customization page.
     * @param param0 - object with success indicator and error message from api (if there's any)
     */
    const handleModalSaveResponse = (response: DynamicObject) => {
        if (response.IsSuccess) {
            setFormHasChanged(false);
            Modal.success({
                title: 'Success',
                content: `Bank file import customization settings saved successfully!`,
                onOk: () => {
                    dispatch(getBankFileAction());
                    setInvalidTabs([]);
                },
            });
        } else {
            let errorMessageContent: any = `Failed to save bank file import customization settings!`;
            if (!isEmpty(response.Messages)) {
                errorMessageContent = map(
                    response.Messages,
                    (error: string, index: number) => (
                        <div key={index}>{error}</div>
                    )
                );
            }

            Modal.error({
                title: 'Error',
                content: errorMessageContent,
                getContainer: () => getPopoverContainer(containerRef),
            });
        }
    };


    /**
     * Function called when `Cancel` button is clicked inside Customer payment options.
     * Pops up a confirmation modal asking to revert all changes made.
     */
    const onCancelButtonClick = () => {
        confirm({
            className: 'modal-swapped-buttons',
            title: 'Do you want to continue?',
            content: (
                <div>
                    When you click the <b>{confirmModalOkText}</b> button, all
                    the data will be reverted to the last saved values.
                </div>
            ),
            onOk() {
                setFormHasChanged(false);
                setIsCancleUpdateing(true);
                resetFields();
            },
            onCancel() { },
            okText: confirmModalOkText,
            cancelText: confirmModalCancelText,
        });
    };

    const allowedRoles = rolePermissions.CUSTOMIZATION_UPDATE;
    const formDisabled = !isEmpty(allowedRoles) && !includes(allowedRoles, userRole);

    
    useEffect(() => {
        const formatOptions: BankFileFormat[] = getInitialCustomizationValue('BankFileFormats');
        const bankfileMappings: BankFileMapping[] = getInitialCustomizationValue('BankFileMappings');
        const formats: string[] = bankfileMappings ? bankfileMappings.map((b: BankFileMapping) => b.Format) : [];
        const usingBankFileFormats = formatOptions.filter(f => formats.includes(f.BankName));

        setUsingBankFileFormats(usingBankFileFormats);
        setActiveKey(usingBankFileFormats[0] ? usingBankFileFormats[0].BankName : undefined);

        if(isCancleUpdateing) {
            setIsCancleUpdateing(false);
        }

    }, [bankFileCustomization, selectedUserCompany, isCancleUpdateing])
    
    const bankFileMappingSelectData = (selectedBankFileFormatTab: string) => {
        const selectedBankFileFormat =  usingBankFileFormats.find(x => x.BankName == selectedBankFileFormatTab);
        const bankFileOptions = selectedBankFileFormat ? selectedBankFileFormat.Headings : [] ;
        const paymentObjectHeadings: ObjectHeading[] = getInitialCustomizationValue('PaymentObjectHeading.ObjectHeadings');
        const bankFileMappings: BankFileMapping[] = getInitialCustomizationValue('BankFileMappings');
        const selectedBankFileMapping = bankFileMappings.find(x => x.Format === selectedBankFileFormatTab);
        
        const fieldMappings: FieldMapping[] = selectedBankFileMapping ? selectedBankFileMapping.FieldMappings : [];

        return paymentObjectHeadings.map((poh) => {
            const fieldMapping = fieldMappings.find(f => f.PaymentHeading === poh.Name);

            return { 
                key: `${formFieldNames.BankFileMappings}[${selectedBankFileFormatTab}][${poh.Name}]`, 
                label: poh.Description, 
                initValue: fieldMapping ? fieldMapping.BankFileHeading : undefined,
                isRequired: poh.IsRequired,
                options: bankFileOptions.map(bfo => {
                    return {
                        label: bfo.Description,
                        value: bfo.Name,
                    }
                })
            };
        });
    };
    
    const onChangeBankFileFormat = (selectedOptions?: string[]) => {
        const formatOptions: BankFileFormat[] = getInitialCustomizationValue('BankFileFormats');
        const selectedFormatOptions: BankFileFormat[] = selectedOptions ? formatOptions.filter(f => selectedOptions.includes(f.BankName)) : [];

        setUsingBankFileFormats(selectedFormatOptions);
        setActiveKey(selectedFormatOptions[0] ? selectedFormatOptions[0].BankName : undefined);
        setFormHasChanged(true);
        setIsFormValid(selectedFormatOptions.length > 0);
    }

    const renderDynamicSelect =
        (item: {
            key: string,
            initValue: string | undefined,
            label: string | undefined,
            isRequired: boolean | undefined,
            options: {
                label: string,
                value: string
            }[]
        }
        , customOnChange?: (value?: string) => void) => {
            const defaultHandleChange = () => {
                setFormHasChanged(true);
            }

            const handleChange = (value: string) => {
                defaultHandleChange();

                if (customOnChange) {
                    customOnChange(value);
                }

                validationTabPane();
            };

            return (
                <FormItem
                    label={item.label}
                    labelCol={{span: 24}}
                >
                {getFieldDecorator( item.key,
                    {
                        initialValue: item.initValue,
                        rules: [
                            {
                              required: item.isRequired,
                              whitespace: item.isRequired,
                              message: "This field is required."
                            }
                        ]
                    }
                    )(
                        <Select allowClear onChange={handleChange} placeholder={`Select bank format`} >
                            {item.options.map((option: {value:string, label: string}) => (
                                <Option key={option.value} value={option.value}>
                                    {option.label}
                                </Option>
                            ))}
                        </Select>
                    )}
            </FormItem>
        );
    };

    /**
     * Function that populates the BankFileFormat.
     */
    const populateBankFileFormat = () => {
        const initialBankNames = map(usingBankFileFormats, (i) => { return i.BankName});
        const formatOptions: BankFileFormat[] = getInitialCustomizationValue('BankFileFormats');
        const initialFormatOptions = formatOptions.map(f => {
            return {
                label: f.Description,
                value: f.BankName
            }
        });

        return (
            <FormItem>
                {getFieldDecorator('BankFileFormats', {
                    initialValue: initialBankNames,
                    rules: [
                        {
                          required: true,
                          message: "This field is required."
                        }
                    ]
                })(
                    <Select
                        placeholder="Select which banks you are receiving your bank file from"
                        style={{ width: '100%' }}
                        getPopupContainer={populatePopoverContainer(
                            drawerRef
                        )}
                        mode="multiple"
                        onChange={onChangeBankFileFormat}
                    >
                        {
                            initialFormatOptions.map((item) =>(
                                <Option key={item.label} value={item.value}>
                                    {item.label}
                                </Option>
                            ))
                        }
                    </Select>
                )}
            </FormItem>
        );
    }

    const onTabChange = (key: string) => {
        setActiveKey(key);
    };

    const onTabRemove = (targetKey: TargetKey) => {
        confirm({
            className: 'modal-swapped-buttons',
            title: 'Do you want to continue?',
            content: (
                <div>
                    When you click the <b>{confirmModalOkText}</b> button,
                    the configure for {targetKey} will be removed.
                </div>
            ),
            onOk() {
                const targetIndex = usingBankFileFormats.findIndex((pane) => pane.BankName === targetKey);
                const newPanes = usingBankFileFormats.filter((pane) => pane.BankName !== targetKey);
                if (newPanes.length && targetKey === activeKey) {
                  const bankFormat  = newPanes[targetIndex === newPanes.length ? targetIndex - 1 : targetIndex];
                  setActiveKey(bankFormat.BankName);
                }
            
                setUsingBankFileFormats(newPanes);
                setFieldsValue({ BankFileFormats: map(newPanes, (i) => { return i.BankName}) });
                setFormHasChanged(true);
            },
            onCancel() { },
            okText: confirmModalOkText,
            cancelText: confirmModalCancelText,
        });
    };

    const onBankFileIgnoreStringChange = (selectedBankTab: string, valueChange: string, itemId: string) => {
        setFormHasChanged(true);

        setBankFileIgnoreStrings(() => {
            return bankFileIgnoreStrings.map((bankFileIgnoreString) => {
                if (bankFileIgnoreString.Format === selectedBankTab) {
                    const bankIgnoreStrings = [...bankFileIgnoreString.IgnoreStrings];
                    
                    return {
                        ...bankFileIgnoreString,
                        IgnoreStrings: bankIgnoreStrings.map(item => {
                            if (item.Id === itemId) {
                                return { ...item, IgnoreString: valueChange };
                            }

                            return item;
                        })
                    };
                }
                return bankFileIgnoreString;
            });
        });
    };

    /**
     * Function that populates the BankFileIgnoreStrings.
     */
    const populateBankFileIgnoreStrings = (selectedBankTab: string) => {
        return getIgnoreStringsByBankFormat(selectedBankTab).map((item) => {
            return (<FormItem key={`${formFieldNames.BankFileIgnoreStrings}-${selectedBankTab}-${item.Id}`} required={false}>
                {/* Apply custom CSS styles */}
                <style>
                    {`
                        .form-inline-mb-0 .ant-form-explain { text-align: left; /* Add more custom styles as needed */ }
                    `}
                </style>

                {/* Render FormItem with Input and Button */}
                {getFieldDecorator(`${formFieldNames.BankFileIgnoreStrings}[${selectedBankTab}][${item.Id}]`, {
                    validateTrigger: ['onChange', 'onBlur'],
                    initialValue: item.IgnoreString,
                    rules: [
                        {
                            required: true,
                            whitespace: true,
                            message: "Please input or delete this field.",

                        },
                        { validator: maxLengthValidation },
                        { validator: validateDuplicateValues(selectedBankTab) },
                    ],
                })(
                    <Input key={globalId.id} onChange={(e) => onBankFileIgnoreStringChange(selectedBankTab, e.target.value, item.Id)} placeholder="Bank filter" style={{ width: '60%', marginRight: 8 }} autoFocus />
                )}
                {<Button
                    className="pa-0"
                    type="link"
                    style={{ color: 'inherit' }}
                    onClick={() => removeField(selectedBankTab, item.Id)}
                >
                    <FontAwesome icon={['fas', 'trash']} className="mr-10" />
                </Button>}
            </FormItem>)
        });
    }

    const renderBankFileConfiguration = (selectedBankTab: string) => {
        return (<Row>
            <Row>
                <Col span={24}>
                    <h4>Bank file filter fields</h4>
                </Col>
                <Col
                    xxl={15}
                    xl={15}
                    lg={18}
                    md={18}
                    sm={18}
                    xs={18}
                >
                    <Row>
                        <Col span={24}>
                            <p>Customize which fields to filter bank file on. Filtering will occur on a partial match of each field and any payments with a partial match will not be imported to IODM</p>
                            {populateBankFileIgnoreStrings(selectedBankTab)}
                            {<FormItem key={'AddFieldButton'}>
                                <Button type="dashed" onClick={() => addField(selectedBankTab)} style={{ width: '60%' }}>
                                    <Icon type="plus" /> Add field
                                </Button>
                            </FormItem>}
                        </Col>
                    </Row>
                </Col>
            </Row>
            <Divider />
            {
                !usingCustomBankFileProcessor && (
                    <Row>
                        <Col span={24}>
                            <h4>Bank file mapping</h4>
                        </Col>
                        <Col span={24}>
                            <Row>
                                <Col span={24}>
                                    <p>Customize mappings of bank file to IODM payments</p>
                                    <Row>
                                        {
                                            bankFileMappingSelectData(selectedBankTab).map((item, index) => (
                                                <Col key={`${selectedBankTab}${item.key}${index}`} xs={24} sm={12} md={8} lg={6}>
                                                  {renderDynamicSelect(item)}
                                                </Col>
                                              ))
                                        }
                                    </Row>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                )
            }
        </Row>);
    };

    const handleFormChange = () => {
        validationTabPane();
    };
    
    return (
        supportCashAllocation && (<div className="h-100">
            <Col span={24}>
                <Form
                    onChange={handleFormChange}
                    labelCol={{
                        xxl: { span: 7 },
                        xl: { span: 7 },
                        lg: { span: 6 },
                        md: { span: 6 },
                        sm: { span: 6 },
                        xs: { span: 6 },
                    }}
                    wrapperCol={{
                        xxl: { span: 17 },
                        xl: { span: 17 },
                        lg: { span: 18 },
                        md: { span: 18 },
                        sm: { span: 18 },
                        xs: { span: 18 },
                    }}
                >   
                    <Row key="title-container" type="flex" align="middle">
                        <Col span={18}>
                            <Row>
                                <Col>
                                    <Title level={3}>
                                        Bank file import customization
                                    </Title>
                                </Col>
                                <Col>
                                    Customize how your bank file is imported
                                </Col>
                            </Row>
                        </Col>
                        <Col span={6} className="ta-right">
                            <Button
                                className="mr-10 w-100px"
                                type="primary"
                                onClick={onSaveButtonClick}
                                disabled={formDisabled || !(formHasChanged && isFormValid)}
                                loading={saveLoading}
                            >
                                Save
                            </Button>
                            <Button
                                className="buttonGrey w-100px"
                                onClick={onCancelButtonClick}
                                disabled={formDisabled || !formHasChanged}
                            >
                                Cancel
                            </Button>
                        </Col>
                    </Row>
                    <Divider />
                    <Row>
                        <Col span={24}>
                            <h4>Bank file format</h4>
                        </Col>
                        <Col
                            xxl={15}
                            xl={15}
                            lg={18}
                            md={18}
                            sm={18}
                            xs={18}
                        >
                            <Row>
                                <Col span={12}>
                                    {
                                        !usingCustomBankFileProcessor && (<>
                                            <p>The banks you are receiving your bank file from</p>
                                            {populateBankFileFormat()}
                                        </>)
                                    }
                                    {
                                        usingCustomBankFileProcessor && (<>
                                            A custom bank file processor is currently active.<p />
                                        </>)
                                    }
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                    <Divider />
                    <Row>
                        <Col span={24}>
                            <h4>Bank file configuration</h4>
                        </Col>
                        <Col span={24}>
                            <Row>
                                <Col span={24}>
                                    <p>Customize configuration of bank file to IODM payments</p>
                                    <Row>
                                        <Tabs
                                          hideAdd
                                          onChange={onTabChange}
                                          activeKey={activeKey}
                                          type="editable-card"
                                          onEdit={onTabRemove}
                                        >
                                          {usingBankFileFormats.map(pane => (
                                            <TabPane tab={<span style = {{ color: invalidTabs.find(i => i === pane.BankName) ? 'red' : '' }}>{pane.Description}</ span >} key={pane.BankName}>
                                              {renderBankFileConfiguration(pane.BankName)}
                                            </TabPane>
                                          ))}
                                        </Tabs>
                                    </Row>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </Form>
                {saveLoading && (
                    <ModalWithSpinner
                        modalTitle="Saving bank file import customization data"
                        modalVisible={saveLoading}
                        displayMessage="Please wait while saving bank file import customization data..."
                    />
                )}
            </Col>
        </div>
    ));
};

const BankFileImportCustomizationPageForm = Form.create({
    name: 'bank-file-import-customization-page-form',
})(BankFileImportCustomizationPage);
export default withRouter(BankFileImportCustomizationPageForm);