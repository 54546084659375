/**
 * File for `Invoicing and Settlements` widget.
 */

import { Spin, Table } from 'antd';
import {
    clone,
    filter,
    forEach,
    get,
    includes,
    isEmpty,
    isNumber,
    isUndefined,
    map,
} from 'lodash';
import moment from 'moment-timezone';
import React, { useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import {
    Bar,
    BarChart,
    CartesianGrid,
    Cell,
    Legend,
    Line,
    LineChart,
    Pie,
    PieChart,
    ResponsiveContainer,
    Tooltip,
    TooltipFormatter,
    XAxis,
    YAxis,
} from 'recharts';
import { CUSTOM_FIELD_TYPES } from '../../config/tableAndPageConstants';
import {
    widgetDisplayTypeValues,
    populatePayloadForOrganisationWidgets,
    widgetDateRangeValues,
    tableCurrencyFormatter
} from '../../constants/dashboards';
import { dateFormatYYYYMMDDDash } from '../../constants/dateFormats';
import { getDashboardInvoicingAndSettlementsRequestAction } from '../../store/dashboards/actions';
import {
    dashboardBypassAPIFetch,
    nFormatter,
} from '../../utils/commonFunctions';
import { DynamicObject } from '../../utils/commonInterfaces';
import {
    appliedFilterIndicator,
    customFieldIndicator,
} from '../common/FilterBar';
import { withNumberFormatHandler } from '../common/NumberFormatHandler';
import {
    defaultEndDate,
    defaultStartDate,
} from './InvoicingSettlementsWidgetFields';

interface IProps {
    widgetDetails: DynamicObject;
    readonly formatCurrency: (amount: number) => JSX.Element;
    preview?: boolean;
    readonly isOrgView?: boolean;
    readonly functionRefObj?: any;
}

const InvoicingSettlementsWidget: React.FC<IProps> = ({
    widgetDetails,
    formatCurrency,
    preview,
    isOrgView,
    functionRefObj
}: IProps) => {
    const unmountedRef = useRef<any>(null);
    const dispatch = useDispatch();

    const [widgetState, setTableState] = useState<{
        lastWidgetDetails: DynamicObject;
        loading: boolean;
        dataSource: DynamicObject[];
    }>({
        lastWidgetDetails: {},
        loading: false,
        dataSource: [],
    });

    /**
     * Common function for updating the `widgetState` state.
     * @param widgetStateObject
     */
    const updateWidgetStateObject = (widgetStateObject: {}) => {
        setTableState({
            ...widgetState,
            ...widgetStateObject,
        });
    };

    const dispatchAction = (payloadCallback?: (payload: any) => void) => {
        let customerFieldValue: string = '';
        const customFieldFilters: DynamicObject = {};
        const customFieldsUsed = [
            CUSTOM_FIELD_TYPES.CUSTOMER,
            CUSTOM_FIELD_TYPES.INVOICE,
        ];

        forEach(widgetDetails, (wdValue: any, wdKey: string) => {
            if (includes(wdKey, customFieldIndicator)) {
                const customFieldType = get(
                    wdKey.replace(customFieldIndicator, '').split('--'),
                    0
                );

                if (includes(customFieldsUsed, customFieldType)) {
                    customFieldFilters[wdKey + appliedFilterIndicator] =
                        wdValue;
                }
            }

            else if (includes(wdKey, 'Customer')) {
                customerFieldValue = wdValue;
            }
        });

        let startDate: any = clone(defaultStartDate);
        let endDate: any = clone(defaultEndDate);
        const dateRange = get(widgetDetails, 'dateRangeSelected');
        if (dateRange === widgetDateRangeValues.CUSTOM_DATE_RANGE) {
            const customStartDate =
                get(widgetDetails, 'customDateRangeStart') || defaultStartDate;
            const customEndDate =
                get(widgetDetails, 'customDateRangeEnd') || defaultEndDate;
            startDate =
                moment(customStartDate).format(dateFormatYYYYMMDDDash) +
                'T00:00:00';
            endDate =
                moment(customEndDate).format(dateFormatYYYYMMDDDash) +
                'T23:59:59';
        } else if (dateRange === widgetDateRangeValues.THIS_CALENDAR_YEAR) {
            startDate = moment().format('YYYY-01-01T00:00:00');
            endDate = moment().format('YYYY-12-31T23:59:59');
        } else if (dateRange === widgetDateRangeValues.LAST_CALENDAR_YEAR) {
            startDate = moment()
                .subtract(1, 'year')
                .format('YYYY-01-01T00:00:00');
            endDate = moment()
                .subtract(1, 'year')
                .format('YYYY-12-31T23:59:59');
        } else if (
            dateRange === widgetDateRangeValues.THIS_FINANCIAL_YEAR_AU ||
            dateRange === widgetDateRangeValues.LAST_FINANCIAL_YEAR_AU
        ) {
            const thisYearFinancialYearStart = moment().format(
                'YYYY-07-01T00:00:00'
            );
            const thisYearFinancialYearStartEnd = moment()
                .add(1, 'year')
                .format('YYYY-06-30T23:59:59');
            if (dateRange === widgetDateRangeValues.THIS_FINANCIAL_YEAR_AU) {
                startDate = thisYearFinancialYearStart;
                endDate = thisYearFinancialYearStartEnd;
            } else if (
                dateRange === widgetDateRangeValues.LAST_FINANCIAL_YEAR_AU
            ) {
                startDate = moment(thisYearFinancialYearStart)
                    .subtract(1, 'year')
                    .format(`${dateFormatYYYYMMDDDash}T00:00:00`);
                endDate = moment(thisYearFinancialYearStartEnd)
                    .subtract(1, 'year')
                    .format(`${dateFormatYYYYMMDDDash}T23:59:59`);
            }
        }

        const IsMonthlyView = getIfIsMonthlyView();

        let payload: DynamicObject = {
            filters: {
                ...customFieldFilters,
            },
            DateMin: startDate,
            DateMax: endDate,
            IsMonthlyView,
            Customer: customerFieldValue
        };

        if (isOrgView) {
            payload = populatePayloadForOrganisationWidgets({
                payload,
                widgetDetails,
            });
        }
        dispatch(
            getDashboardInvoicingAndSettlementsRequestAction(
                payload,
                isOrgView,
                (invoicingSettlements: DynamicObject[]) => {
                    if (unmountedRef.current) return;

                    const usedDataSource = constructChartsArray(
                        startDate,
                        endDate,
                        IsMonthlyView,
                        invoicingSettlements
                    );

                    updateWidgetStateObject({
                        dataSource: usedDataSource,
                        loading: false,
                        lastWidgetDetails: clone(widgetDetails),
                    });
                },
                payloadCallback
            )
        );
    }

    /**
     * Function called for initializing widget data based on widgetDetails prop received.
     */
    const initializeWidgetData = () => {
        const bypassAPIFetching = dashboardBypassAPIFetch(
            widgetState.lastWidgetDetails,
            widgetDetails
        );
        if (bypassAPIFetching) return;

        updateWidgetStateObject({
            loading: true,
        });
        
        dispatchAction(undefined);
    };

    /**
     * Function responsible for setting the `unmounted` variable indicator for when this component unmounts.
     */
    const setInitialLoad = () => {
        unmountedRef.current = false;

        //will unmount
        return () => {
            unmountedRef.current = true;
        };
    };

    useEffect(setInitialLoad, []);

    /**
     * Function for constructing the charts data.
     * @param startDate
     * @param endDate
     * @param isMonthlyView
     * @param dataSource
     */
    const constructChartsArray = (
        startDate: string,
        endDate: string,
        isMonthlyView: boolean,
        dataSource: DynamicObject
    ) => {
        const momentStartDate = moment(startDate);
        const momentEndDate = moment(endDate);
        const interim = momentStartDate.clone();
        const chartArray: any = [];
        let indexValue = 0;
        if (isMonthlyView) {
            while (
                momentEndDate > interim ||
                interim.format('M') === momentEndDate.format('M')
            ) {
                const inerimKeyValue = interim.format('M-YYYY');
                const chartDataList = filter(
                    dataSource,
                    (d) => `${d.DisplayPeriod}-${d.Year}` === inerimKeyValue
                );
                const dataObject = {
                    key: inerimKeyValue,
                    xDisplay: interim.format('MMM-YYYY'),
                    'Created amount': 0,
                    'Settled amount': 0,
                };
                forEach(chartDataList, (chartData) => {
                    const typeName =
                        get(chartData, 'InvoicingSettlementType') ===
                        'CreatedAmount'
                            ? 'Created amount'
                            : 'Settled amount';

                    dataObject[typeName] = get(chartData, 'OriginalAmount', 0);
                });

                chartArray.push(dataObject);

                interim.add(1, 'month');
            }
        } else {
            while (
                momentEndDate > interim ||
                interim.format('W') === momentEndDate.format('W')
            ) {
                const inerimKeyValue = interim.format('W-YYYY');
                const chartDataList = filter(
                    dataSource,
                    (d) => `${d.DisplayPeriod}-${d.Year}` === inerimKeyValue
                );

                const dataObject = {
                    key: indexValue,
                    xDisplay: interim.format('W-YYYY'),
                    'Created amount': 0,
                    'Settled amount': 0,
                };
                forEach(chartDataList, (chartData) => {
                    const typeName =
                        get(chartData, 'InvoicingSettlementType') ===
                        'CreatedAmount'
                            ? 'Created amount'
                            : 'Settled amount';

                    dataObject[typeName] = get(chartData, 'OriginalAmount', 0);
                });

                chartArray.push(dataObject);

                interim.add(1, 'week');

                indexValue++;
            }
        }

        return chartArray;
    };

    /**
     * Function for formatting the tooltip.
     * @param value
     */
    const tooltipFormatter: TooltipFormatter = (value) => {
        if (isNumber(value)) {
            return formatCurrency(value);
        } else {
            return value;
        }
    };

    useEffect(initializeWidgetData, [widgetDetails]);

    if (functionRefObj) {
        functionRefObj.getPayload = (callback: (payload: any) => void) => {
            dispatchAction(callback);
        };
    }

    /**
     * Function for getting if the chart grouping option is monthly or not.
     */
    const getIfIsMonthlyView = () => {
        return !isUndefined(get(widgetDetails, 'groupingOptions'))
            ? get(widgetDetails, 'groupingOptions')
            : true;
    };

    /**
     * Function for populating the label whether the chart is set to Monthly or Weekly view.
     */
    const populateChartLabel = () => {
        if (!widgetState.loading) {
            const isMonthlyView = getIfIsMonthlyView();
            const labelText = isMonthlyView ? 'Monthly view' : 'Weekly view';

            return <div className="chart-title">{labelText}</div>;
        }

        return null;
    };

    /**
     * Function that sorts the order for values inside a tooltip.
     * @param tooltipPayload
     */
    const tooltipItemSorter = (tooltipPayload: any) => {
        if (tooltipPayload.dataKey === blueValueKey) {
            return 1;
        }
        return 0;
    };

    const COLORS = [
        '#0088FE',
        '#00C49F',
        '#FFBB28',
        '#FF8042',
        '#F44336',
        '#9C27B0',
        '#FFEB3B',
        '#795548',
        '#8BC34A',
        '#263238',
    ];

    /**
     * Function for rendering the labels for pie chart.
     * @param props
     */
    const renderCustomizedLabel = (props: any) => {
        const RADIAN = Math.PI / 180;
        const {
            cx,
            cy,
            midAngle,
            outerRadius,
            xDisplay,
            percent,
            innerRadius,
        } = props;
        const sin = Math.sin(-RADIAN * midAngle);
        const cos = Math.cos(-RADIAN * midAngle);
        const textAnchor = cos >= 0 ? 'start' : 'end';
        const radius = innerRadius + (outerRadius - innerRadius) * 1.2;
        const x = cx + radius * cos;
        const y = cy + radius * sin;
        return (
            <g>
                <text
                    x={x}
                    y={y}
                    textAnchor={textAnchor}
                    fill="#333"
                    dominantBaseline="central"
                >
                    {xDisplay}
                </text>
                <text x={x} y={y} dy={18} textAnchor={textAnchor} fill="#999">
                    {`${(percent * 100).toFixed(2)}%`}
                </text>
            </g>
        );
    };

    /**
     * Function for formatting the y axis tick labels
     */
    const yTickFormatter = (value: number) => {
        return nFormatter(value);
    };

    let orangeValueKey = 'Settled amount';
    let blueValueKey = 'Created amount';

    const populateWidgetContent = () => {
        const displayView = get(widgetDetails, 'displayType');

        if(displayView === widgetDisplayTypeValues.TABLE){
            const columns = [
                {
                    title: 'Date',
                    dataIndex: 'xDisplay',
                },
                {
                    title: 'Created Amount',
                    dataIndex: 'Created amount',
                },
                {
                    title: 'Settled Amount',
                    dataIndex: 'Settled amount',
                },
            ];

            return (
                <Table
                    rowClassName={(record) => {
                        return get(record, 'xDisplay') === 'Grand total'
                            ? 'table-total-row'
                            : '';
                    }}
                    className="table-striped-rows table-ws-nw"
                    columns={columns}
                    dataSource={tableCurrencyFormatter(widgetState.dataSource, formatCurrency)}
                    loading={widgetState.loading}
                    pagination={false}
                    size="middle"
                    rowKey="key"
                />
            );
        } else if (displayView === widgetDisplayTypeValues.PIE_CHART) {
            // remove data with 0 value
            const filteredData = (widgetState.dataSource).filter((dataPoint: any) => dataPoint["Settled amount"] > 0);

            return (
                <Spin wrapperClassName="spinner-wh100" spinning={widgetState.loading}>
                    <ResponsiveContainer width="99%" height="99%">
                        <PieChart>
                            <Pie
                                paddingAngle={1}
                                minAngle={1}
                                data={filteredData}
                                label={renderCustomizedLabel}
                                labelLine={true}
                                outerRadius="70%"
                                fill="#8884d8"
                                dataKey="Settled amount"
                                nameKey="xDisplay"
                                isAnimationActive={false}
                            >
                                {map(filteredData, (_entry, index) => (
                                    <Cell
                                        key={index}
                                        fill={COLORS[index % COLORS.length]}
                                    />
                                ))}
                            </Pie>
                            <Tooltip formatter={tooltipFormatter} />
                            {preview && <Legend />}
                        </PieChart>
                    </ResponsiveContainer>
                </Spin>
            );
            } else if (displayView === widgetDisplayTypeValues.BAR_CHART || displayView === undefined) {
                return (
                    <Spin
                    wrapperClassName="spinner-wh100"
                    spinning={widgetState.loading}
                >
                    {populateChartLabel()}
    
                    <ResponsiveContainer width="99%" height="99%">
                        <BarChart
                            data={widgetState.dataSource}
                            margin={{
                                top: 20,
                                right: 30,
                                left: 20,
                                bottom: 15,
                            }}
                        >
                            <CartesianGrid strokeDasharray="3 3" />
                            <XAxis dataKey="xDisplay" />
                            <YAxis tickFormatter={yTickFormatter} />
                            <Tooltip
                                formatter={tooltipFormatter}
                                itemSorter={tooltipItemSorter}
                            />
                            {preview && <Legend />}
                            <Bar dataKey={blueValueKey} fill="#0088fe" />
                            <Bar dataKey={orangeValueKey} fill="#fd6a02" />
                        </BarChart>
                    </ResponsiveContainer>
                </Spin>
            );
        } else {
            return (
                <Spin
                wrapperClassName="spinner-wh100"
                spinning={widgetState.loading}
            >
                {populateChartLabel()}

                <ResponsiveContainer width="99%" height="99%">
                    <LineChart
                        data={widgetState.dataSource}
                        margin={{
                            top: 20,
                            right: 30,
                            left: 20,
                            bottom: 15,
                        }}
                    >
                        <CartesianGrid strokeDasharray="3 3" />
                        <XAxis dataKey="xDisplay" />
                        <YAxis tickFormatter={yTickFormatter} />
                        <Tooltip
                            formatter={tooltipFormatter}
                            itemSorter={tooltipItemSorter}
                        />
                        {preview && <Legend />}
                        <Line dataKey={blueValueKey} stroke="#0088fe" type="monotone" />
                        <Line dataKey={orangeValueKey} stroke="#fd6a02" type="monotone" />
                    </LineChart>
                </ResponsiveContainer>
            </Spin>
        );
    }
    };

    return (
        <div className="invoicing-settlements-widget-container h-100">
            {populateWidgetContent()}
        </div>
    );
};

export default withNumberFormatHandler(InvoicingSettlementsWidget);
