/**
 * File for the `Active Tasks per user` widget.
 */

import { Spin, Table } from 'antd';
import { clone, find, forEach, get, includes, isEmpty, isNumber, map, sum, isUndefined, set } from 'lodash';
import moment from 'moment-timezone';
import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
    Bar,
    BarChart,
    CartesianGrid,
    Cell,
    Legend,
    Pie,
    PieChart,
    ResponsiveContainer,
    Tooltip,
    TooltipFormatter,
    XAxis,
    YAxis,
} from 'recharts';
import { CUSTOM_FIELD_TYPES } from '../../config/tableAndPageConstants';
import { widgetDisplayTypeValues, populatePayloadForOrganisationRegionalWidgets, tableNumberFormatter } from '../../constants/dashboards';
import { ApplicationState } from '../../store';
import { getDashboardTasksCountForUsersRequestAction, getDashboardTasksCountForUserTypesRequestAction } from '../../store/dashboards/actions';
import { TaskCountStateName } from '../../store/dashboards/types';
import { dashboardBypassAPIFetch, getDateFilterValues, checkIfEmailIsValid } from '../../utils/commonFunctions';
import { DynamicObject } from '../../utils/commonInterfaces';
import {
    appliedFilterIndicator,
    customFieldIndicator,
    taskFieldIndicator
} from '../common/FilterBar';
import { withNumberFormatHandler } from '../common/NumberFormatHandler';
import { dateSelectOptions } from '../../constants/invoicesSortAndFilters';
import { tasksStatusFilterOptions } from '../../constants/tasksSortAndFilters';

interface IProps {
    widgetDetails: DynamicObject;
    readonly preview?: boolean;
    readonly isOrgView?: boolean;
    readonly organisationCurrenciesAll?: DynamicObject[];
    readonly formatNumber: (
        value: number,
        decimalScale?: number,
        cusLocale?: string
    ) => JSX.Element;
    readonly functionRefObj?: any;
}
const COLORS = [
    '#0088FE',
    '#00C49F',
    '#FFBB28',
    '#FF8042',
    '#F44336',
    '#9C27B0',
    '#FFEB3B',
    '#795548',
    '#8BC34A',
    '#263238',
];

const TasksForUsersWidget: React.FC<IProps> = ({
    widgetDetails,
    preview,
    isOrgView,
    organisationCurrenciesAll,
    formatNumber,
    functionRefObj
}: IProps) => {
    const unmountedRef = useRef<any>(null);
    const dispatch = useDispatch();
    let widgetRef = useRef<any>(null);
    const organisationCompanies = useSelector(
        (app: ApplicationState) => app.organisations.companies.data
    );

    const [widgetState, setTableState] = useState<{
        lastWidgetDetails: DynamicObject;
        loading: boolean;
        dataSource: DynamicObject[];
        tickWidth: number;
        chartMarginLeft: number;
        groupByUserType: boolean;
    }>({
        lastWidgetDetails: {},
        loading: false,
        dataSource: [],
        tickWidth: 30,
        chartMarginLeft: 20,
        groupByUserType: false
    });

    /**
     * Variable indicator for when the company is using customer workflow or not.
     */
    const usingCustomerWorkflow: boolean = useSelector(
        (state: ApplicationState) =>
            get(
                state.companies.selectedUserCompany,
                'Company.UsingCustomerWorkflow'
            )
    );

    let currencyValueUsed: any = undefined;
    let localeUsed: any = undefined;
    if (isOrgView) {
        const region = widgetDetails.Region;
        const currencySelectedParsed = region
            ? find(organisationCurrenciesAll, ['Name', region])
            : undefined;

        currencyValueUsed =
            currencySelectedParsed || get(organisationCurrenciesAll, 0);
        localeUsed = get(currencyValueUsed, 'Locale');
    }

    /**
     * Common function for updating the `widgetState` state.
     * @param widgetStateObject
     */
    const updateWidgetStateObject = (widgetStateObject: {}) => {
        if (unmountedRef.current) return;
        setTableState({
            ...widgetState,
            ...widgetStateObject,
        });
    };


    interface ResultObject {
        WorkflowId: string;
        States: string[];
    }

    function convertTasksWorkflowsToObject(tasksWorkflows: string[]): ResultObject[] {
        const workflowMap: { [key: string]: string[] } = {};
        if (!isUndefined(tasksWorkflows)) {
            tasksWorkflows.forEach((workflow) => {
                const [state, workflowId] = workflow.split('---');
                if (workflowMap[workflowId]) {
                    workflowMap[workflowId].push(state);
                } else {
                    workflowMap[workflowId] = [state];
                }
            })
        };

        return Object.keys(workflowMap).map((workflowId) => ({
            WorkflowId: workflowId,
            States: workflowMap[workflowId],
        }));
    }

    /**
     * Function for getting the length of text.
     * @param text
     */
    const measureText = (text: string) => {
        return text ? text.length : 0;
    };

    /**
     * Function to remove Internal or External users from datasource
     * @param data - The data source
     * @param userType - "Internal" | "External"
     * @param grouped - true | false
     */
    const removeUserType = (data: DynamicObject, userType: string, grouped: boolean) => {
        if (!grouped) {
            if (userType === "Internal") {
                return data.filter(
                    (item: any) => item.User === null
                );
            }
            else {
                return data.filter(
                    (item: any) => item.AssignedEmailAddress === null
                );
            }
        }
        else {
            if (userType === "Internal") {
                return data.filter(
                    (item: any) => item.UserType !== "Company Users"
                );
            }
            else {
                return data.filter(
                    (item: any) => item.UserType !== "External Users"
                );
            }
        }

    };

    const dispatchAction = (payloadCallback?: (payload: any) => void) => {
        const customFieldFilters: DynamicObject = {};
        let InvoiceNumber: string = '';
        let AssignedUserId: any = undefined;
        let AssignedEmailAddress: any = undefined;
        let Customer: string = '';
        let CustomerCountry: string = '';
        let CustomerState: string = '';
        let amountFilters: any = {
            AmountType: '',
            AmountValue: 0,
            AmountOperator: '',
        };
        let actionDateFilters: any = {
            value: undefined,
            From: {},
            To: {},
            Last: undefined,
            Next: undefined
        }
        let isRangeTypeFromLast: boolean = false;
        let isRangeTypeToLast: boolean = false;
        let ActionDateMin: any = undefined;
        let ActionDateMax: any = undefined;
        let WorkflowFilters: DynamicObject = {};
        let DisplayInternalUsers = true;
        let DisplayExternalUsers = true;
        let GroupByUserType = false

        actionDateFilters.value = get(widgetDetails, "TaskField---ActionDate--DateType");

        DisplayInternalUsers = !isUndefined(get(widgetDetails, "TaskField---InternalUsers")) ?
            get(widgetDetails, "TaskField---InternalUsers") :
            true;
        DisplayExternalUsers = !isUndefined(get(widgetDetails, "TaskField---ExternalUsers")) ?
            get(widgetDetails, "TaskField---ExternalUsers") :
            true;

        if (get(widgetDetails, "groupingOptions") === "UserType") {
            GroupByUserType = true
        }

        if (get(widgetDetails, "TaskField---ActionDate--RangeTypeFrom") === "Last") {
            isRangeTypeFromLast = true;
        }

        if (get(widgetDetails, "TaskField---ActionDate--RangeTypeTo") === "Last") {
            isRangeTypeToLast = true;
        }

        forEach(widgetDetails, (wdValue: any, wdKey: string) => {
            if (includes(wdKey, customFieldIndicator)) {
                const customFieldType = get(
                    wdKey.replace(customFieldIndicator, '').split('--'),
                    0
                );
                if (
                    (usingCustomerWorkflow &&
                        customFieldType !== CUSTOM_FIELD_TYPES.INVOICE) ||
                    !usingCustomerWorkflow
                ) {
                    customFieldFilters[wdKey + appliedFilterIndicator] =
                        wdValue;
                }
            }

            if (includes(wdKey, taskFieldIndicator) && !isUndefined(wdValue)) {
                if (includes(wdKey, "Amount--AmountType")) {
                    amountFilters.AmountType = wdValue;
                }
                else if (includes(wdKey, "Amount--AmountOperator")) {
                    amountFilters.AmountOperator = wdValue;
                }
                else if (includes(wdKey, "Amount--AmountValue")) {
                    amountFilters.AmountValue = wdValue;
                }
                else if (includes(wdKey, "CustomerCountry")) {
                    CustomerCountry = wdValue;
                }
                else if (includes(wdKey, "CustomerState")) {
                    CustomerState = wdValue;
                }
                else if (includes(wdKey, "Customer")) {
                    Customer = wdValue;
                }
                else if (includes(wdKey, "AssignedUserId")) {
                    if (checkIfEmailIsValid(wdValue)) {
                        AssignedEmailAddress = wdValue
                    }
                    else if (includes(wdKey, "AssignedUserId--UserId")) {
                        AssignedUserId = wdValue;
                    }
                }
                else if (includes(wdKey, "InvoiceNumber")) {
                    InvoiceNumber = wdValue;
                }
                else if (includes(wdKey, "ActionDate")) {
                    if (actionDateFilters.value === dateSelectOptions.CUSTOM_DATE_RANGE) {
                        if (includes(wdKey, "From")) {
                            actionDateFilters.From = moment(wdValue);
                        }
                        else if (includes(wdKey, "To")) {
                            actionDateFilters.To = moment(wdValue);
                        }

                        const { minDate, maxDate } = getDateFilterValues(actionDateFilters);
                        ActionDateMin = minDate;
                        ActionDateMax = maxDate;
                    }
                    else if (actionDateFilters.value === dateSelectOptions.CUSTOM_DAYS_RANGE) {
                        if (includes(wdKey, "Last--From") && isRangeTypeFromLast) {
                            actionDateFilters.From.Last = wdValue;
                        }
                        else if (includes(wdKey, "Next--From") && !isRangeTypeFromLast) {
                            actionDateFilters.From.Next = wdValue;
                        }
                        else if (includes(wdKey, "Last--To") && isRangeTypeToLast) {
                            actionDateFilters.To.Last = wdValue;
                        }
                        else if (includes(wdKey, "Next--To") && !isRangeTypeToLast) {
                            actionDateFilters.To.Next = wdValue;
                        }
                    }

                    const { minDate, maxDate } = getDateFilterValues(actionDateFilters);
                    ActionDateMin = minDate;
                    ActionDateMax = maxDate;
                }
            }
        });

        const taskTypes = map(
            get(widgetDetails, 'tasksTypes'),
            (taskType: string) => parseInt(taskType)
        );

        WorkflowFilters = convertTasksWorkflowsToObject(get(widgetDetails, 'tasksWorkflows'))

        let payload: DynamicObject = {
            filters: {
                DisplayName: Customer,
                CustomerCode: Customer,
                InvoiceNumber,
                ...amountFilters,
                ActionDateMin: !isEmpty(ActionDateMin) ? ActionDateMin : undefined,
                ActionDateMax: !isEmpty(ActionDateMax) ? ActionDateMax : undefined,
                CustomerCountry,
                CustomerState,
                AssignedUserId,
                AssignedEmailAddress,
                WorkflowFilters,
                ...customFieldFilters,
                Status: get(tasksStatusFilterOptions, "0.value"),
                Contact: 0
            },
            Type: sum(taskTypes),
        };

        if (isOrgView) {
            payload = populatePayloadForOrganisationRegionalWidgets({
                payload,
                widgetDetails,
                organisationCompanies,
            });
        }
        if (!GroupByUserType) {
            dispatch(
                getDashboardTasksCountForUsersRequestAction(
                    payload,
                    isOrgView,
                    (tasksCount: TaskCountStateName[]) => {
                        if (unmountedRef.current) return;

                        if (!isEmpty(tasksCount)) {
                            if (!DisplayInternalUsers) {
                                tasksCount = removeUserType(tasksCount, "Internal", GroupByUserType);
                            }

                            if (!DisplayExternalUsers) {
                                tasksCount = removeUserType(tasksCount, "External", GroupByUserType);
                            }
                        }

                        const dataSource = map(
                            tasksCount,
                            (tc: TaskCountStateName) => {
                                let user = !isEmpty(get(tc, 'User')) ?
                                    `${get(tc, 'User.GivenName')}${" "}${get(tc, 'User.FamilyName')}` :
                                    !isEmpty(get(tc, 'AssignedEmailAddress', '')) ?
                                        get(tc, 'AssignedEmailAddress', '') :
                                        'Unassigned/System'

                                return {
                                    ...tc,
                                    Count: tc.Count,
                                    User: user
                                }
                            }
                        );

                        dataSource.sort((a, b) => b.Count - a.Count);

                        let longestWord = '';
                        map(dataSource, (ds) => {
                            let user = ds.User

                            if (longestWord.length < user.length) {
                                longestWord = user;
                            }
                        })

                        const longestWordWidth = measureText(longestWord) * 8;
                        const tickWidth = longestWordWidth > 300 ? 300 : longestWordWidth;
                        const chartMarginLeft = tickWidth - 60 > 0 ? tickWidth - 60 : tickWidth;

                        updateWidgetStateObject({
                            dataSource,
                            loading: false,
                            lastWidgetDetails: clone(widgetDetails),
                            tickWidth,
                            chartMarginLeft,
                            groupByUserType: GroupByUserType
                        });
                    },
                    payloadCallback
                )
            );
        }
        else {
            dispatch(
                getDashboardTasksCountForUserTypesRequestAction(
                    payload,
                    isOrgView,
                    (tasksCount: TaskCountStateName[]) => {
                        if (unmountedRef.current) return;

                        if (!isEmpty(tasksCount)) {
                            if (!DisplayInternalUsers) {
                                tasksCount = removeUserType(tasksCount, "Internal", GroupByUserType);
                            }

                            if (!DisplayExternalUsers) {
                                tasksCount = removeUserType(tasksCount, "External", GroupByUserType);
                            }
                        }

                        const dataSource = map(
                            tasksCount,
                            (tc: TaskCountStateName) => {
                                let user = get(tc, 'UserType');

                                return {
                                    ...tc,
                                    Count: tc.Count,
                                    User: user
                                }
                            }
                        );

                        dataSource.sort((a, b) => b.Count - a.Count);

                        let longestWord = '';
                        map(dataSource, (ds) => {
                            let user = ds.User

                            if (longestWord.length < user.length) {
                                longestWord = user;
                            }
                        })

                        const longestWordWidth = measureText(longestWord) * 8;
                        const tickWidth = longestWordWidth > 300 ? 300 : longestWordWidth;
                        const chartMarginLeft = tickWidth - 60 > 0 ? tickWidth - 60 : tickWidth;

                        updateWidgetStateObject({
                            dataSource,
                            loading: false,
                            lastWidgetDetails: clone(widgetDetails),
                            tickWidth,
                            chartMarginLeft,
                            groupByUserType: GroupByUserType
                        });
                    },
                    payloadCallback
                )
            );
        }
    }

    /**
     * Function called for initializing widget data based on widgetDetails prop received.
     */
    const initializeWidgetData = () => {
        const bypassAPIFetching = dashboardBypassAPIFetch(
            widgetState.lastWidgetDetails,
            widgetDetails
        );
        if (bypassAPIFetching) return;

        updateWidgetStateObject({
            loading: true,
        });

        dispatchAction(undefined);
    };

    useEffect(initializeWidgetData, [widgetDetails]);

    if (functionRefObj) {
        functionRefObj.getPayload = (callback: (payload: any) => void) => {
            dispatchAction(callback);
        };
    }

    /**
     * Function responsible for setting the `unmounted` variable indicator for when this component unmounts.
     */
    const setInitialLoad = () => {
        unmountedRef.current = false;

        //will unmount
        return () => {
            unmountedRef.current = true;
        };
    };

    useEffect(setInitialLoad, []);


    /**
     * Function for formatting the tooltip.
     * @param value
     */
    const tooltipFormatter: TooltipFormatter = (value) => {
        if (isNumber(value)) {
            return formatNumber(value);
        } else {
            return value;
        }
    };

    /**
   * Function that sorts the order for values inside a tooltip.
   * @param tooltipPayload
   */
    const tooltipItemSorter = (tooltipPayload: any) => {
        if (tooltipPayload.dataKey === blueValueKey) {
            return 1;
        }
        return 0;
    };

    /**
     * Function for rendering the labels for pie chart.
     * @param props
     */
    const renderCustomizedLabel = (props: any) => {
        const RADIAN = Math.PI / 180;
        const {
            cx,
            cy,
            midAngle,
            outerRadius,
            User,
            percent,
            innerRadius,
        } = props;
        const sin = Math.sin(-RADIAN * midAngle);
        const cos = Math.cos(-RADIAN * midAngle);
        const textAnchor = cos >= 0 ? 'start' : 'end';
        const radius = innerRadius + (outerRadius - innerRadius) * 1.2;
        const x = cx + radius * cos;
        const y = cy + radius * sin;
        return (
            <g>
                <text
                    x={x}
                    y={y}
                    textAnchor={textAnchor}
                    fill="#333"
                    dominantBaseline="central"
                >
                    {User}
                </text>
                <text x={x} y={y} dy={18} textAnchor={textAnchor} fill="#999">
                    {`${(percent * 100).toFixed(2)}%`}
                </text>
            </g>
        );
    };

    let blueValueKey = 'Count';

    const populateWidgetContent = () => {
        const displayView = get(widgetDetails, 'displayType');
        const {
            loading: stateLoading,
            dataSource: stateDataSource,
            tickWidth: stateTickWidth,
            chartMarginLeft: stateChartMarginLeft,
            groupByUserType: groupByUserType
        } = widgetState;

        if (displayView === widgetDisplayTypeValues.TABLE) {
            const columns = [
                {
                    title: groupByUserType ? 'User Type' : 'User',
                    dataIndex: 'User',
                },
                {
                    title: 'Count',
                    dataIndex: 'Count',
                },

            ];

            return (
                <Table
                    rowClassName={(record) => {
                        return get(record, 'User') === 'Grand total'
                            ? 'table-total-row'
                            : '';
                    }}
                    className="table-striped-rows table-ws-nw"
                    columns={columns}
                    dataSource={tableNumberFormatter(stateDataSource, formatNumber)}
                    loading={stateLoading}
                    pagination={false}
                    size="middle"
                    rowKey="User"
                />
            );
        } else if (displayView === widgetDisplayTypeValues.PIE_CHART || displayView === undefined) {
            return (
                <Spin wrapperClassName="spinner-wh100" spinning={stateLoading}>
                    <ResponsiveContainer width="99%" height="99%">
                        <PieChart>
                            <Pie
                                paddingAngle={1}
                                minAngle={1}
                                data={stateDataSource}
                                label={renderCustomizedLabel}
                                labelLine={true}
                                outerRadius="70%"
                                fill="#8884d8"
                                dataKey="Count"
                                nameKey="User"
                                isAnimationActive={false}
                            >
                                {map(stateDataSource, (_entry, index) => (
                                    <Cell
                                        key={index}
                                        fill={COLORS[index % COLORS.length]}
                                    />
                                ))}
                            </Pie>
                            <Tooltip formatter={tooltipFormatter} />
                            {preview && <Legend />}
                        </PieChart>
                    </ResponsiveContainer>
                </Spin>
            );
        } else {
            return (
                <Spin wrapperClassName="spinner-wh100" spinning={stateLoading}>
                    <ResponsiveContainer width="99%" height="99%">
                        <BarChart
                            data={stateDataSource}
                            margin={{
                                top: 20,
                                right: 20,
                                left: stateChartMarginLeft,
                                bottom: 15,
                            }}
                            layout="vertical"
                        >
                            <CartesianGrid strokeDasharray="3 3" />
                            <YAxis
                                type="category"
                                dataKey="User"
                                tick={{
                                    fontSize: 14,
                                    width: stateTickWidth,
                                }}
                            />
                            <XAxis
                                type="number"
                                allowDecimals={false}
                                width={stateTickWidth}
                                orientation="bottom"
                            />
                            <Tooltip formatter={tooltipFormatter} />
                            {preview && <Legend />}
                            <Bar dataKey={blueValueKey} fill="#0088fe" />
                        </BarChart>
                    </ResponsiveContainer>
                    <div className="tickets-sticky-x-axis" />
                </Spin >
            );
        }
    };

    return (
        <div className="tasks-widget-container h-100" ref={widgetRef}>
            {populateWidgetContent()}
        </div>
    );
};

export default withNumberFormatHandler(TasksForUsersWidget);
