import { all, call, delay, fork, put, select, takeLatest } from "redux-saga/effects";
import { ApplicationState } from "..";
import { ReportActionTypes } from "./types";
import { checkShouldRequestRefetch, getGraphqlQueryString, getRegionConfigFromList, getSortFieldsWithCustomFields, removeAppliedFiltersForApiRequest } from "../../utils/commonFunctions";
import { DynamicObject } from "../../utils/commonInterfaces";
import { API, Auth, graphqlOperation } from "aws-amplify";
import queries from '../../graphql/queries.graphql';
import { REPORTS_PAGE } from "../../config/tableAndPageConstants";
import { get } from "lodash";
import { getReportDataErrorAction, getReportDataSuccessAction, getReportsErrorAction, getReportsRequestAction, getReportsSuccessAction } from "./actions";
import { API_NAME, maxAPIRefetchCount, refetchAPIDelay } from "../../config/config";
import { getRegionKeyConfig, getRegionSettingConfig } from "../auth/sagas";

export const getSelectedReportId = (state: ApplicationState) =>
    state.report.activeData.selectedId;

export const getReportData = (state: ApplicationState) =>
    state.report.activeData;

function* handleGetReportsRequest({ payload }: any) {
    const errorMessage = 'Error fetching report list. Please try again later.';
    let { refetchCount } = get(payload, 'refetchCount') || 0;
    try {
        const {
            filters,
            sortBy,
            sortAscending,
            pageSize,
            currentPage,
            excludeReports,
            Region
        } = payload;
        const cleanFilters = removeAppliedFiltersForApiRequest(
            filters,
            true,
            'report'
        );
        const sortObject = getSortFieldsWithCustomFields(sortBy);

        // To call async functions, use redux-saga's `call()`.
        const res: DynamicObject = yield call(
            [API, 'graphql'],
            graphqlOperation(queries.GET_REPORT_HISTORIES_FOR_COMPANY, {
                ...cleanFilters,
                ...sortObject,
                Ascending: sortAscending,
                PageSize: pageSize,
                Skip: currentPage * REPORTS_PAGE.pageSize,
                ExcludeReports: excludeReports
            })
        );

        const { ReportHistories } = get(res.data, 'GetReportHistoriesForCompany');
        if (ReportHistories) {
            const responsePayload = {
                data: ReportHistories,
                pageData: {
                    pageSize: pageSize,
                    currentPage: currentPage,
                    hasNextPage:
                        !(ReportHistories.length < pageSize) &&
                        !(pageSize < REPORTS_PAGE.pageSize),
                },
            };

            refetchCount = 0;
            yield put(getReportsSuccessAction(responsePayload));
        }
    } catch (err) {
        if (err instanceof Error) {
            console.log('Error', err);
        } else {
            console.error('An unknown error occured.', err);
        }

        if (
            refetchCount <= maxAPIRefetchCount &&
            checkShouldRequestRefetch(err)
        ) {
            refetchCount++;
            yield delay(refetchAPIDelay);
            yield put(getReportsRequestAction({ ...payload, refetchCount }));
        } else {
            yield put(getReportsErrorAction([errorMessage]));
        }
    }
}

function* handleGetReportsForOrganisationRequest({ payload }: any) {
    const errorMessage = 'Error fetching report list. Please try again later.';
    let { refetchCount } = get(payload, 'refetchCount') || 0;
    try {
        const {
            filters,
            sortBy,
            sortAscending,
            pageSize,
            currentPage,
            excludeReports,
            Region
        } = payload;
        const cleanFilters = removeAppliedFiltersForApiRequest(
            filters,
            true,
            'report'
        );
        const sortObject = getSortFieldsWithCustomFields(sortBy);
        let apiUrl: string | undefined = undefined;
        if (Region) {
            let regionKeyConfig: DynamicObject[] = yield select(
                getRegionKeyConfig
            );
            let regionSettingsConfig: DynamicObject[] = yield select(
                getRegionSettingConfig
            );
            const configUsed = getRegionConfigFromList(
                Region,
                regionKeyConfig,
                regionSettingsConfig
            );
            apiUrl = get(configUsed, 'Url');
        }

        let res: DynamicObject;
        const usedQuery = queries.GET_REPORT_HISTORIES_FOR_ORGANISATION;
        const variables = {
            ...cleanFilters,
            ...sortObject,
            Ascending: sortAscending,
            PageSize: pageSize,
            Skip: currentPage * REPORTS_PAGE.pageSize,
            ExcludeReports: excludeReports
        };

        if (apiUrl) {
            const currentSession: DynamicObject = yield Auth.currentSession();
            const accessToken = get(currentSession, 'accessToken.jwtToken');
            const response: Response = yield fetch(
                `${apiUrl}/graphql`,
                {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        Authorization: accessToken,
                    },
                    body: JSON.stringify({
                        query: getGraphqlQueryString(usedQuery),
                        variables,
                    }),
                }
            );

            res = yield response.json();
        } else {
            res = yield call(
                [API, 'graphql'],
                graphqlOperation(usedQuery, variables)
            );
        }

        const { ReportHistories } = get(res.data, 'GetReportHistoriesForOrganisation');
        if (ReportHistories) {
            const responsePayload = {
                data: ReportHistories,
                pageData: {
                    pageSize: pageSize,
                    currentPage: currentPage,
                    hasNextPage:
                        !(ReportHistories.length < pageSize) &&
                        !(pageSize < REPORTS_PAGE.pageSize),
                },
            };

            refetchCount = 0;
            yield put(getReportsSuccessAction(responsePayload));
        }
    } catch (err) {
        if (err instanceof Error) {
            console.log('Error', err);
        } else {
            console.error('An unknown error occured.', err);
        }

        if (
            refetchCount <= maxAPIRefetchCount &&
            checkShouldRequestRefetch(err)
        ) {
            refetchCount++;
            yield delay(refetchAPIDelay);
            yield put(getReportsRequestAction({ ...payload, refetchCount }));
        } else {
            yield put(getReportsErrorAction([errorMessage]));
        }
    }
}

/**
 * Function called for downloading the complete report.
 */
function* handleDownloadCompleteReport({ payload: sagaPayload }: any) {
    const { payload, callback } = sagaPayload;

    try {
        yield call([API, 'post'], API_NAME, '/report/complete/download', {
            body: payload,
        });

        if (callback) {
            const response = {
                IsSuccess: true,
            };
            callback(response);
        }
    } catch (err) {
        if (callback) {
            const returnData = get(err.response, 'data')
                ? err.response.data
                : { Messages: [err.message] };
            returnData.IsSuccess = false;
            callback(returnData);
        }

        if (err instanceof Error) {
            console.log('Error', err);
        } else {
            console.error('An unknown error occured.', err);
        }
    }
}

function* handleGetReportDataRequest({ payload }: any) {
    const errorMessage = 'Error fetching report details. Please try again later.';
    const { ReportJobId } = payload;

    try {
        // To call async functions, use redux-saga's `call()`.
        const res: DynamicObject = yield call(
            [API, 'graphql'],
            graphqlOperation(queries.GET_REPORT_DETAILS, {
                ReportJobId,
            })
        );

        const Report = get(res.data, 'GetReportHistoryDetails');
        if (Report) {
            const responsePayload = {
                record: Report,
            };
            yield put(getReportDataSuccessAction(responsePayload));
        } else {
            yield put(getReportDataErrorAction([errorMessage]));
        }
    }
    catch (err) {
        if (err instanceof Error) {
            console.log('Error', err);
        } else {
            console.error('An unknown error occured.', err);
        }

        yield put(getReportDataErrorAction([errorMessage]));
    }
}

function* handleGetReportDataForOrganisationRequest({ payload, type }: any) {
    const errorMessage = 'Error fetching report details. Please try again later.';
    const { ReportJobId, Region } = payload;

    try {
        let apiUrl: string | undefined = undefined;
        if (Region) {
            let regionKeyConfig: DynamicObject[] = yield select(
                getRegionKeyConfig
            );
            let regionSettingsConfig: DynamicObject[] = yield select(
                getRegionSettingConfig
            );
            const configUsed = getRegionConfigFromList(
                Region,
                regionKeyConfig,
                regionSettingsConfig
            );
            apiUrl = get(configUsed, 'Url');
        }

        let res: DynamicObject;
        const usedQuery = queries.GET_REPORT_DETAILS_FOR_ORGANISATION;
        const variables = {
            ReportJobId
        };

        if (apiUrl) {
            const currentSession: DynamicObject = yield Auth.currentSession();
            const accessToken = get(currentSession, 'accessToken.jwtToken');
            const response: Response = yield fetch(
                `${apiUrl}/graphql`,
                {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        Authorization: accessToken,
                    },
                    body: JSON.stringify({
                        query: getGraphqlQueryString(usedQuery),
                        variables,
                    }),
                }
            );

            res = yield response.json();
        } else {
            res = yield call(
                [API, 'graphql'],
                graphqlOperation(usedQuery, variables)
            );
        }

        const Report = get(res.data, 'GetReportHistoryDetailsForOrganisation');
        if (Report) {
            const responsePayload = {
                record: Report,
            };
            yield put(getReportDataSuccessAction(responsePayload));
        } else {
            yield put(getReportDataErrorAction([errorMessage]));
        }
    }
    catch (err) {
        if (err instanceof Error) {
            console.log('Error', err);
        } else {
            console.error('An unknown error occured.', err);
        }

        yield put(getReportDataErrorAction([errorMessage]));
    }
}

function* watchGetReportDataForOrganisationRequest() {
    yield takeLatest(
        ReportActionTypes.GET_REPORT_DATA_FOR_ORGANISATION_REQUEST,
        handleGetReportDataForOrganisationRequest
    );
}

function* watchGetReportDataRequest() {
    yield takeLatest(
        ReportActionTypes.GET_REPORT_DATA_REQUEST,
        handleGetReportDataRequest
    );
}

function* watchGetReportsRequest() {
    yield takeLatest(
        ReportActionTypes.GET_REPORTS_REQUEST,
        handleGetReportsRequest
    );
}

function* watchGetReportsForOrganisationRequest() {
    yield takeLatest(
        ReportActionTypes.GET_REPORTS_FOR_ORGANISATION_REQUEST,
        handleGetReportsForOrganisationRequest
    );
}

function* watchDownloadCompleteReportsRequest() {
    yield takeLatest(
        ReportActionTypes.DOWNLOAD_COMPLETE_REPORTS,
        handleDownloadCompleteReport
    );
}

function* reportSaga() {
    yield all([
        fork(watchGetReportsRequest),
        fork(watchDownloadCompleteReportsRequest),
        fork(watchGetReportDataRequest),
        fork(watchGetReportDataForOrganisationRequest),
        fork(watchGetReportsForOrganisationRequest),
    ]);
}

export default reportSaga;
